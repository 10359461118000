export const CloseEyeIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7313 11.1878L14.9808 9.9216C15.0717 10.2663 15.125 10.6266 15.125 11C15.125 13.305 13.2746 15.18 11 15.18C10.6315 15.18 10.2759 15.126 9.93575 15.0339L11.1853 13.7677C12.5515 13.6733 13.6381 12.5722 13.7313 11.1878ZM21.9134 10.6616C21.8288 10.5075 20.6295 8.37796 18.2851 6.57325L17.2846 7.58707C18.9941 8.86075 20.0772 10.3462 20.5037 11.0011C19.6888 12.2584 16.4923 16.5734 11 16.5734C10.1764 16.5734 9.4105 16.4666 8.68983 16.2963L7.56009 17.4411C8.59873 17.7638 9.74033 17.9667 11 17.9667C18.1969 17.9667 21.765 11.6089 21.9134 11.3384C22.0289 11.1279 22.0289 10.8722 21.9134 10.6616ZM19.0486 3.82926L3.92356 19.1559C3.78933 19.2919 3.61333 19.36 3.4375 19.36C3.26167 19.36 3.08567 19.2919 2.95144 19.1559C2.6828 18.8837 2.6828 18.4429 2.95144 18.1708L4.87575 16.2208C1.76481 14.3177 0.184078 11.5163 0.086625 11.3384C-0.028875 11.1279 -0.028875 10.872 0.086625 10.6615C0.234953 10.3912 3.80308 4.03339 11 4.03339C12.8609 4.03339 14.4727 4.46358 15.8507 5.09963L18.0764 2.84418C18.3451 2.57196 18.7801 2.57196 19.0486 2.84418C19.317 3.1164 19.3172 3.55722 19.0486 3.82926ZM5.90098 15.1819L7.64156 13.4182C7.16083 12.7347 6.875 11.9013 6.875 11C6.875 8.69512 8.72541 6.82005 11 6.82005C11.8895 6.82005 12.7119 7.10969 13.3863 7.59665L14.7938 6.17041C13.6818 5.71914 12.4181 5.42672 11 5.42672C5.50773 5.42672 2.3112 9.74168 1.49652 10.999C2.01386 11.7935 3.50092 13.8048 5.90098 15.1819ZM8.63964 12.4066L12.3881 8.60821C11.9792 8.36351 11.5084 8.21338 11 8.21338C9.48372 8.21338 8.25 9.46354 8.25 11C8.25 11.5152 8.39816 11.9923 8.63964 12.4066Z"
        fill="#B0B0B0"
      />
    </svg>
  );
};
