/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './home-wrapper.styled';
import { TabsSwitcher } from '../../common/components/tabs-switcher';
import { EHomeTabs } from '../../common/types/components.types';
import { EmptyPageContainer } from '../empty-page-container.component';
import { EmptyFriendSearchIcon } from '../../common/components';
import { SearchContainer } from '../search.container';
import { friendRequestService } from '../../common/services/friend-request.service';
import { useSocketData } from '../../context/socket.context';
import { sentRequestService } from '../../common/services/sent-request.service';

interface IProps {
  activeTab: EHomeTabs;
  handleActiveTab: (tab: EHomeTabs) => (e: any) => void;
  children?: React.ReactNode;
}

export const HomeWrapper: FC<IProps> = ({ activeTab, handleActiveTab, children }) => {
  const { data: friendsRequestData, refetch } = friendRequestService.useAllQuery<any>();
  const { refetch: refetchSentReqs } = sentRequestService.useAllQuery<any>();
  const { requestCount, sentRequestChanged } = useSocketData();
  const [noUser, setNoUser] = useState(false);

  useEffect(() => {
    if (requestCount) {
      refetch();
    }
  }, [requestCount]);

  useEffect(() => {
    refetchSentReqs();
  }, [sentRequestChanged]);

  const reqs = (friendsRequestData || []).length;

  return (
    <Styled.HomeWrapperContainer container item direction="column">
      <Grid container item direction="column" p="8.75rem 2.75rem 0 2.75rem">
        <Grid container>
          <SearchContainer onSetNoUser={setNoUser} />
        </Grid>
        {noUser && (
          <EmptyPageContainer title="The user you are looking for either doesn’t exist or is already in your Friends or Requests list.">
            <EmptyFriendSearchIcon />
          </EmptyPageContainer>
        )}
        <Grid container mt="3rem">
          <TabsSwitcher
            title={EHomeTabs.FRIENDS}
            onClick={handleActiveTab(EHomeTabs.FRIENDS)}
            activeTab={activeTab}
          />
          <TabsSwitcher
            title={EHomeTabs.REQUESTS}
            onClick={handleActiveTab(EHomeTabs.REQUESTS)}
            activeTab={activeTab}
            requests={reqs}
          />
        </Grid>
      </Grid>
      <Styled.BottomBorder container />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Styled.HomeWrapperContainer>
  );
};
