import React, { useContext, useState } from 'react';
import { DefaultAvatarsListContainer } from './default-avatars-list.styled';
import { VISIBLE_DEFAULT_AVATARS_MAP } from './consts';
import { DefaultAvatar } from './default-avatar.component';
import { useUploadDefaultAvatar } from './hooks/useUploadDefaultAvatar';
import { useGetUser } from '../common/hooks';
import { setChoosenAvatar } from './utils';
import { AvatarContext } from '../context/avatar.context';

export const DefaultAvatarsList = () => {
  const [itemId, setItemId] = useState<string | null>(null);
  const { setAvatarUrl, setSizeError } = useContext(AvatarContext);

  const { data: user } = useGetUser();

  const { mutateAsync: uploadDefaultAvatar } = useUploadDefaultAvatar();

  const getAvatarId = async (id: string) => {
    setAvatarUrl(['']);
    setSizeError('');
    setItemId(id);
    await uploadDefaultAvatar({ defaultAvatar: id });
  };

  return (
    <DefaultAvatarsListContainer>
      {VISIBLE_DEFAULT_AVATARS_MAP.map((el, idx) => (
        <DefaultAvatar
          isChoosen={setChoosenAvatar(user, el, itemId)}
          key={idx}
          getAvatarId={getAvatarId}
          src={el.src}
          id={el.id}
        />
      ))}
    </DefaultAvatarsListContainer>
  );
};
