import React from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import toast, { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MainRouter } from '../navigation';

import * as theme from '../theme';
import { muiTheme } from '../theme';
import * as Styled from './app.styled';
import '../../style.css';
import '../../__mocks__';
import { ModalState } from '../context/modal.context';
import { AvatarState } from '../context/avatar.context';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      cacheTime: Infinity
    }
  },
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        toast.error(`Something went wrong: ${error.message}`);
      }
    }
  })
});

const AppContainer = () => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={muiTheme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <Styled.GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <ModalState>
            <AvatarState>
              <MainRouter />
            </AvatarState>
          </ModalState>
          <ReactQueryDevtools initialIsOpen={false} />
          <Toaster position="top-right" reverseOrder />
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </MuiThemeProvider>
  </ThemeProvider>
);

export default AppContainer;
