import { toastError } from '../components/toast/toast.component';
import { EStatusTypes } from '../types/components.types';
import { userService } from './user.service';

type RecognizeCallback = (text: string, status: EStatusTypes) => void;

class NativeSpeechService {
  private SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  private reconizer: SpeechRecognition | null = null;

  private isPermitted = true;

  public async showErrorToast() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (e) {
      console.log(
        'if you want to have full access to app features give permission to use your microphone'
      );

      toastError('Allow access to use your microphone');
    }
  }

  public async checkPermission(cb: () => void) {
    try {
      // @ts-ignore
      const permission = await navigator.permissions.query({ name: 'microphone' });

      // listen for changes in permission status
      permission.onchange = () => {
        cb();
      };

      const permissionNotification = await navigator.permissions.query({ name: 'notifications' });

      permissionNotification.onchange = () => {
        cb();
      };
    } catch {
      // in firefox or webkit case
    }
  }

  public async init(cb: RecognizeCallback) {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch {
      this.isPermitted = false;
    }

    if (!this.isPermitted) {
      return;
    }

    this.reconizer = new this.SpeechRecognition();
    this.reconizer.lang = 'en-US';
    this.reconizer.continuous = false;
    this.reconizer.maxAlternatives = 1;

    this.reconizer.onstart = () => {
      console.log('\n    Session started event.');
    };

    this.reconizer.onresult = async (event) => {
      const { transcript } = event.results[0][0];
      if (transcript) {
        const userData = await userService.getMe();
        cb(transcript, userData.status);
        console.log(`RECOGNIZED: Text=${transcript}`);
      }
    };

    this.reconizer.onend = () => {
      console.log('\n    Session stopped event.');
      this.stop();
      try {
        this.reconizer?.start();
      } catch {
        //
      }
    };

    this.reconizer.onerror = (event) => {
      console.log(`Error occurred in recognition: ${event.error}`);
    };

    this.reconizer.start();
  }

  public stop() {
    if (this.reconizer) {
      this.reconizer.stop();
    }
  }
}

export const nativeSpeechService = new NativeSpeechService();
