import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './settings-wrapper.styled';
import { Text } from '../global-typography';
import { BackArrowContainer } from './settings-wrapper.styled';
import { BackArrowIcon } from '../icons/back-arrow-icon.component';
import { APP_KEYS } from '../../consts';
import { ErrorToast } from '../auth-wrapper/auth-wrapper.styled';

interface IProps {
  title?: string;
  isNewAccount?: boolean;
  errorMsg?: JSX.Element | undefined;
  children?: React.ReactNode;
}

export const SettingsWrapper: FC<IProps> = ({ isNewAccount, title, errorMsg, children }) => {
  const { push } = useHistory();
  const handleBack = () => {
    push(APP_KEYS.ROUTER_KEYS.HOME);
  };

  return (
    <Styled.SettingsWrapperContainer container item direction="column">
      {errorMsg && (
        <ErrorToast
          container
          alignItems="center"
          justifyContent="center"
          mt="-2.5rem"
          withoutBorderRadius
        >
          <Text colorVariant="error.main" variant="body1" align="center">
            {errorMsg}
          </Text>
        </ErrorToast>
      )}
      <Text
        colorVariant="common.black"
        variant="h4"
        align="left"
        fontWeight="fontWeightBold"
        sx={{ position: 'relative' }}
      >
        {title}
        {!isNewAccount && (
          <BackArrowContainer onClick={handleBack}>
            <BackArrowIcon />
          </BackArrowContainer>
        )}
      </Text>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Styled.SettingsWrapperContainer>
  );
};
