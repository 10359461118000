import React, { FC, useState } from 'react';
import * as Styled from './settings-popover.styled';
import { Text } from '../../global-typography';
import { ESettingsOptions } from '../../../types/components.types';

interface IProps {
  label?: ESettingsOptions;
  onClick?: () => void;
}

export const SettingsPopoverItem: FC<IProps> = ({ label, onClick }) => {
  const [isHover, setHover] = useState(false);
  const handleMouseOver = () => {
    setHover(true);
  };
  const handleMouseOut = () => {
    setHover(false);
  };
  return (
    <Styled.SettingsPopoverItemContainer
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
    >
      <Text colorVariant={isHover ? 'primary.main' : 'secondary.dark'} variant="subtitle2">
        {label}
      </Text>
    </Styled.SettingsPopoverItemContainer>
  );
};
