import { HttpFactoryService } from './http-factory.service';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { ReactQueryFactory } from '../utils';

export class ConversationService extends ReactQueryFactory<{}> {
  constructor(private httpService: EnhancedWithAuthHttpService) {
    super('conversation');
  }

  async createRoom(payload: { usernamePrompt: string }) {
    await this.httpService.post('conversation', payload);
  }

  async deleteRoom() {
    await this.httpService.delete('conversation');
  }

  async declineRoom() {
    await this.httpService.delete('conversation/decline');
  }

  async onDetailedRequest(roomId: string): Promise<any | void | undefined> {
    const data = await this.httpService.get<any>(`conversation/${roomId}`);
    return data;
  }

  async getConversation(roomId: string) {
    const data = await this.httpService.get<any>(`conversation/${roomId}`);
    return data;
  }

  async onAllRequest() {
    throw new Error('onAllRequest not implemented!');
  }

  async onListRequest() {
    throw new Error('onListRequest not implemented!');
  }
}

const factory = new HttpFactoryService();
export const conversationService = new ConversationService(factory.createAuthHttpService());
