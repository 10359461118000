import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';

export const MainFormContainer = styled(Grid)<{ theme?: Theme }>`
  padding-left: 0px;
`;

export const PasswordSectionContainer = styled(Grid)<{ theme?: Theme }>`
  min-height: 67px;
  background-color: rgba(234, 241, 246, 0.5);
  padding-left: ${({ theme }) => theme.spacing(3.5)};
  padding-right: ${({ theme }) => theme.spacing(3.5)};
`;

export const UploadAndPhoneContainer = styled(Grid)<{ theme?: Theme }>`
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
`;

export const UsernameAndEmailContainer = styled(Grid)<{ theme?: Theme }>`
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 20px;
`;
