import React from 'react';
import { APP_KEYS } from '../common/consts';
import {
  EmailVerificationPage,
  LoginPage,
  PhoneVerificationPage,
  ResetEmailPage,
  ResetPasswordPage,
  SignUpPage
} from '../auth';
import { ConversationPage } from '../conversation';
import { HomePage } from '../home';
import { SettingsContainer } from '../settings/settings-container.component';
import { SpeechTestContainer } from '../speech-test';
import { PhoneEmailVerificationPage } from '../auth/phone-and-email-verification';
import { Room } from '../conversation/Room';

export interface GenericRouteType {
  component: React.FC<any>;
  path: string;
}

export const publicRoutes: GenericRouteType[] = [
  { component: SignUpPage, path: APP_KEYS.ROUTER_KEYS.SIGN_UP },
  { component: LoginPage, path: APP_KEYS.ROUTER_KEYS.LOGIN },
  { component: ResetEmailPage, path: APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD },
  { component: ResetPasswordPage, path: APP_KEYS.ROUTER_KEYS.RESET_PASSWORD },
  { component: EmailVerificationPage, path: APP_KEYS.ROUTER_KEYS.EMAIL_VERIFICATION },
  { component: PhoneVerificationPage, path: APP_KEYS.ROUTER_KEYS.PHONE_VERIFICATION },
  { component: PhoneEmailVerificationPage, path: APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION }
];
export const privateRoutes: GenericRouteType[] = [
  { component: Room, path: APP_KEYS.ROUTER_KEYS.ROOM },
  { component: SettingsContainer, path: APP_KEYS.ROUTER_KEYS.SETTINGS },
  { component: ConversationPage, path: APP_KEYS.ROUTER_KEYS.CONVERSATION },
  { component: SpeechTestContainer, path: APP_KEYS.ROUTER_KEYS.SPEECH_TEST }, // only for test purposes
  { component: HomePage, path: APP_KEYS.ROUTER_KEYS.HOME }
];
