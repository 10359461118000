import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useHistory } from 'react-router-dom';
import { EventTypes, SocketEventTypes } from '../types/socket.types';
import { IUser } from '../services/types';
import { useSocketData } from '../../context/socket.context';
import { localStorageService } from '../services/local-storage.service';
import { queryClient } from '../../app';
import { APP_KEYS } from '../consts';
import { ESoundTypes, useSound } from '../../context/sound.context';
import { toastSuccess } from '../components/toast/toast.component';
import { notifyMessage } from '../../auth/utils/notificationMessage.util';

let socket: Socket | null = null;

export const useSocket = (userId: string) => {
  if (userId && !socket) {
    socket = io(`${process.env.REACT_APP_BACKEND_URL}/friends` as string, {
      query: {
        userId
      }
    });
  }

  const [accepted, setAccepted] = useState<IUser[]>();
  const [requestCount, setRequestCount] = useState<any>();
  const [statusData, setStatusData] = useState<any>();

  const { setSentRequestsChanged } = useSocketData();
  const { play } = useSound();

  const { push } = useHistory();

  const handleClearSocket = () => {
    socket = null;
  };

  const addMember = (data: { room: string; otherMemberName: string }) => {
    console.log('ROOM');
    if (data) {
      play(ESoundTypes.START_CALL);
      push(`/conversation?room=${data.room}`);
      toastSuccess(`Conversation started with @${data.otherMemberName}`);
    }
  };

  const closeRoom = (data: { username: string; reason: 'busy' | 'ended' }) => {
    if (data) {
      const soundToPlay = data.reason === 'busy' ? ESoundTypes.USER_BUSY : ESoundTypes.END_CALL;
      const textToToast =
        data.reason === 'busy'
          ? `${data.username} is currently busy`
          : `Conversation ended with @${data.username}`;
      play(soundToPlay);
      push('/');
      toastSuccess(textToToast);
      notifyMessage(textToToast);
    }
  };

  useEffect(() => {
    if (userId) {
      socket?.on(SocketEventTypes.EMITS.USER_FRIENDLIST_REQUESTS, (data: any) => {
        if (data) setRequestCount(data);
      });

      socket?.on(SocketEventTypes.EMITS.TEST, (data: { result: IUser[] }) => {
        if (data) setAccepted(data.result);
      });

      socket?.on(SocketEventTypes.SUBSCRIBES.USER_FRIEND_ACCEPTED, (data: { result: IUser[] }) => {
        if (data) setAccepted(data.result);
      });

      socket?.on(SocketEventTypes.EMITS.USER_STATUS_GET, (data: any[]) => {
        if (data) setStatusData(data);
      });

      socket?.on(SocketEventTypes.EMITS.USER_FRINDLIST_SENT_REQUESTS, () => {
        setSentRequestsChanged((state) => !state); // just trigger state change to refetch
      });

      socket
        ?.off(SocketEventTypes.SUBSCRIBES.CONVERSATION_USER_ADDED)
        ?.on(SocketEventTypes.SUBSCRIBES.CONVERSATION_USER_ADDED, addMember);

      socket
        ?.off(SocketEventTypes.SUBSCRIBES.CONVERSATION_ENDED)
        ?.on(SocketEventTypes.SUBSCRIBES.CONVERSATION_ENDED, closeRoom);

      socket?.on('logout', (data: any) => {
        if (data) {
          socket = null;
          localStorageService.removeTokenFromStorage();
          queryClient.clear();
          push(APP_KEYS.ROUTER_KEYS.LOGIN);
        }
      });

      socket?.emit(SocketEventTypes.SUBSCRIBES.USER_FRIEND_ACCEPTED);
      socket?.emit(EventTypes.USER_STATUS_CHANGE);
      socket?.emit(EventTypes.USER_FRIENDLIST_COUNT_CHANGE);
    }
  }, [userId, socket]);

  return {
    socket,
    accepted,
    requestCount,
    statusData,
    handleClearSocket
  };
};
