import styled from '@emotion/styled';
import { MergedTheme } from '../../../../theme';

export const SettingsPopoverContainer = styled.div<{ theme?: MergedTheme; isUsername?: boolean }>`
  position: absolute;
  width: 172px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.common.white};
  top: 68px;
  left: ${({ isUsername }) => (!isUsername ? '-66px' : 0)};
  filter: drop-shadow(1px 5px 10px ${({ theme }) => theme.colors.mostLightBlue});

  &::after {
    content: ' ';
    height: 0.75rem;
    width: 0.75rem;
    background-color: ${({ theme }) => theme.palette.background.default};
    position: absolute;
    left: calc(50% + 4px);
    top: -1px;
    border-left: 1px solid ${({ theme }) => theme.palette.background.default};
    border-top: 1px solid ${({ theme }) => theme.palette.background.default};
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const TopSection = styled.div<{ theme?: MergedTheme }>`
  background-color: ${({ theme }) => theme.palette.background.default};
  min-width: 100%;
  height: 8px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const SettingsPopoverItemContainer = styled.div<{ theme?: MergedTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dividerGrey};

  &:last-child {
    border-bottom: none;
  }
`;
