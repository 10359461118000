import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import bgImg from '../../../../assets/image/vector.svg';

export const MainContainer = styled(Grid)`
  background-color: rgba(170, 230, 253, 0.15);
  background-image: url(${bgImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
