import * as Yup from 'yup';
import { ERROR_MSG } from '../../common/consts';

export const EMAIL_REGEX = /^([a-z0-9_\.\-])+\@(([a-z0-9\-])+\.)+([a-z0-9]{2,4})+$/;
export const PHONE_REGEX = /^[(00)|(+)]{1}[0-9\\s.\\/-]{12,13}$/;

export const getloginValidator = () =>
  Yup.string()
    .strict(true)
    .required('Email / Phone Number is required.')
    .test('test-name', ERROR_MSG.VALID_LOGIN, (value) => {
      const isValidEmail = EMAIL_REGEX.test(value);
      const isValidPhone = PHONE_REGEX.test(value);
      return !(!isValidEmail && !isValidPhone);
    });
