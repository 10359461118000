import styled from '@emotion/styled';
import { COLORS, MergedTheme } from '../../../theme';
import { getStatusBorderColor, getStatusColor } from '../../utils/components.util';
import { EStatusTypes } from '../../types/components.types';

export const StatusBadgeContainer = styled.div<{ theme?: MergedTheme }>`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.semiTransparentGrey};
  border-radius: 18px;
  height: 37px;
  padding: ${({ theme }) => theme.spacing(1)};
  box-sizing: border-box;
`;

export const StatusIndicator = styled.div<{ theme?: MergedTheme; status?: EStatusTypes }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid ${({ status }) => getStatusBorderColor(status)};
  background-color: ${({ status }) => getStatusColor(status)};
`;

export const DotsContainer = styled.div<{ theme?: MergedTheme; isActive?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(2)};
  position: relative;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.semiTransparentGrey : theme.colors.transparent};

  &:hover {
    background-color: ${({ theme }) => theme.colors.semiTransparentGrey};
  }
`;

export const CallButton = styled.button`
  margin-left: 16px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(234, 241, 246, 0.5);

  border: none;
  outline: none;

  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
`;

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  outline: 'none',
  border: `1px solid ${COLORS.veryLightAzure};`,
  borderRadius: 5,
  boxShadow: 24,
  p: 4
};

// export const Dots = styled.div
