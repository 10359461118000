import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { AuthWrapper, CustomLink, PageWrapper, Text } from '../../common/components';
import { APP_KEYS } from '../../common/consts';

interface IProps {
  children?: React.ReactNode;
}

export const ResetPageContainer: FC<IProps> = ({ children }) => {
  const [errorLoginMsg, setErrorLoginMsg] = useState<string>('');
  const [firstPasswordErrorMsg, setFirstPasswordErrorMsg] = useState<string>('');
  const [secondPasswordErrorMsg, setSecondPasswordErrorMsg] = useState<string>('');
  const [customErrorMsg, setCustomErrorMsg] = useState<string>();

  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');

  const errorMsg = customErrorMsg ? (
    <>
      {customErrorMsg?.split('<br>').map((item) => (
        <p>{item}</p>
      ))}
      <p>{serverErrorMsg}</p>
    </>
  ) : undefined;

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <AuthWrapper title="Reset Password" variant="h5" errorMsg={errorMsg}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              errorLoginMsg,
              setErrorLoginMsg,
              firstPasswordErrorMsg,
              setFirstPasswordErrorMsg,
              secondPasswordErrorMsg,
              setSecondPasswordErrorMsg,
              customErrorMsg,
              setCustomErrorMsg,
              serverErrorMsg,
              setServerErrorMsg
            } as any);
          }
          return null;
        })}

        <Grid item container justifyContent="center" mt="2.5rem">
          <Text colorVariant="common.black" variant="subtitle2" letterSpacing="0.02em">
            Back to&nbsp;
            <CustomLink to={APP_KEYS.ROUTER_KEYS.LOGIN}>LOGIN</CustomLink>
          </Text>
        </Grid>
      </AuthWrapper>
    </PageWrapper>
  );
};
