export enum ECommand {
  HEY = 'HEY',
  BUSY = 'BUSY',
  BYE = 'BYE'
}

export const matchSpeech = (text: string) => {
  const nameMatch = text.match(/[hH]ey[\s,]+(\w+)/);
  const commandMatcher: Record<ECommand, boolean | string> = {
    [ECommand.BUSY]: text.toLowerCase().includes("i'm busy"),
    [ECommand.BYE]:
      text.toLowerCase().includes('bye, bye') || text.toLowerCase().includes('bye-bye'),
    [ECommand.HEY]: nameMatch ? nameMatch[1] : false
  };

  return [commandMatcher.HEY, commandMatcher.BYE, commandMatcher.BUSY];
};
