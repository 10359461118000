import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { AuthWrapper, Text, CustomLink } from '../common/components';
import { APP_KEYS } from '../common/consts';

interface IProps {
  title?: string;
  errorMsg?: JSX.Element | undefined | string;
  children?: React.ReactNode;
}

export const ChangePasswordPageContainer: FC<IProps> = ({ title, errorMsg, children }) => (
  <AuthWrapper title={title} withLogo={false} variant="h5" errorMsg={errorMsg}>
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {});
      }
      return null;
    })}

    <Grid item container justifyContent="center" mt="1rem">
      <Text colorVariant="common.black" variant="subtitle2" letterSpacing="0.02em">
        Back to&nbsp;
        <CustomLink to={APP_KEYS.ROUTER_KEYS.SETTINGS}>Settings</CustomLink>
      </Text>
    </Grid>
  </AuthWrapper>
);
