import { APP_KEYS } from '../consts';

export class LocalStorageService {
  public setTokenToStorage(token: string) {
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.TOKEN, token);
    return token;
  }

  public getTokenFromStorage() {
    return localStorage.getItem(APP_KEYS.STORAGE_KEYS.TOKEN);
  }

  public removeTokenFromStorage() {
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.TOKEN);
  }

  public setRoomToStorage(roomId: string) {
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.CURRENT_ROOM, roomId);
    return roomId;
  }

  public getRoomFromStorage() {
    return localStorage.getItem(APP_KEYS.STORAGE_KEYS.CURRENT_ROOM);
  }

  public removeRoomFromStorage() {
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.CURRENT_ROOM);
  }

  public setSpeechTokenToStorage(token: string) {
    localStorage.setItem(APP_KEYS.STORAGE_KEYS.SPEECH_API_TOKEN, token);
    return token;
  }

  public getSpeechTokenFromStorage() {
    return localStorage.getItem(APP_KEYS.STORAGE_KEYS.SPEECH_API_TOKEN);
  }

  public removeSpeechTokenFromStorage() {
    localStorage.removeItem(APP_KEYS.STORAGE_KEYS.SPEECH_API_TOKEN);
  }
}

export const localStorageService = new LocalStorageService();
