import { HttpFactoryService } from './http-factory.service';
import {
  IChangePasswordRequest,
  ICheckCode,
  ISetDefaultAvatarBody,
  ISetDefaultAvatarResponse,
  ISetupPasswordRequest,
  IUpdateAvatarResponse,
  IUserInfoRequest,
  IUser
} from './types';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { ReactQueryFactory } from '../utils';
import { localStorageService } from './local-storage.service';

export class UserService extends ReactQueryFactory<{}> {
  constructor(private httpService: EnhancedWithAuthHttpService) {
    super('user');
  }

  async onDetailedRequest(): Promise<any | void | undefined> {
    throw new Error('onDetailedRequest not implemented!');
  }

  async onAllRequest() {
    const token = localStorageService.getTokenFromStorage();
    if (!token) return null;
    const data = await this.httpService.get<IUser>('user/me');
    return data;
  }

  async getUserIdByUsername(username: string) {
    const token = localStorageService.getTokenFromStorage();

    if (!token) return null;

    const data = await this.httpService.get<any>(`user/get-userId-by-username:${username}`);

    return data;
  }

  async onListRequest() {
    throw new Error('onListRequest not implemented!');
  }

  async getMe() {
    return this.httpService.get<any>('user/me');
  }

  async getAvatar(id: string) {
    const data = await this.httpService.get<any>(`user/avatar/${id}`);
    const url = URL.createObjectURL(new Blob([data], { type: 'image/png' }));

    return url;
  }

  async setUserInfo({
    email,
    phone,
    username,
    description,
    isNewAccount
  }: IUserInfoRequest): Promise<any | void> {
    return this.httpService.patch('user/me', { email, phone, username, description, isNewAccount });
  }

  async changePassword({ newPassword, oldPassword }: IChangePasswordRequest): Promise<any | void> {
    return this.httpService.patch('user/change-password', { newPassword, oldPassword });
  }

  async setupPassword({ password }: ISetupPasswordRequest): Promise<any | void> {
    return this.httpService.patch('user/add-password', { password });
  }

  async checkPhoneCode({ code }: ICheckCode): Promise<any | void> {
    return this.httpService.post('user/check-code', { code });
  }

  async setVerificateAccount({
    code,
    emailUser,
    phoneUser
  }: {
    code: number;
    emailUser: string | undefined;
    phoneUser: string | undefined;
  }): Promise<any | void> {
    return this.httpService.post('user/set-verificate-account', { code, emailUser, phoneUser });
  }

  async deleteAccount() {
    return this.httpService.delete('user');
  }

  async uploadAvatar(body: FormData): Promise<IUpdateAvatarResponse | void> {
    return this.httpService.post<Promise<IUpdateAvatarResponse>, FormData>(
      'user/setprofileimage',
      body,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  }

  async uploadDefaultAvatar(
    body: ISetDefaultAvatarBody
  ): Promise<ISetDefaultAvatarResponse | void> {
    return this.httpService.post<Promise<ISetDefaultAvatarResponse>, ISetDefaultAvatarBody>(
      'user/setdefaultimage',
      body,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  }
}

const factory = new HttpFactoryService();
export const userService = new UserService(factory.createAuthHttpService());
