import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { Theme } from '@mui/material';
import { COLORS } from './colors.const';

export interface MergedTheme extends Theme {
  colors: {
    [key: string]: string;
    transparent: string;
    semiTransparentBlue: string;
    semiTransparentGrey: string;
  };
}

export const muiTheme = createTheme(
  deepmerge(
    {
      palette: {
        mode: 'light',
        primary: {
          main: '#0583F2',
          dark: '#6BB3F2',
          light: '#B8DCFC'
        },
        secondary: {
          main: '#B0B0B0',
          dark: '#868686',
          light: '#F2F2F2'
        },
        background: {
          default: '#EAF1F6'
        },
        error: {
          main: '#C6000B',
          dark: '#E42121',
          light: '#F7E5E5'
        },
        success: {
          main: '#91BF4B'
        }
      },
      typography: {
        fontFamily: ['"DM Sans"'].join(','),
        h5: {
          fontSize: '1.75rem'
        },
        subtitle1: {
          fontSize: '1.5rem'
        },
        subtitle2: {
          fontSize: '1.125rem',
          fontWeight: 400
        },
        caption: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 500
        }
      }
    },
    {
      colors: COLORS
    }
  )
);
