import React, {
  createContext,
  ReactChild,
  ReactChildren,
  useContext,
  useMemo,
  useState
} from 'react';
import { EStatusTypes } from '../common/types/components.types';

interface IContextValue {
  status: EStatusTypes;
  onSetStatus: (currentStatus?: EStatusTypes) => void;
}

const UserContext = createContext<IContextValue>({} as IContextValue);

interface IUserContext {
  children: ReactChild | ReactChildren;
}

export const UserContextProvider: React.FC<IUserContext> = ({ children }) => {
  const [status, setStatus] = useState(EStatusTypes.OFFLINE);

  const onSetStatus = (currentStatus?: EStatusTypes) => {
    if (currentStatus) {
      setStatus(currentStatus);
    }
  };

  const value = useMemo(
    () => ({
      status,
      onSetStatus
    }),
    [status, onSetStatus]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserData = () => useContext(UserContext);
