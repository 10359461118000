import React, { ComponentProps, FC } from 'react';

const CombineComponents = (...components: FC[]): FC =>
  components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }: ComponentProps<FC>): JSX.Element =>
        (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        ),
    ({ children }: any) => <>{children}</>
  );

export default CombineComponents;
