import React, { FC, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FriendsItem } from '../common/components/user-item/friends-item.component';
import { EmptyPageContainer } from './empty-page-container.component';
import { EmptyFriendsIconComponent } from '../common/components';
import { IUser } from '../common/services/types';
import { useSocket } from '../common/utils/socket.util';
import { useSocketData } from '../context/socket.context';
import { SocketEventTypes } from '../common/types/socket.types';

interface IProps {
  userId: string;
}

export const FriendsTab: FC<IProps> = ({ userId }) => {
  const { socket } = useSocket(userId);
  const { accepted } = useSocketData();
  const friends = accepted || [];

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        socket?.emit(SocketEventTypes.SUBSCRIBES.USER_FRIEND_ACCEPTED);
      }
    };

    const handleOnline = () => {
      socket?.emit(SocketEventTypes.SUBSCRIBES.USER_FRIEND_ACCEPTED);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('online', handleOnline);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleOnline);
    };
  }, [socket]);

  return (
    <>
      {!accepted && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '50px'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {friends.length !== 0 &&
        accepted &&
        friends.map((item: IUser) => (
          <FriendsItem
            status={item.status}
            key={item.id}
            username={item.username}
            id={item.id}
            friend={item}
          />
        ))}
      {friends.length === 0 && accepted && (
        <EmptyPageContainer title="Your friend list is empty now">
          <EmptyFriendsIconComponent />
        </EmptyPageContainer>
      )}
    </>
  );
};
