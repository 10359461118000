import * as Yup from 'yup';

export const getUsernameValidator = () =>
  Yup.string()
    .strict(true)
    .matches(
      /^[a-zA-Z]*$/,
      'Please enter valid username, only lowercase letters, not include ~!@#$%^&*()_+=\\./,`'
    )
    .trim('The contact name cannot include leading and trailing spaces')
    .required('Username is required.')
    .max(25, 'Too long username.');
