import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { getStatusColor } from '../../utils/components.util';
import { EStatusTypes } from '../../types/components.types';
import { MergedTheme } from '../../../theme';
import { Text } from '../global-typography';

export const UserItemContainer = styled(Grid)<{ theme?: MergedTheme; withborder?: string }>`
  border-bottom: ${({ withborder, theme }) =>
    withborder ? `1px solid ${theme.colors.lightBlue}` : 'none'};
  min-height: 100px;
  padding-left: ${({ theme }) => theme.spacing(5.5)};
  padding-right: ${({ theme }) => theme.spacing(5.5)};
`;

export const UserIconContainer = styled.div<{ theme?: MergedTheme; status?: EStatusTypes }>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 3px solid ${({ status }) => getStatusColor(status)};
  overflow: hidden;
`;

export const DescriptionText = styled(Text)`
  max-width: 450px;
`;

export const HeyName = styled.span<{ theme?: MergedTheme; status?: EStatusTypes }>`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  padding-left: ${({ theme }) => theme.spacing(1)};
`;

export const UsernameWrapper = styled.div`
  display: flex;
`;
