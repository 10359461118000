import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import * as GlobalTypography from '../common/components/global-typography';
import { HiddenInput, UploadFileContainer } from './upload-file.styled';
import { COLORS, FONTS } from '../theme';
import upload from '../../assets/image/upload.svg';
import { AVATAR_HUGE_SIZE_ERROR } from './consts/upload-file.consts';
import { AvatarContext } from '../context/avatar.context';

export type UploadFileProps = {
  image: never[];
  setImage: Dispatch<SetStateAction<never[]>>;
};

export const UploadFile = ({ image, setImage }: UploadFileProps) => {
  const { setAvatarUrl, setSizeError } = useContext(AvatarContext);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (image?.length < 1) {
      return;
    }

    // @ts-ignore
    if (image[0]?.size > 100000000) {
      setSizeError(AVATAR_HUGE_SIZE_ERROR);
      return;
    }

    setSizeError('');

    const newImageUrls: any = [];
    image.forEach((img) => newImageUrls?.push(URL.createObjectURL(img)));
    setAvatarUrl(newImageUrls);
  }, [image]);

  const onImageUpload = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setImage([...e.target.files]);
    e.target.value = '';
  };

  return (
    <>
      <UploadFileContainer onClick={onImageUpload}>
        <img src={upload} alt="upload" width="24" height="24" />
        <GlobalTypography.Text
          variant="body1"
          colorVariant={COLORS.offline}
          fontSize={FONTS.SIZES.s}
        >
          upload file
        </GlobalTypography.Text>
      </UploadFileContainer>
      <HiddenInput
        type="file"
        onChange={onChange}
        name="avatar"
        id="avatar"
        ref={inputRef}
        accept="image/jpg, image/jpeg, image/png"
      />
    </>
  );
};
