import { toastError } from '../components/toast/toast.component';

export function customErrorHandler(error: any): void {
  if (error.response?.data?.message) {
    const err = error.response?.data?.message;

    if (Array.isArray(err)) {
      for (const i in err) {
        toastError(err[i]);
      }
    } else {
      toastError(err);
    }
  } else {
    toastError('Something went wrong!');
  }
}
