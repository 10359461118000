import { EnhancedWithAuthHttpService } from './http-auth.service';
import { HttpFactoryService } from './http-factory.service';
import {
  IGetFriendVoiceCommandPayload,
  IGetFriendVoiceResponse,
  ISetUpFriendVoiceCommandPayload
} from './types';

export class UploadAudioService {
  constructor(private httpService: EnhancedWithAuthHttpService) {}

  async setupFriendVoiceCommand({ friendId, audioUploaded }: ISetUpFriendVoiceCommandPayload) {
    return this.httpService.put(
      `audio/voice-command/${friendId}`,
      {
        audioUploaded
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  }

  async getFriendVoiceCommand({
    friendId
  }: IGetFriendVoiceCommandPayload): Promise<IGetFriendVoiceResponse | void> {
    return this.httpService.get<IGetFriendVoiceResponse>(`audio/voice-command/${friendId}`);
  }
}

const factory = new HttpFactoryService();
export const uploadAudioService = new UploadAudioService(factory.createAuthHttpService());
