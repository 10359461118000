import React, { FC } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './conversation.styled';
import { Text } from '../common/components';
import { EStatusTypes } from '../common/types/components.types';

interface IProps {
  userName?: string;
  status?: EStatusTypes;
  avatarUrl: string;
}

export const ConversationItem: FC<IProps> = ({
  userName = '@test_user',
  status = EStatusTypes.ONLINE,
  avatarUrl
}) => (
  <Styled.ConversationItemContainer
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
  >
    <Styled.UserIconContainer status={status}>
      <picture>
        <source srcSet={avatarUrl} type="image/png" />
        <Styled.UserImage src={avatarUrl} alt={userName} />
      </picture>
    </Styled.UserIconContainer>

    <Text colorVariant="common.black" variant="subtitle1" fontWeight="fontWeightBold" mt="2.25rem">
      @{userName}
    </Text>
  </Styled.ConversationItemContainer>
);
