import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const TabsSwitcherContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 109px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

export const Selected = styled.div<{ theme?: Theme; isActive?: boolean }>`
  width: 109px;
  height: 7px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary.main : theme.palette.common.white};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  -moz-border-radius-topleft: 7px;
  -moz-border-radius-topright: 7px;
`;

export const RequestsCount = styled.div<{ theme?: Theme }>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: flex;
  align-items: ;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -18px;
  color: ${({ theme }) => theme.palette.common.white};
`;
