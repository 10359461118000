import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const TextareaContainer = styled.div<{
  disabled: boolean | undefined;
  height?: number;
  theme?: Theme;
  isErrorExists?: boolean;
}>`
  display: flex;
  position: relative;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.secondary.main : theme.palette.common.white};
  border: 1px solid
    ${({ theme, isErrorExists }) =>
      isErrorExists ? theme.palette.error.dark : theme.palette.secondary.main};
  border-radius: 10px;
  box-sizing: border-box;
  height: ${({ height }) => (height ? `${height + 2}px` : '58px')};
  max-height: 104px;
  &:focus-within {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const Textarea = styled.textarea<{ theme?: Theme }>`
  outline: none;
  border: none;
  max-height: 104px;
  max-lines: 3;
  min-width: 100%;
  overflow: hidden;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  background-color: transparent;
  padding: ${({ theme }) => theme.spacing(2)};

  &::placeholder {
    color: ${({ theme }) => theme.palette.secondary.main};
  }

  &:-webkit-autofill {
    -webkit-background-clip: text;
  }
`;

export const SubText = styled.span<{ theme?: Theme }>`
  color: ${({ theme }) => theme.palette.primary.main};
`;
export const IconContainer = styled.div<{ theme?: Theme }>`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const Styled = {
  TextareaContainer,
  Textarea,
  SubText,
  IconContainer
};
