import styled from 'styled-components';
import { COLORS, MEDIA } from '../../../theme';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;

  background-color: ${COLORS.modalBackdrop};
`;

export const StyledModal = styled.div`
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 24px);

  @media (min-width: ${MEDIA.laptop}) {
    max-width: calc(100vw - 48px);
  }
`;
