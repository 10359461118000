import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { CustomButton, FormInput } from '../../common/components';
import { APP_KEYS } from '../../common/consts';
import { authService } from '../../common/services/auth.service';
import { useAuthData } from '../../context/auth.context';
import { checkEmail, resetValidationSchema } from '../utils/auth.util';

interface IProps {
  errorLoginMsg?: string;
  setErrorLoginMsg?: React.Dispatch<React.SetStateAction<string>>;
  serverErrorMsg?: string;
  setServerErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
}
interface IFormikData {
  login: string;
}

export const ResetEmailForm: FC<IProps> = ({
  errorLoginMsg,
  setErrorLoginMsg = () => {},
  serverErrorMsg,
  setServerErrorMsg = () => {}
}) => {
  const { push } = useHistory();
  const { search } = useLocation();

  const initialValues = {
    login: search.split('?')[1]
  };

  const { onSetEmail, onSetPhone } = useAuthData();
  const [submitValue, setSubmitValue] = useState('');

  const { mutate: resetMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ login }) => {
      const isEmail = checkEmail(login);
      const args = isEmail ? { email: login } : { phone: login };
      return authService.resetPassword(args);
    },
    {
      onSuccess: () => {
        const isEmail = checkEmail(submitValue);
        if (isEmail) onSetEmail(submitValue);
        if (!isEmail) onSetPhone(submitValue);
        const nextRoute = isEmail
          ? APP_KEYS.ROUTER_KEYS.EMAIL_VERIFICATION
          : APP_KEYS.ROUTER_KEYS.PHONE_VERIFICATION;
        push(nextRoute);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ login }) => {
      setSubmitValue(login);
      resetMutation({ login });
    },
    validationSchema: resetValidationSchema()
  });

  const { login } = formik.values;

  if (serverErrorMsg && submitValue && login !== submitValue) {
    setServerErrorMsg('');
  }

  const isValidButton =
    !Object.keys(formik.errors).length && !!login && !errorLoginMsg && !serverErrorMsg;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" mt="0.5rem">
          <Grid item mt="1.5rem">
            <FormInput
              name="login"
              type="text"
              passedValue=""
              placeholder="Enter your Email or Phone Number"
              label="Email / Phone Number"
              setErrorMsg={setErrorLoginMsg}
            />
          </Grid>
          <Grid item mt="1.75rem">
            <CustomButton variant="contained" fullWidth type="submit" disabled={!isValidButton}>
              Continue
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
