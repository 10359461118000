import React, { useState } from 'react';
import { Text } from '../../../common/components/global-typography';
import { ActionAcon, EditFriendActionContainer } from './edit-friend-action.styled';
import { useHover } from '../../../common/hooks';

interface EditFriendPopoverActionProps {
  text: string;
  activeIcon: string;
  inactiveIcon: string;
  activeColor: string;
  inactiveColor: string;
  action: () => void;
  isLast?: boolean;
}

export const EditFriendPopoverAction = ({
  text,
  activeIcon,
  inactiveIcon,
  activeColor,
  inactiveColor,
  action,
  isLast = false
}: EditFriendPopoverActionProps) => {
  const { isHover, onMouseOver, onMouseOut } = useHover();

  return (
    <EditFriendActionContainer
      onClick={action}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      isLast={isLast}
    >
      <ActionAcon
        src={isHover ? activeIcon : inactiveIcon}
        alt="edit_friend_action"
        width="14"
        height="18"
      />
      <Text colorVariant={isHover ? activeColor : inactiveColor} variant="body1">
        {text}
      </Text>
    </EditFriendActionContainer>
  );
};
