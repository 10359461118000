import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import * as Styled from './conversation.styled';
import { PageWrapper } from '../common/components';
import { ConversationItem } from './conversation-item.component';
import { conversationService } from '../common/services';
import { LOCAL_VIDEO, useWebRTC } from './webrtc/useWebRTC';
import { useDecoratedQuery } from '../common/hooks';
import { localStorageService } from '../common/services/local-storage.service';
import { userService } from '../common/services/user.service';
import logo from '../../assets/image/near-logo.svg';
import phone from '../../assets/icons/phone.svg';
import { useEndAudioRoom } from '../common/hooks/use-end-audio-room';
import { getAvatarUrl } from './utils/get-avatar-url.util';

interface IProps {}

async function notifyMe(roomInfo: any, username: string) {
  const friendUsername =
    username === roomInfo.members[0].user.username
      ? roomInfo.members[1].user.username
      : roomInfo.members[0].user.username;

  const options = {
    icon: logo,
    body: `Conversation started with @${friendUsername}`
  };

  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification('Near team', options);
    // …
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    await Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const notification = new Notification('Near team', options);
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

export const ConversationPage: FC<IProps> = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { push } = useHistory();

  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const { username } = data || {};

  const roomID = searchParams.get('room') || '';
  const { clients, provideMediaRef } = useWebRTC(roomID);

  const { data: roomInfo, isLoading } = useDecoratedQuery(
    ['conversation', searchParams.get('room') || ''],
    () => conversationService.getConversation(searchParams.get('room') || ''),
    {
      retry: 1,
      onError: () => {
        push('/');
      }
    }
  );

  const { mutateAsync: deleteRoom } = useEndAudioRoom();

  const onEndRoom = () => {
    deleteRoom({});
  };

  useEffect(() => {
    if (!roomInfo || !username) return;
    setTimeout(() => notifyMe(roomInfo, username), 1000);
  }, [roomInfo, username]);

  return (
    <PageWrapper withHeader>
      <div>
        {clients.map((clientID: string) => (
          <div key={clientID} id={clientID}>
            <audio
              ref={(instance) => {
                provideMediaRef(clientID, instance);
              }}
              autoPlay
              playsInline
              muted={clientID === LOCAL_VIDEO}
            />
          </div>
        ))}
      </div>
      <Styled.ConversationContainer container columnSpacing={4}>
        {isLoading && !roomInfo ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Styled.Wrapper>
            <Styled.Container>
              <ConversationItem
                userName={roomInfo.members[0].user.username || ''}
                avatarUrl={getAvatarUrl(roomInfo.members[0])}
              />
              <ConversationItem
                userName={roomInfo.members[1].user.username || ''}
                avatarUrl={getAvatarUrl(roomInfo.members[1])}
              />
            </Styled.Container>

            <div>
              <Styled.EndCallButton onClick={onEndRoom}>
                <img src={phone} alt="phone" width="30" height="12" />
                <Styled.ButtonTextWrapper>
                  <Styled.ButtonEndCallText>End call</Styled.ButtonEndCallText>
                  <Styled.ButtonByeByeText>- &quot;Bye Bye&quot;</Styled.ButtonByeByeText>
                </Styled.ButtonTextWrapper>
              </Styled.EndCallButton>
            </div>
          </Styled.Wrapper>
        )}
      </Styled.ConversationContainer>
    </PageWrapper>
  );
};
