export const SearchIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5264 17.106L14.9282 12.5076C15.7095 11.2638 16.1629 9.79372 16.1629 8.21606C16.1629 3.75262 12.5446 0.134766 8.08129 0.134766C3.61803 0.134766 0 3.75262 0 8.21606C0 12.6797 3.61786 16.2972 8.08129 16.2972C9.7982 16.2972 11.3885 15.7604 12.6971 14.8483L17.2405 19.3921C17.5562 19.7074 17.9701 19.8645 18.3834 19.8645C18.7973 19.8645 19.2107 19.7074 19.5269 19.3921C20.1578 18.7605 20.1578 17.7375 19.5264 17.106ZM8.08129 13.679C5.06453 13.679 2.61873 11.2333 2.61873 8.21639C2.61873 5.19946 5.06453 2.75366 8.08129 2.75366C11.0982 2.75366 13.5439 5.19946 13.5439 8.21639C13.5439 11.2333 11.0982 13.679 8.08129 13.679Z"
        fill="#B0B0B0"
      />
    </svg>
  );
};
