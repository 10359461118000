import React, { FC, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { FormLabel, Grid, Switch } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import {
  CustomButton,
  FormInput,
  Overlay,
  PageWrapper,
  SettingsWrapper
} from '../common/components';
import { FormTextarea } from '../common/components/form-textarea';
import { PasswordSection } from './password-section.component';
import { ChangePassword } from './change-password.component';
import { SetupPassword } from './setup-password.component';
import { APP_KEYS } from '../common/consts';
import { customErrorHandler } from '../common/utils/error.util';
import { userService } from '../common/services/user.service';
import { IUserInfoRequest } from '../common/services/types';
import { settingsValidationSchema } from '../auth/utils/auth.util';
import { toastSuccess } from '../common/components/toast/toast.component';
import { OverlayCover } from '../common/components/overlayCover';
import { DeleteAccountPopoverContainer } from './delete-account-popover.container';
import { ChooseAvatar } from './choose-avatar.component';
import {
  MainFormContainer,
  UploadAndPhoneContainer,
  UsernameAndEmailContainer
} from './settings.styled';
import { useUploadAvatar } from './hooks';
import { AvatarContext } from '../context/avatar.context';

interface IProps {
  isNewAccount: boolean;
  password: string | null;
  initialValues: IUserInfoRequest;
  refetch: Function;
  isShowPasswordSection: boolean;
}

interface IFormikData {
  email: string | null;
  phone: string | null;
  description: string;
  username: string;
}

export const SettingsPage: FC<IProps> = ({
  isNewAccount,
  password,
  initialValues,
  refetch,
  isShowPasswordSection
}) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [speechRecognitionModule, setSpeechRecognitionModule] = useState(
    localStorage.getItem('speechRecognitionModule') !== 'native'
  );

  const { avatarUrl, sizeError } = useContext(AvatarContext);
  const [image, setImage] = useState([]);

  const handleChangeSpeechRecognitionModule = (event: any) => {
    setSpeechRecognitionModule(event.target.checked);
    localStorage.setItem('speechRecognitionModule', event.target.checked ? 'azure' : 'native');

    window.location.reload();
  };

  const { mutate: setUserMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ email, phone, username, description }) =>
      userService.setUserInfo({ email, phone, username, description, isNewAccount: false }),
    {
      onSuccess: async (res) => {
        toastSuccess(res.message);
        await refetch();
        push(APP_KEYS.ROUTER_KEYS.HOME);
      },
      onError: customErrorHandler
    }
  );

  const { mutateAsync: uploadAvatar } = useUploadAvatar();

  const getPopover = (params: string) => {
    switch (true) {
      case params.includes(`popover=${APP_KEYS.POPOVER_KEYS.CHANGE_PASSWORD}`):
        return <ChangePassword />;
      case params.includes(`popover=${APP_KEYS.POPOVER_KEYS.SETUP_PASSWORD}`):
        return <SetupPassword />;
      default:
        return null;
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ email, phone, username, description }) => {
      const usernameLowerCase = username.toLowerCase();

      setUserMutation({
        email: email || null,
        phone: phone || null,
        username: usernameLowerCase,
        description
      });

      if (avatarUrl[0] !== '' && !sizeError && image[0]) {
        const formData = new FormData();
        formData.append('avatarUploaded', image[0]);
        uploadAvatar(formData);
      }
    },
    validationSchema: settingsValidationSchema()
  });

  const showPopover = search.includes('popover');
  const { email, phone, username } = formik.values;

  const isValidButton = !Object.keys(formik.errors).length && !!username && (!!email || !!phone);
  const errorMsg =
    Object.values(formik.errors).length > 0 ? (
      <>
        {Object.values(formik.errors).map((item) => (
          <p>{item}</p>
        ))}
      </>
    ) : undefined;

  const handleClickDelete = () => {
    setShowConfirm(true);
  };

  const handleClosePopover = () => {
    setShowConfirm(false);
  };
  return (
    <>
      {showPopover && <Overlay>{getPopover(search)}</Overlay>}
      {showConfirm && (
        <OverlayCover>
          <DeleteAccountPopoverContainer handleClose={handleClosePopover} />
        </OverlayCover>
      )}
      <PageWrapper withHeader justifyContent="center" alignItems="center">
        <SettingsWrapper title="Settings" isNewAccount={isNewAccount} errorMsg={errorMsg}>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <MainFormContainer container>
                <UsernameAndEmailContainer item container direction="column" xs={5.7}>
                  <Grid item mt="1.25rem" minHeight="90px">
                    <FormInput
                      name="username"
                      type="text"
                      passedValue=""
                      placeholder="Create your Username"
                      label="Username"
                      subText="*"
                      errorPlaceholder
                    />
                    <div style={{ fontSize: '0.78rem', paddingLeft: '5px' }}>
                      * Your username will be used by other users to start a conversation with you,
                      so please make sure it’s easy to pronounce it in English
                    </div>
                  </Grid>
                  <Grid item mt="1.25rem" minHeight="90px">
                    <FormInput
                      name="email"
                      type="email"
                      passedValue=""
                      placeholder="Enter your Email Address"
                      label="Email Address"
                      notTouchableError={!email && !phone}
                      errorPlaceholder
                      disabled={!isShowPasswordSection}
                    />
                  </Grid>
                </UsernameAndEmailContainer>
                <UploadAndPhoneContainer direction="column" xs={5.9}>
                  <Grid item mt="1.25rem" minHeight="120px">
                    <ChooseAvatar image={image} setImage={setImage} />
                  </Grid>
                  <Grid mt="1.25rem" item minHeight="90px">
                    <FormInput
                      name="phone"
                      type="phone"
                      passedValue=""
                      placeholder="Example +123456789"
                      label="Phone Number"
                      notTouchableError={!email && !phone}
                      orError={!email && !phone}
                      errorPlaceholder
                    />
                  </Grid>
                </UploadAndPhoneContainer>
              </MainFormContainer>
              <Grid item container mt="1.25rem">
                <FormTextarea
                  type="text"
                  name="description"
                  passedValue=""
                  label="Description"
                  placeholder="Tell something about yourself..."
                />
              </Grid>
              {isShowPasswordSection && (
                <Grid item container mt="1.25rem">
                  <PasswordSection password={password} />
                </Grid>
              )}

              <Grid item container mt="1.25rem" justifyContent="space-between">
                <Grid item xs>
                  <FormLabel component="legend" style={{ color: 'black', fontWeight: 500 }}>
                    Assigned speech module (a page reload is required to change)
                  </FormLabel>
                  <Grid item xs>
                    <Switch
                      checked={speechRecognitionModule}
                      onChange={handleChangeSpeechRecognitionModule}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {speechRecognitionModule
                      ? 'azure speech recognition module'
                      : 'native speech recognition module'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container mt="1.25rem" justifyContent="space-between">
                <Grid item xs>
                  <CustomButton variant="outlined" color="error" onClick={handleClickDelete}>
                    Delete Account
                  </CustomButton>
                </Grid>
                <Grid item xs container justifyContent="end">
                  <Grid item mr="2.5rem">
                    <CustomButton
                      variant="outlined"
                      width="182px"
                      disabled={isNewAccount}
                      onClick={() => push(APP_KEYS.ROUTER_KEYS.HOME)}
                    >
                      Cancel
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <CustomButton
                      variant="contained"
                      width="182px"
                      type="submit"
                      disabled={!isValidButton || Boolean(sizeError)}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormikProvider>
        </SettingsWrapper>
      </PageWrapper>
    </>
  );
};
