import React, { FC } from 'react';
import { useSocketData } from '../context/socket.context';
import { Styled } from './styles.styled';
import { PageWrapper } from '../common/components';
import { matchSpeech } from '../common/utils';
import { speechService } from '../common/services';
import { ESoundTypes, useSound } from '../context/sound.context';

export const SpeechTestContainer: FC = () => {
  const { recognizedText, setRecognizedText } = useSocketData();

  const recognizeCallback = (text: string) => {
    const commandMatch = matchSpeech(text);
    if (commandMatch[0]) {
      setRecognizedText(`User start call with ${commandMatch[0]}`);
    }
    if (commandMatch[1]) {
      setRecognizedText('User ended call');
    }
    if (commandMatch[2]) {
      setRecognizedText('User is busy now');
    }
  };

  const start = () => {
    speechService.init(recognizeCallback);
  };

  const stop = () => {
    speechService.stop();
  };

  const { play } = useSound();

  return (
    <PageWrapper>
      <Styled.ResponseWrapper>
        <button type="button" onClick={() => play(ESoundTypes.START_CALL)}>
          Start call sound
        </button>
        <button type="button" onClick={() => play(ESoundTypes.END_CALL)}>
          End call sound
        </button>
        <button type="button" onClick={() => play(ESoundTypes.USER_BUSY)}>
          User busy sound
        </button>
        <button type="button" onClick={() => play(ESoundTypes.COMMAND_UNKNOWN)}>
          Command unknown sound
        </button>
        <button type="button" onClick={start}>
          Start
        </button>
        <button type="button" onClick={stop}>
          Stop
        </button>
        <div>Recognized Text: </div>
        <div>{recognizedText}</div>
      </Styled.ResponseWrapper>
    </PageWrapper>
  );
};
