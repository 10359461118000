import styled from 'styled-components';
import { WEIGHTS, SIZES } from '../../../theme/fonts.const';
import { COLORS } from '../../../theme';

export const RecordSuccessfullContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

export const RecordSuccessfullHeader = styled.div`
  width: 310px;
  margin-bottom: 44px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderText = styled.p`
  font-weight: ${WEIGHTS.bold};
  font-size: ${SIZES.xxxxxl};
  line-height: 36px;
  letter-spacing: 0.02em;
`;

export const BackWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BackText = styled.p`
  margin-right: 5px;
  font-size: ${SIZES.xl};
  line-height: 24px;
  letter-spacing: 0.02em;
`;

export const BackBtn = styled.button`
  padding: 0;
  background-color: ${COLORS.transparent};
  color: ${COLORS.veryLightAzure};
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
`;
