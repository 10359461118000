import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const MultiInputContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.spacing(6)};
`;

export const MultiInputItem = styled.input<{ theme?: Theme }>`
  height: 58px;
  width: 58px;
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  border-radius: 10px;
  text-align: center;

  &:focus-within {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    border: 1px solid ${({ theme }) => theme.palette.primary.dark};
    outline: none;
  }
`;
