import React, { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomButton, FormInput } from '../../common/components';
import { authService } from '../../common/services/auth.service';
import { getCodeFromUrl } from './auth.util';
import { APP_KEYS } from '../../common/consts';
import { newPasswordValidationSchema } from '../utils/auth.util';

interface IProps {
  firstPasswordErrorMsg?: string;
  setFirstPasswordErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
  secondPasswordErrorMsg?: string;
  setSecondPasswordErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
  customErrorMsg?: string;
  setCustomErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
  serverErrorMsg?: string;
  setServerErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
}
interface IFormikData {
  password: string;
  code: string;
}
const initialValues = {
  newPassword1: '',
  newPassword2: ''
};

export const NewPasswordForm: FC<IProps> = ({
  firstPasswordErrorMsg,
  setFirstPasswordErrorMsg = () => {},
  secondPasswordErrorMsg,
  setSecondPasswordErrorMsg = () => {},
  customErrorMsg,
  setCustomErrorMsg = () => {},
  serverErrorMsg,
  setServerErrorMsg = () => {}
}) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [submitValue, setSubmitValue] = useState('');

  const { mutate: setPasswordMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ password, code }) => authService.setPassword({ password, code }),
    {
      onSuccess: () => {
        push(APP_KEYS.ROUTER_KEYS.LOGIN);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ newPassword1, newPassword2 }) => {
      if (newPassword1 !== newPassword2) {
        // setCustomErrorMsg(ERROR_MSG.PASS_NOT_MATCH);
      } else {
        setSubmitValue(newPassword1);
        const code = getCodeFromUrl(search);
        setPasswordMutation({ password: newPassword1, code });
      }
    },
    validationSchema: newPasswordValidationSchema()
  });

  const { newPassword1, newPassword2 } = formik.values;

  const str = null;
  Object.values(formik.errors).forEach((item) => str + item);

  setCustomErrorMsg(Object.values(formik.errors).join('<br>'));

  const isValidButton =
    !Object.keys(formik.errors).length &&
    !!newPassword1 &&
    !!newPassword2 &&
    !firstPasswordErrorMsg &&
    !secondPasswordErrorMsg &&
    !customErrorMsg &&
    !serverErrorMsg;

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" mt="0.5rem">
          <Grid item mt="1.5rem">
            <FormInput
              name="newPassword1"
              type="password"
              passedValue=""
              placeholder="Enter your New Password"
              label="New Password"
              setErrorMsg={setFirstPasswordErrorMsg}
            />
          </Grid>
          <Grid item mt="1.5rem">
            <FormInput
              name="newPassword2"
              type="password"
              passedValue=""
              placeholder="Enter your New Password"
              label="Confirm New Password"
              setErrorMsg={setSecondPasswordErrorMsg}
              customError={!!customErrorMsg}
            />
          </Grid>
          <Grid item mt="1.75rem">
            <CustomButton variant="contained" fullWidth type="submit" disabled={!isValidButton}>
              Reset Password
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
};
