import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../consts/app-keys.const';
import { userService } from '../services/user.service';

export const useGetUser = () => {
  const getCurrentUser = async () => userService.onAllRequest();

  return useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: getCurrentUser,
    retry: false,
    staleTime: Infinity
  });
};
