import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Text } from '../common/components/global-typography';

interface IProps {
  title?: string;
  children?: React.ReactNode;
}

export const EmptyPageContainer: FC<IProps> = ({ title, children }) => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" mt="3rem">
      <Text
        colorVariant="secondary.main"
        variant="subtitle2"
        fontWeight="fontWeightBold"
        mb="1.5rem"
        sx={{ cursor: 'text' }}
      >
        {title}
      </Text>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Grid>
  );
};
