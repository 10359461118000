import React, { FC, useContext, useState } from 'react';
import { Skeleton } from '@mui/material';
import * as Styled from './user-item.styled';
import { EStatusTypes } from '../../types/components.types';
import { useGetUser } from '../../hooks';
import { StyledAvatar } from './user-icon.styled';
import { getUserAvatar } from './utils/get-user-avatar.util';
import { IUser } from '../../services/types';
import { AvatarContext } from '../../../context/avatar.context';

interface IProps {
  friend?: IUser;
  isAccountOwner?: boolean;
  id: string;
  status?: EStatusTypes;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UserIcon: FC<IProps> = ({ id, status, friend, isAccountOwner = false }) => {
  const { data: user, isLoading } = useGetUser();

  const { avatarUrl } = useContext(AvatarContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const renderUserAvatar = () => {
    if (avatarUrl[0] && id === user?.id) {
      return (
        <picture>
          <source srcSet={avatarUrl[0]} type="image/png" />
          <StyledAvatar src="" alt="user_avatar" onLoad={handleImageLoad} width={52} height={52} />
        </picture>
      );
    }

    return (
      <>
        {isLoading ? (
          <Skeleton variant="circular" width={52} height={52} />
        ) : (
          <picture>
            <source srcSet={getUserAvatar(isAccountOwner ? user : friend)} type="image/png" />
            <StyledAvatar
              src=""
              alt="user_avatar"
              onLoad={handleImageLoad}
              width={52}
              height={52}
            />
          </picture>
        )}
        {!isLoaded && <Skeleton variant="circular" width={52} height={52} />}
      </>
    );
  };

  return (
    <Styled.UserIconContainer status={isAccountOwner ? EStatusTypes.ONLINE : friend?.status}>
      {renderUserAvatar()}
    </Styled.UserIconContainer>
  );
};
