import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

export interface ModalContextProps {
  children: JSX.Element | JSX.Element[];
}

export interface IModalContext {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  friendId: string;
  setFriendId: Dispatch<SetStateAction<string>>;
}

export const ModalContext = createContext<IModalContext>({
  isModalOpen: false,
  setIsModalOpen: () => {},
  friendId: '',
  setFriendId: () => {}
});

export const ModalState = ({ children }: ModalContextProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [friendId, setFriendId] = useState('');

  const value = useMemo(
    () => ({
      isModalOpen,
      setIsModalOpen,
      friendId,
      setFriendId
    }),
    [isModalOpen, setIsModalOpen, friendId, setFriendId]
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};
