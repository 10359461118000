import styled from 'styled-components';
import { COLORS } from '../../../theme';
import { SIZES, WEIGHTS } from '../../../theme/fonts.const';

export const RecordVoiceModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 506px;
  padding: 32px 32px 44px 58px;

  background-color: ${COLORS.white};
  box-shadow: 1px 5px 10px ${COLORS.mostLightBlue};
  border-radius: 20px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

export const Title = styled.h3`
  font-weight: ${WEIGHTS.bold};
  font-size: ${SIZES.xxxxxl};
  line-height: 36px;
  letter-spacing: 0.02em;
`;

export const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.veryLightAzure};
  border-radius: 50%;
  outline: none;

  cursor: pointer;
`;

export const MirophoneIcon = styled.img`
  margin-bottom: 32px;
`;

export const Tip = styled.p`
  font-size: ${SIZES.m};
  line-height: 18px;
  color: ${COLORS.offlineBorder};
  margin-bottom: 26px;
`;

export const TipType = styled.span`
  font-weight: ${WEIGHTS.bold};
  font-size: ${SIZES.m};
  line-height: 18px;
  color: ${COLORS.black};
`;
