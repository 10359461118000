export const EmptyFriendSearchIcon = () => {
  return (
    <svg
      width="139"
      height="143"
      viewBox="0 0 139 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9135 62.3933C14.9135 62.3933 39.1453 61.4229 44.3819 21.591C49.0321 -13.7836 89.8932 2.31759 101.705 16.4187C117.005 34.6856 108.849 66.1084 128.642 70.078C148.436 74.0477 135.51 118.183 105.465 113.958C68.0901 108.703 77.1327 130.543 65.9129 139.787C57.8607 146.422 24.8437 140.035 23.7433 115.952C22.8173 95.6857 14.2687 95.7499 8.20946 93.452C-0.529427 90.1378 -6.03589 66.1356 14.9135 62.3933Z"
        fill="#EAF1F6"
      />
      <path
        d="M97.2875 106.629L73.4805 83.1045L68.7663 87.8753L92.5733 111.4L97.2875 106.629Z"
        fill="#6BB3F2"
      />
      <path
        d="M106.383 112.951L87.8605 94.6484C87.7574 94.5465 87.5912 94.5475 87.4893 94.6506L80.4789 101.745C80.377 101.848 80.378 102.015 80.4811 102.116L99.0031 120.419C99.1062 120.52 99.2724 120.519 99.3743 120.416L106.385 113.322C106.487 113.219 106.486 113.052 106.383 112.951Z"
        fill="#0583F2"
      />
      <path
        d="M52.3805 94.9359C68.7591 94.9359 82.0365 81.6585 82.0365 65.28C82.0365 48.9014 68.7591 35.624 52.3805 35.624C36.002 35.624 22.7246 48.9014 22.7246 65.28C22.7246 81.6585 36.002 94.9359 52.3805 94.9359Z"
        fill="#0583F2"
      />
      <path
        d="M52.3815 90.0969C66.0875 90.0969 77.1985 78.986 77.1985 65.2799C77.1985 51.5738 66.0875 40.4629 52.3815 40.4629C38.6754 40.4629 27.5645 51.5738 27.5645 65.2799C27.5645 78.986 38.6754 90.0969 52.3815 90.0969Z"
        fill="#EAF1F6"
      />
      <path
        d="M102.517 38.4706C95.5607 36.2158 88.0431 40.0293 85.7791 47.0141C83.5244 53.9704 87.3378 61.488 94.3228 63.7518C101.308 66.0157 108.806 62.1649 111.061 55.2083C113.316 48.2517 109.474 40.7253 102.517 38.4706ZM100.418 44.9464C101.197 45.1988 101.883 45.6768 102.389 46.3197C102.896 46.9626 103.2 47.7414 103.264 48.5575C103.327 49.3736 103.146 50.1901 102.745 50.9034C102.344 51.6168 101.739 52.1948 101.009 52.5643C100.279 52.9338 99.455 53.078 98.6426 52.9788C97.8301 52.8795 97.0654 52.5411 96.4455 52.0066C95.8256 51.4721 95.3784 50.7656 95.1606 49.9766C94.9429 49.1876 94.9643 48.3516 95.2223 47.5748C95.9189 45.4257 98.2684 44.2498 100.418 44.9464ZM95.166 61.1502C92.8549 60.4049 90.8748 58.8794 89.5648 56.8348C92.0162 55.4419 94.5794 55.0539 96.8984 55.8055C99.2173 56.5572 101.103 58.3557 102.234 60.941C99.9734 61.8284 97.4749 61.9024 95.166 61.1502Z"
        fill="#0583F2"
      />
      <path
        d="M44.6176 101.861C43.4093 102.477 42.4111 103.439 41.7491 104.623C41.0871 105.807 40.7911 107.161 40.8985 108.513C41.0059 109.865 41.5119 111.155 42.3525 112.22C43.1932 113.285 44.3306 114.076 45.6211 114.495C46.9116 114.913 48.2971 114.939 49.6025 114.57C50.9079 114.201 52.0745 113.453 52.9547 112.42C53.835 111.388 54.3894 110.118 54.5479 108.771C54.7063 107.424 54.4617 106.06 53.845 104.851C53.0165 103.233 51.5797 102.008 49.8498 101.448C48.1198 100.887 46.2382 101.036 44.6176 101.861ZM46.2152 104.99C46.5915 104.798 47.0164 104.722 47.436 104.772C47.8556 104.822 48.2509 104.995 48.5719 105.27C48.8929 105.544 49.1251 105.908 49.2389 106.315C49.3528 106.722 49.3433 107.154 49.2115 107.555C49.0798 107.957 48.8318 108.31 48.4989 108.57C48.1661 108.83 47.7635 108.986 47.3421 109.017C46.9207 109.048 46.4996 108.953 46.1321 108.745C45.7647 108.536 45.4674 108.223 45.2781 107.846C45.0272 107.342 44.9849 106.76 45.1603 106.225C45.3357 105.691 45.7147 105.247 46.2152 104.99V104.99ZM50.2124 112.822C49.0969 113.393 47.8174 113.559 46.593 113.291C46.9793 111.888 47.7733 110.811 48.8942 110.238C50.0152 109.666 51.3596 109.635 52.716 110.166C52.2148 111.314 51.3297 112.253 50.2124 112.821V112.822Z"
        fill="#0583F2"
      />
    </svg>
  );
};
