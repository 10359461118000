import React from 'react';
import { DefaultAvatarContainer } from './default-avatar.styled';

type DefaultAvatarProps = {
  src: string;
  id: string;
  isChoosen: boolean;
  getAvatarId: (id: string) => void;
};

export const DefaultAvatar = ({ isChoosen, src, id, getAvatarId }: DefaultAvatarProps) => {
  const onAvatarClick = () => {
    getAvatarId(id);
  };

  return (
    <DefaultAvatarContainer onClick={onAvatarClick} isChoosen={isChoosen}>
      <img src={src} alt="default_user_avatar" width="29" height="29" />
    </DefaultAvatarContainer>
  );
};
