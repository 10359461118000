import styled from 'styled-components';
import { COLORS } from '../../../theme';

export const EditFriendActionContainer = styled.div<{ isLast: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : `1px solid ${COLORS.offline}`)};
`;

export const ActionAcon = styled.img`
  margin-right: 10px;
`;
