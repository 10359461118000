import React, { FC, useEffect, useState } from 'react';
import { AutocompleteInput } from '../common/components/autocomplete-input';
import { friendService } from '../common/services/friend.service';
import { IPagination } from '../common/utils';

interface IProps {
  onSetNoUser: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Container: FC<IProps> = ({ onSetNoUser }) => {
  const [searchValue, setSearchValue] = useState('');
  const { data, isLoading, isFetching } = friendService.useListQuery<any[]>(
    { match: searchValue },
    {} as IPagination
  );

  const users = data || [];
  const options = users.map((el) => ({ ...el, label: el.username }));

  useEffect(() => {
    if (!options.length && searchValue && !isLoading && !isFetching) {
      onSetNoUser(true);
    } else {
      onSetNoUser(false);
    }
  }, [searchValue, options]);

  const handleClearSearch = () => {
    setSearchValue('');
  };

  return (
    <AutocompleteInput
      placeholder="Search new friend..."
      passedOptions={options}
      onSetSearchValue={setSearchValue}
      searchValue={searchValue}
      handleClearSearch={handleClearSearch}
    />
  );
};

export const SearchContainer = React.memo(Container);
