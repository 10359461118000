import { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { ChangePasswordPageContainer } from './change-password-container.component';
import { CustomButton, FormInput } from '../common/components';
import { userService } from '../common/services/user.service';
import { toastSuccess } from '../common/components/toast/toast.component';
import { APP_KEYS, ERROR_MSG } from '../common/consts';
import { setPasswordValidationSchema } from '../auth/utils/auth.util';
import { localStorageService } from '../common/services/local-storage.service';

interface IProps {}

interface IFormikData {
  password: string;
}

const initialValues = {
  newPassword: '',
  confirmNewPassword: ''
};

export const SetupPassword: FC<IProps> = () => {
  const { push } = useHistory();
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState<string>('');
  const [confirmNewPasswordErrorMsg, setConfirmNewPasswordErrorMsg] = useState<string>('');
  const [customErrorMsg, setCustomErrorMsg] = useState<string>('');
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');
  const [submitValue, setSubmitValue] = useState('');

  const { mutate: setupPasswordMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ password }) => userService.setupPassword({ password }),
    {
      onSuccess: (res) => {
        toastSuccess(res.message);
        localStorageService.removeTokenFromStorage();
        push(APP_KEYS.ROUTER_KEYS.LOGIN);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ newPassword, confirmNewPassword }) => {
      if (newPassword !== confirmNewPassword) {
        setCustomErrorMsg(ERROR_MSG.PASS_NOT_MATCH);
      } else {
        setSubmitValue(newPassword);
        setupPasswordMutation({ password: newPassword });
      }
    },
    validationSchema: setPasswordValidationSchema()
  });

  const { newPassword, confirmNewPassword } = formik.values;

  if (
    serverErrorMsg &&
    submitValue &&
    (submitValue !== newPassword || submitValue !== confirmNewPassword)
  ) {
    setServerErrorMsg('');
  }

  if (
    !newPasswordErrorMsg &&
    customErrorMsg === ERROR_MSG.PASS_NOT_MATCH &&
    newPassword === confirmNewPassword
  ) {
    setCustomErrorMsg('');
  }

  const isValidButton =
    !Object.keys(formik.errors).length &&
    !!newPassword &&
    !!confirmNewPassword &&
    !newPasswordErrorMsg &&
    !confirmNewPasswordErrorMsg &&
    !customErrorMsg &&
    !serverErrorMsg;

  const errorMsg =
    serverErrorMsg || newPasswordErrorMsg || confirmNewPasswordErrorMsg || customErrorMsg;

  return (
    <ChangePasswordPageContainer title="Set Up Password" errorMsg={errorMsg}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" mt="0.5rem">
            <Grid item mt="1.5rem">
              <FormInput
                name="newPassword"
                type="password"
                passedValue=""
                placeholder="Enter your New Password"
                label="New Password"
                setErrorMsg={setNewPasswordErrorMsg}
              />
            </Grid>
            <Grid item mt="1.5rem">
              <FormInput
                name="confirmNewPassword"
                type="password"
                passedValue=""
                placeholder="Enter your New Password"
                label="Confirm New Password"
                setErrorMsg={setConfirmNewPasswordErrorMsg}
                customError={!!customErrorMsg}
              />
            </Grid>
            <Grid item mt="1.75rem">
              <CustomButton variant="contained" fullWidth type="submit" disabled={!isValidButton}>
                Set Up Password
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </ChangePasswordPageContainer>
  );
};
