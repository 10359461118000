import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Styled from './overlayCover.styled';

interface IProps {
  children?: React.ReactNode;
}

export const OverlayCover: FC<IProps> = ({ children }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClose = () => {
    push(pathname);
  };

  return (
    <Styled.OverlayContainer
      container
      justifyContent="center"
      alignItems="center"
      onClick={handleClose}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Styled.OverlayContainer>
  );
};
