import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';

import { AuthWrapper, Text, CustomLink } from '../common/components';
import { APP_KEYS } from '../common/consts';
import { ESoundTypes, useSound } from '../context/sound.context';
import { notifyMessage } from '../auth/utils/notificationMessage.util';

interface IProps {
  title?: string;
  description?: string | null;
}

export const ConversationNotificationContainer: FC<IProps> = ({ title, description }) => {
  const [progress, setProgress] = useState(100);
  const { push } = useHistory();
  const { search } = useLocation();
  const { play } = useSound();

  const searchParams = new URLSearchParams(search);
  const name = searchParams.get('name');

  useEffect(() => {
    const timer1 = setInterval(() => {
      setProgress((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 3));
    }, 100);

    if (title === 'Oops...') notifyMessage(`  ${description} `);
    else {
      notifyMessage(`${title?.replace('Name', `@${name!}`)} `);
    }
    play(ESoundTypes.COMMAND_UNKNOWN);

    return () => {
      clearInterval(timer1);
    };
  }, []);

  useEffect(() => {
    if (progress === 0) push(APP_KEYS.ROUTER_KEYS.HOME);
  }, [progress]);

  return (
    <AuthWrapper withLogo={false} variant="h5">
      <Grid item container justifyContent="center" mt="1rem">
        <Text
          colorVariant="common.black"
          variant="subtitle2"
          letterSpacing="0.02em"
          fontWeight="600"
        >
          {name ? title?.replace('Name', `@${name!}`) : title}
        </Text>
      </Grid>
      <Grid item container justifyContent="center" mt="1rem">
        <Text colorVariant="common.black" variant="subtitle2" letterSpacing="0.02em">
          {description}
        </Text>
      </Grid>
      <Grid item container justifyContent="center" mt="1rem">
        <CircularProgress variant="determinate" value={progress} />
      </Grid>
      <Grid item container justifyContent="center" mt="1rem">
        <Text colorVariant="common.black" variant="subtitle2" letterSpacing="0.02em">
          Back to&nbsp;
          <CustomLink to={APP_KEYS.ROUTER_KEYS.HOME}>Homepage</CustomLink>
        </Text>
      </Grid>
    </AuthWrapper>
  );
};
