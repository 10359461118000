import { FC, MouseEventHandler } from 'react';
import * as Styled from './tabs-switcher.styled';
import { Text } from '../global-typography';
import { EHomeTabs } from '../../types/components.types';
import { RequestsCount } from './tabs-switcher.styled';

interface IProps {
  title?: string;
  activeTab?: EHomeTabs;
  requests?: number;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export const TabsSwitcher: FC<IProps> = ({ title, activeTab, requests, onClick }) => {
  const isActive = title === activeTab;
  return (
    <Styled.TabsSwitcherContainer onClick={onClick}>
      <Text
        colorVariant={isActive ? 'common.black' : 'secondary.dark'}
        variant="subtitle2"
        fontWeight="fontWeightBold"
        position="relative"
      >
        {title}
        {requests ? <RequestsCount>{requests}</RequestsCount> : null}
      </Text>
      <Styled.Selected isActive={isActive} />
    </Styled.TabsSwitcherContainer>
  );
};
