import styled from 'styled-components';
import { COLORS } from '../theme';

export const DefaultAvatarContainer = styled.div<{ isChoosen: boolean }>`
  display: flex;
  cursor: pointer;

  outline-width: ${({ isChoosen }) => (isChoosen ? '2px' : '1px')};
  outline-style: ${({ isChoosen }) => (isChoosen ? 'solid' : 'none')};
  outline-color: ${({ isChoosen }) => (isChoosen ? `${COLORS.azure}` : `${COLORS.offline}`)};
`;
