import { FC } from 'react';
import { UserItem } from './user-item.component';
import { SentRequestsItemContent } from '../requests-content/sent-equests-content.component';
import { EHomeTabs, EStatusTypes } from '../../types/components.types';
import { IUser } from '../../services/types';

interface IProps {
  id: string;
  status?: EStatusTypes;
  username: string;
  friend: IUser;
}

export const SentRequestsItem: FC<IProps> = ({ id, status, username, friend }) => (
  <UserItem id={id} username={username} status={status} type={EHomeTabs.REQUESTS} friend={friend}>
    <SentRequestsItemContent id={id} />
  </UserItem>
);
