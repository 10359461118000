import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const DividerLine = styled.div<{ theme?: Theme }>`
  border-bottom: solid 1px ${({ theme }) => theme.palette.secondary.main};
  position: relative;
  min-width: 100%;
`;

export const DividerLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
`;

export const DividerLabel = styled.div<{ theme?: Theme }>`
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  position: relative;
  top: 13px;
`;
