import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';

export const SettingsWrapperContainer = styled(Grid)<{ theme?: Theme }>`
  min-height: 100vh;
  width: 976px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding-top: ${({ theme }) => theme.spacing(18)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(8)};
  padding-left: ${({ theme }) => theme.spacing(8)};
`;

export const BackArrowContainer = styled.div<{ theme?: Theme }>`
  position: absolute;
  cursor: pointer;
  top: ${({ theme }) => theme.spacing(0.5)};
  left: -${({ theme }) => theme.spacing(6)};
`;
