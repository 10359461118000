import React, { FC } from 'react';
import * as Styled from './settings.styled';
import { CustomLink, Text } from '../common/components';
import { APP_KEYS } from '../common/consts';

interface IProps {
  password: string | null;
}

export const PasswordSection: FC<IProps> = ({ password }) => {
  const linkText = password ? 'Change' : 'Set Up';
  const param = password
    ? APP_KEYS.POPOVER_KEYS.CHANGE_PASSWORD
    : APP_KEYS.POPOVER_KEYS.SETUP_PASSWORD;
  const redirectLink = `${APP_KEYS.ROUTER_KEYS.SETTINGS}?popover=${param}`;

  return (
    <Styled.PasswordSectionContainer container alignItems="center" justifyContent="space-between">
      <Text
        colorVariant="common.black"
        variant="subtitle2"
        fontWeight="fontWeightMedium"
        letterSpacing="2%"
        sx={{ cursor: 'auto' }}
      >
        Password
      </Text>
      <CustomLink to={redirectLink}>{linkText}</CustomLink>
    </Styled.PasswordSectionContainer>
  );
};
