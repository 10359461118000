import React, { FC } from 'react';
import { HeaderContainer } from './header.styled';
import { NearLogo } from '../near-logo';
import { UserInfo } from './user-info.component';
import { userService } from '../../services/user.service';
import { localStorageService } from '../../services/local-storage.service';

interface IProps {
  headerContent?: React.ReactNode;
}

export const Header: FC<IProps> = () => {
  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const { username, id } = data || {};

  return (
    <HeaderContainer>
      <NearLogo />
      {id ? <UserInfo id={id} username={username} /> : null}
    </HeaderContainer>
  );
};
