export enum EHomeTabs {
  FRIENDS = 'Friends',
  REQUESTS = 'Requests'
}

export enum EStatusTypes {
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE'
}

export enum ESettingsOptions {
  SETTINGS = 'Settings',
  LOGOUT = 'Log out'
}
