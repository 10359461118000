import * as Yup from 'yup';
import { ERROR_MSG } from '../../common/consts';

export const PASSWORD_MATCH = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.{8,20})/;

export const getPasswordValidator = () =>
  Yup.string()
    .strict(true)
    .required('Password is required.')
    .matches(PASSWORD_MATCH, ERROR_MSG.PASSWORD_REQUIREMENTS);

export const getPasswordConfirmValidator = () =>
  Yup.string().oneOf([Yup.ref('newPassword'), undefined], "Passwords don't match");

export const getResetPasswordConfirmValidator = () =>
  Yup.string().oneOf([Yup.ref('newPassword1'), undefined], "Passwords don't match");

export const getLoginPasswordValidator = () =>
  Yup.string().strict(true).required('Password is required.');
