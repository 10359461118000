import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { CustomButton } from '../button';
import { UserItem } from '../user-item';
import { EHomeTabs, EStatusTypes } from '../../types/components.types';
import { toastSuccess } from '../toast/toast.component';
import { customErrorHandler } from '../../utils/error.util';
import { friendService } from '../../services/friend.service';
import { sentRequestService } from '../../services/sent-request.service';
import { IUser } from '../../services/types';
// import { RecordVoiceModal } from '../record-voice-modal';
// import { ModalContext } from '../../../context/modal.context';

interface IProps {
  username: string;
  id: string;
  status: EStatusTypes;
  description?: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  friend: IUser;
}

export const AutocompleteItem: FC<IProps> = ({
  username,
  id,
  status,
  description,
  setOpen,
  friend
}) => {
  // const { setIsModalOpen } = useContext(ModalContext);

  const isAdded = useRef(false);
  const { refetch } = sentRequestService.useAllQuery<any>();
  const { mutate: createFriendMutation } = useMutation<any, AxiosError, string>(
    (userId) => friendService.createFriend({ id: userId }),
    {
      onSuccess: () => {
        refetch();
        toastSuccess('Friend successfully added!');
      },
      onError: customErrorHandler
    }
  );

  const handleAddUser = (e: any) => {
    e.stopPropagation();

    if (!isAdded.current) createFriendMutation(id);
    isAdded.current = true;
    setOpen(false);
    // setIsModalOpen(true);
  };

  return (
    <UserItem
      id={id}
      username={username}
      status={status}
      description={description}
      friend={friend}
      withborder=""
      type={EHomeTabs.REQUESTS}
    >
      <CustomButton variant="contained" size="small" onClick={handleAddUser}>
        {isAdded.current ? 'Added' : 'Add'}
      </CustomButton>
    </UserItem>
  );
};
