// Local storage keys
export const STORAGE_KEYS = {
  TOKEN: 'TOKEN',
  CURRENT_ROOM: 'CURRENT_ROOM',
  SPEECH_API_TOKEN: 'SPEECH_API_TOKEN'
};

// React-query keys
export const QUERY_KEYS = {
  EXAMPLE: 'EXAMPLE',
  TOKEN: 'TOKEN',
  STATISTIC: 'statistic',
  TRENDING: 'trending',
  USER: 'user',
  AVATAR: 'avatar',
  FRIEND_HINT: 'friendHint'
};

// Backend Routes
export const BACKEND_KEYS = {
  EXAMPLE: 'example',
  COURSES: 'courses',
  ARTICLES: 'articles',
  STATISTIC: 'statistic',
  TRENDING: 'trending',
  FEATURED_ARTICLES: 'featured_articles'
};

export const ROUTER_KEYS = {
  HOME: '/',
  AUTHORIZED: 'authorized',
  SIGN_UP: '/signup',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  EMAIL_VERIFICATION: '/email-verification',
  PHONE_VERIFICATION: '/phone-verification',
  PHONE_EMAIL_VERIFICATION: '/phone-email-verification',
  SETTINGS: '/settings',
  CONVERSATION: '/conversation',
  SPEECH_TEST: '/speech-test', // for test purposes
  ROOM: '/room' // for test
};

export const POPOVER_KEYS = {
  CHANGE_PASSWORD: 'change-password',
  SETUP_PASSWORD: 'setup-password'
};
