import React from 'react';
import ReactDOM from 'react-dom';
import AppContainer from './modules/app';
import ContextProvider from './modules/context';

ReactDOM.render(
  <ContextProvider>
    <AppContainer />
  </ContextProvider>,
  document.getElementById('root')
);
