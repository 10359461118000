export const EventTypes = {
  USER_FRIENDLIST_COUNT_CHANGE: 'count.change',
  USER_STATUS_CHANGE: 'status.change'
} as const;

export const SocketEventTypes = {
  SUBSCRIBES: {
    USER_FRIEND_ACCEPTED: 'accepted:get',
    CONVERSATION_USER_ADDED: 'conversation_user_added',
    CONVERSATION_ENDED: 'conversation_ended'
  },
  EMITS: {
    USER_FRINDLIST_SENT_REQUESTS: 'sent_requests_count:get',
    USER_FRIENDLIST_REQUESTS: 'requests_count:get',
    TEST: 'testResult',
    USER_STATUS_GET: 'user_status:get'
  }
} as const;
