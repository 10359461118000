import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { MergedTheme } from '../../theme';

export const HomeWrapperContainer = styled(Grid)<{ theme?: MergedTheme }>`
  min-height: 100vh;
  width: 976px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const BottomBorder = styled(Grid)<{ theme?: MergedTheme }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const RequestHeaderContainer = styled(Grid)<{ theme?: MergedTheme }>`
  min-height: 72px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBlue};
  padding-left: ${({ theme }) => theme.spacing(5.5)};
`;
