import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { userService } from '../../common/services/user.service';
import { IErrorResponse } from '../../common/types';
import {
  ISetDefaultAvatarBody,
  ISetDefaultAvatarResponse,
  IUser
} from '../../common/services/types';
import { QUERY_KEYS } from '../../common/consts/app-keys.const';
import { toastError, toastSuccess } from '../../common/components/toast/toast.component';

export const useUploadDefaultAvatar = () => {
  const queryClient = useQueryClient();

  const uploadAvatar = (body: ISetDefaultAvatarBody) => userService.uploadDefaultAvatar(body);

  const onUpdateAvatarSuccess = (response: ISetDefaultAvatarResponse) => {
    toastSuccess(response?.message || 'Success!');

    queryClient.setQueryData<IUser[] | undefined>([QUERY_KEYS.USER], (prevUserState) => {
      if (prevUserState) {
        return {
          ...prevUserState,
          avatarDefaultUploaded: response?.avatarDefaultUploaded,
          avatarUploaded: null
        };
      }
    });
  };

  const onUpdateAvatarError = (error: AxiosError<IErrorResponse>) => {
    toastError(error?.response?.data?.message ?? error?.message);
  };

  return useMutation<ISetDefaultAvatarResponse, AxiosError<IErrorResponse>, ISetDefaultAvatarBody>({
    // @ts-ignore
    mutationFn: uploadAvatar,
    onSuccess: onUpdateAvatarSuccess,
    onError: onUpdateAvatarError
  });
};
