import React, { FC } from 'react';
import { EmptyPageContainer } from './empty-page-container.component';
import { EmptyRequestsIconComponent } from '../common/components/icons/empty-requests-icon.component';
import { RequestsItem } from '../common/components/user-item/requests-item.component';
import { SentRequestsItem } from '../common/components/user-item/sent-requests-item.component';
import { RequestsHeader } from './home-wrapper/requests-header.component';
import { IUser } from '../common/services/types';
import { friendRequestService } from '../common/services/friend-request.service';
import { sentRequestService } from '../common/services/sent-request.service';

interface IProps {}

export const RequestsTab: FC<IProps> = () => {
  const { data: requestsData } = friendRequestService.useAllQuery<any>();
  const { data: sentRequestsData } = sentRequestService.useAllQuery<any>();
  const requests = requestsData || [];
  const sentRequests = sentRequestsData || [];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {requests.length || sentRequests.length ? (
        <>
          {requests.length ? <RequestsHeader title="Friend requests" /> : null}
          {requests.map((item: IUser) => (
            <RequestsItem
              key={item.id}
              status={item.status}
              id={item.id}
              username={item.username}
              friend={item}
            />
          ))}

          {sentRequests.length ? <RequestsHeader title="Sent requests" /> : null}
          {sentRequests.map((item: IUser) => (
            <SentRequestsItem
              key={item.id}
              status={item.status}
              id={item.id}
              username={item.username}
              friend={item}
            />
          ))}
        </>
      ) : (
        <EmptyPageContainer title="You don’t have any friend requests">
          <EmptyRequestsIconComponent />
        </EmptyPageContainer>
      )}
    </>
  );
};
