import React, { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { CustomButton, FormInput } from '../common/components';
import { userService } from '../common/services/user.service';
import { ChangePasswordPageContainer } from './change-password-container.component';
import { APP_KEYS, ERROR_MSG } from '../common/consts';
import { toastSuccess } from '../common/components/toast/toast.component';
import { changePasswordValidationSchema } from '../auth/utils/auth.util';

interface IProps {}

interface IFormikData {
  newPassword: string;
  oldPassword: string;
}

const initialValues = {
  newPassword: '',
  oldPassword: '',
  confirmNewPassword: ''
};

const getErrorArray = (obj: any): String[] => {
  const arr: String[] = [];
  obj.forEach((value: String) => {
    if (!arr.includes(value)) {
      arr.push(value);
    }
  });

  return arr;
};

export const ChangePassword: FC<IProps> = () => {
  const { push } = useHistory();
  const [oldPasswordErrorMsg, setOldPasswordErrorMsg] = useState<string>('');
  const [newPasswordErrorMsg, setNewPasswordErrorMsg] = useState<string>('');
  const [confirmNewPasswordErrorMsg, setConfirmNewPasswordErrorMsg] = useState<string>('');
  const [customErrorMsg, setCustomErrorMsg] = useState<string>('');
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');
  const [submitValues, setSubmitValues] = useState({ oldPassword: '', newPassword: '' });

  const { mutate: changePasswordMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ newPassword, oldPassword }) => userService.changePassword({ newPassword, oldPassword }),
    {
      onSuccess: (res) => {
        toastSuccess(res.message);
        push(APP_KEYS.ROUTER_KEYS.HOME);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ oldPassword, newPassword, confirmNewPassword }) => {
      if (newPassword !== confirmNewPassword) {
        setCustomErrorMsg(ERROR_MSG.PASS_NOT_MATCH);
      } else {
        setSubmitValues({ oldPassword, newPassword });
        changePasswordMutation({ newPassword, oldPassword });
      }
    },
    validationSchema: changePasswordValidationSchema()
  });

  const { oldPassword, newPassword, confirmNewPassword } = formik.values;

  if (
    serverErrorMsg &&
    ((submitValues.oldPassword && oldPassword !== submitValues.oldPassword) ||
      (submitValues.newPassword &&
        (newPassword !== submitValues.newPassword ||
          confirmNewPassword !== submitValues.newPassword)))
  ) {
    setServerErrorMsg('');
  }

  if (
    !newPasswordErrorMsg &&
    customErrorMsg === ERROR_MSG.PASS_NOT_MATCH &&
    newPassword === confirmNewPassword
  ) {
    setCustomErrorMsg('');
  }

  const isValidButton =
    !Object.keys(formik.errors).length &&
    !!oldPassword &&
    !!newPassword &&
    !!confirmNewPassword &&
    !oldPasswordErrorMsg &&
    !newPasswordErrorMsg &&
    !confirmNewPasswordErrorMsg &&
    !customErrorMsg &&
    !serverErrorMsg;

  const errorMsg =
    getErrorArray(Object.values(formik.errors)).length > 0 ? (
      <>
        {getErrorArray(Object.values(formik.errors)).map((item) => (
          <p>{item}</p>
        ))}
        <p>{serverErrorMsg}</p>
      </>
    ) : undefined;

  return (
    <ChangePasswordPageContainer title="Change Password" errorMsg={errorMsg}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" mt="0.5rem">
            <Grid item mt="1rem">
              <FormInput
                name="oldPassword"
                type="password"
                passedValue=""
                placeholder="Enter your Old Password"
                label="Old Password"
                setErrorMsg={setOldPasswordErrorMsg}
                customError={!!serverErrorMsg}
              />
            </Grid>
            <Grid item mt="1rem">
              <FormInput
                name="newPassword"
                type="password"
                passedValue=""
                placeholder="Enter your New Password"
                label="New Password"
                setErrorMsg={setNewPasswordErrorMsg}
              />
            </Grid>
            <Grid item mt="1rem">
              <FormInput
                name="confirmNewPassword"
                type="password"
                passedValue=""
                placeholder="Enter your New Password"
                label="Confirm New Password"
                setErrorMsg={setConfirmNewPasswordErrorMsg}
                customError={!!customErrorMsg}
              />
            </Grid>
            <Grid item mt="1rem">
              <CustomButton variant="contained" fullWidth type="submit" disabled={!isValidButton}>
                Change Password
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </ChangePasswordPageContainer>
  );
};
