import React, { FC } from 'react';
import { PageWrapper } from '../common/components';
import { EHomeTabs } from '../common/types/components.types';
import { HomeWrapper } from './home-wrapper';

interface IProps {
  activeTab: EHomeTabs;
  handleActiveTab: (tab: EHomeTabs) => (e: any) => void;
  children?: React.ReactNode;
}

export const HomePageContainer: FC<IProps> = ({ activeTab, handleActiveTab, children }) => (
  <PageWrapper withHeader justifyContent="center" alignItems="center">
    <HomeWrapper activeTab={activeTab} handleActiveTab={handleActiveTab}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </HomeWrapper>
  </PageWrapper>
);
