import styled from 'styled-components';
import { COLORS } from '../theme';

export const UploadFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 58px;

  border: 1px ${COLORS.offline} dashed;
  border-radius: 10px;
  color: ${COLORS.offline};

  cursor: pointer;
`;

export const HiddenInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;
