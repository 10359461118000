export const getCodeFromUrl = (search: string) => {
  const params = search.startsWith('?') ? search.slice(1) : search;
  const paramsHash = params.split('&&').reduce((acc, el) => {
    const paramData = el.split('=');
    return {
      ...acc,
      [paramData[0]]: paramData[1]
    };
  }, {});

  // @ts-ignore
  return paramsHash.code;
};
