import { HttpFactoryService } from './http-factory.service';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { ReactQueryFactory } from '../utils';
import { IRemoveFriendRequest } from './types';
import { IHttpConfig } from '../types';

export class FriendRequestService extends ReactQueryFactory<{}> {
  constructor(private httpService: EnhancedWithAuthHttpService) {
    super('request');
  }

  async onDetailedRequest(): Promise<any | void | undefined> {
    throw new Error('onDetailedRequest not implemented!');
  }

  async onAllRequest() {
    const data = await this.httpService.get<any>('user-friend/friend-requests');
    return data;
  }

  async onListRequest() {
    throw new Error('onListRequest not implemented!');
  }

  async removeFriendRequest({ id }: IRemoveFriendRequest): Promise<any | void> {
    return this.httpService.delete('user-friend/friend-request', { data: { id } } as IHttpConfig);
  }
}

const factory = new HttpFactoryService();
export const friendRequestService = new FriendRequestService(factory.createAuthHttpService());
