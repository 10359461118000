import { IUser } from '../../../services/types';
import defaultAvatar from '../../../../../assets/image/user-icon-v1.svg';

export const getUserAvatar = (userData: void | IUser | null | undefined) => {
  if (userData?.avatarUploaded) {
    return `${process.env.REACT_APP_AWS_S3_AVATAR_BASE_URL}${userData?.avatarUploaded}`;
  }

  if (userData?.avatarDefaultUploaded) {
    return `${process.env.REACT_APP_AWS_S3_DEFAULT_AVATAR_BASE_URL}${userData?.avatarDefaultUploaded}`;
  }

  return defaultAvatar;
};
