import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { toastError, toastSuccess } from '../../common/components/toast/toast.component';
import { uploadAudioService } from '../../common/services/upload-audio.service';
import { ISetUpFriendVoiceCommandPayload } from '../../common/services/types';
import { IErrorResponse } from '../../common/types';

export const useSetupFriendVoiceCommand = () => {
  const setupFrindVoiceCommand = async (payload: ISetUpFriendVoiceCommandPayload) =>
    uploadAudioService.setupFriendVoiceCommand(payload);

  const onSuccess = () => {
    toastSuccess('Voice command successfully added!');
  };

  const onError = (error: AxiosError<IErrorResponse>) => {
    toastError(error?.response?.data?.message ?? error?.message);
  };

  return useMutation<any, AxiosError<IErrorResponse>, ISetUpFriendVoiceCommandPayload>({
    mutationFn: setupFrindVoiceCommand,
    onSuccess,
    onError
  });
};
