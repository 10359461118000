import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import logo from '../../../../assets/image/near-logo.svg';

import { APP_KEYS } from '../../consts';
import { useDecoratedMutations } from '../../hooks';
import { conversationService } from '../../services';
import { customErrorHandler } from '../../utils/error.util';

interface IProps {
  isInAuth?: boolean;
}

export const NearLogo: FC<IProps> = ({ isInAuth = false }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { mutateAsync: deleteRoomAsync } = useDecoratedMutations<any, AxiosError, void>(
    () => conversationService.deleteRoom(),
    {
      onError: customErrorHandler
    }
  );

  const handleBackHome = async () => {
    if (pathname.includes('conversation')) {
      await deleteRoomAsync();
    }

    push(APP_KEYS.ROUTER_KEYS.HOME);
  };

  return (
    <Grid
      container
      style={{ cursor: 'pointer' }}
      alignItems="center"
      width="auto"
      onClick={handleBackHome}
    >
      <img src={logo} alt="logo" style={{ height: isInAuth ? '36px' : 'auto' }} />
    </Grid>
  );
};
