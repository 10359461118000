import React from 'react';
import * as GlobalTypography from '../common/components/global-typography';
import { ChooseAvatarContainer, UploadWrapper } from './choose-avatar.styled';
import { UploadFile, UploadFileProps } from './upload-file.component';
import { DefaultAvatarsList } from './default-avatars-list.component';

export const ChooseAvatar = ({ image, setImage }: UploadFileProps) => (
  <ChooseAvatarContainer>
    <GlobalTypography.Text
      variant="body1"
      colorVariant="common.black"
      fontWeight="fontWeightMedium"
      pb="0.125rem"
    >
      Choose Avatar
    </GlobalTypography.Text>
    <UploadWrapper>
      <UploadFile image={image} setImage={setImage} />
      <DefaultAvatarsList />
    </UploadWrapper>
  </ChooseAvatarContainer>
);
