import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { COLORS, MergedTheme } from '../theme';
import { EStatusTypes } from '../common/types/components.types';
import { getStatusColor } from '../common/utils/components.util';
import { SIZES, WEIGHTS } from '../theme/fonts.const';

export const ConversationContainer = styled(Grid)<{ theme?: MergedTheme }>`
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing(6.25)};
  padding-top: ${({ theme }) => theme.spacing(18.25)};
`;

export const ConversationItemContainer = styled(Grid)<{ theme?: MergedTheme }>`
  width: 48%;
  height: 553px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 1px 5px 10px ${({ theme }) => theme.colors.mostLightBlue};
  border-radius: 20px;
  min-height: 100%;
`;

export const UserIconContainer = styled.div<{ theme?: MergedTheme; status?: EStatusTypes }>`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 6px solid ${({ status }) => getStatusColor(status)};
  overflow: hidden;
`;

export const UserImage = styled.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EndCallButton = styled.button`
  width: 100%;
  height: 84px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.sunsetOrange};
  border-radius: 12px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.venetianRed};
  }
`;

export const ButtonTextWrapper = styled.div`
  width: 176px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 14px;
`;

export const ButtonEndCallText = styled.span`
  font-size: ${SIZES.xxl};
  font-wight: ${WEIGHTS.bold};
  line-height: 26px;
  color: ${COLORS.white};
`;

export const ButtonByeByeText = styled.span`
  font-size: ${SIZES.xxl};
  line-height: 26px;
  color: ${COLORS.white};
`;
