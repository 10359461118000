import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './settings-popover.styled';
import { SettingsPopoverItem } from './settings-popover-item.component';
import { ESettingsOptions } from '../../../types/components.types';
import { APP_KEYS } from '../../../consts';
import { localStorageService } from '../../../services/local-storage.service';
import { queryClient } from '../../../../app';
import { userService } from '../../../services/user.service';
import { useSocket } from '../../../utils/socket.util';

interface IProps {
  isUsername?: boolean;
}

export const SettingsPopover: FC<IProps> = ({ isUsername }) => {
  const { push } = useHistory();

  const handleSettings = () => {
    push(APP_KEYS.ROUTER_KEYS.SETTINGS);
  };

  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const user = data || {};
  const userId = user.id;
  const { socket, handleClearSocket } = useSocket(userId);

  const handleLogout = () => {
    socket?.disconnect();
    handleClearSocket();

    localStorageService.removeTokenFromStorage();
    queryClient.clear();
    push(APP_KEYS.ROUTER_KEYS.LOGIN);
  };

  return (
    <Styled.SettingsPopoverContainer isUsername={isUsername}>
      <Styled.TopSection />
      <SettingsPopoverItem label={ESettingsOptions.SETTINGS} onClick={handleSettings} />
      <SettingsPopoverItem label={ESettingsOptions.LOGOUT} onClick={handleLogout} />
    </Styled.SettingsPopoverContainer>
  );
};
