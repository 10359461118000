import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { conversationService } from '../services';
import { useDecoratedMutations } from './react-query.hooks';

export const useCreateAudioRoom = () => {
  const { push } = useHistory();

  const createAudioRoom = async (usernamePrompt: string) =>
    conversationService.createRoom({ usernamePrompt });

  const onError = (err: any, usernamePrompt: string) => {
    const error = err!.response!.data!.message;
    const username = usernamePrompt.toLowerCase();

    if (error === 'User busy') push(`/?notification&&error=0&&name=${username}`);
    if (error === 'User offline') push(`/?notification&&error=2&&name=${username}`);
    if (error === 'We are not able to find a user via these keywords. Please try again.') {
      push('/?notification&&error=1');
    }
  };

  return useDecoratedMutations<any, AxiosError, string>(createAudioRoom, {
    onError
  });
};
