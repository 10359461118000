import React, { FC } from 'react';
import { ResetPageContainer } from './reset-container.component';
import { NewPasswordForm } from './new-password-form.component';

interface IProps {}

export const ResetPasswordPage: FC<IProps> = () => (
  <ResetPageContainer>
    <NewPasswordForm />
  </ResetPageContainer>
);
