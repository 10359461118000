import { FC, useEffect, useRef, useState } from 'react';
import { MultiInputContainer, MultiInputItem } from './multi-input.styled';

interface IProps {
  length?: number;
  codeHandler?: (code: number) => void;
}

let refs = {};
export const MultiInput: FC<IProps> = ({ length = 4, codeHandler }) => {
  const renderLength = [...Array(length)].map((el, idx) => `multi-input-${idx + 1}`);
  const initState = renderLength.reduce((acc, el) => ({ ...acc, [el]: '' }), {});
  const [values, setValues] = useState<{ [key: string]: string }>(initState);

  renderLength.forEach((el) => {
    refs = {
      ...refs,
      [el]: useRef(null)
    };
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const nextValue = value.replace(/[^0-9]/gi, '');
    const currentIdx = renderLength.indexOf(name);
    const nextCell = renderLength[currentIdx + 1];
    if (nextCell && nextValue) {
      // @ts-ignore
      refs[nextCell].current.focus();
    }

    setValues((prev) => ({
      ...prev,
      [name]: nextValue
    }));
  };

  const handleKeyDown = (e: any) => {
    const {
      code,
      target: { value, name, selectionEnd }
    } = e;
    const currentIdx = renderLength.indexOf(name);
    const prevCell = renderLength[currentIdx - 1];
    const nextCell = renderLength[currentIdx + 1];
    if (code === 'ArrowLeft' && prevCell) {
      // @ts-ignore
      refs[prevCell].current.focus();
    }
    if (code === 'ArrowRight' && nextCell && (selectionEnd === 1 || !value)) {
      // @ts-ignore
      refs[nextCell].current.focus();
    }
    if (code === 'Backspace' && prevCell && !value) {
      // @ts-ignore
      refs[prevCell].current.focus();
    }
  };

  const codeValue = Object.values(values).reduce((acc, value) => acc + value, '');

  useEffect(() => {
    if (codeValue.length === length && codeHandler) {
      codeHandler(Number(codeValue));
    }
  }, [codeValue]);

  return (
    <MultiInputContainer>
      {renderLength.map((item, idx) => (
        <MultiInputItem
          key={item}
          autoFocus={idx === 0}
          name={item}
          maxLength={1}
          // @ts-ignore
          ref={refs[item]}
          value={values[item]}
          onChange={handleChange}
          onKeyDownCapture={handleKeyDown}
        />
      ))}
    </MultiInputContainer>
  );
};
