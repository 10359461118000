import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, InputAdornment } from '@mui/material';
import * as Styled from './autocomplete-input.styled';
import { SearchIcon } from '../icons';
import { AutocompleteItem } from '../autocomplete-item';
import { RecordVoiceModal } from '../record-voice-modal';
import { IUser } from '../../services/types';

export const AutocompleteInput: FC<any> = ({
  passedOptions = [],
  onSetSearchValue,
  searchValue,
  name,
  placeholder,
  disabled,
  isErrorExists,
  handleClearSearch,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [user, setUser] = useState<IUser | undefined | null>(null);

  const handleChange = (e: SyntheticEvent, value: any) => {
    if (value.label) {
      setInputValue(value.label);
    }
  };

  const handleInputChange = (e: SyntheticEvent, value: any) => {
    setInputValue(value);
  };

  const handleKeyDown = (event: any) => {
    if (event.code === 'Enter') {
      onSetSearchValue(inputValue.replace(/[^A-Za-z]/gi, ''));
      setInputValue(inputValue.replace(/[^A-Za-z]/gi, ''));
    }
  };

  useEffect(() => {
    setOpen(true);
  }, [passedOptions]);

  const renderOptions = searchValue === inputValue ? passedOptions : [];
  const isOptions = open && !!renderOptions.length;

  return (
    <>
      <Styled.InputContainer
        disabled={disabled}
        isErrorExists={isErrorExists}
        isOptions={isOptions}
        onKeyDown={handleKeyDown}
      >
        <Autocomplete
          {...props}
          value={inputValue}
          freeSolo
          fullWidth
          open={isOptions}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleChange}
          onInputChange={handleInputChange}
          id="autocomplete-input"
          options={renderOptions}
          disableClearable
          disablePortal
          sx={{
            '& .MuiAutocomplete-paper': {
              borderBottom: isOptions ? '1px solid #6BB3F2' : 'none'
            }
          }}
          renderOption={(renderProps, option: IUser) => {
            setUser(option);

            return (
              <Styled.ListItem {...renderProps}>
                <AutocompleteItem
                  key={option.id}
                  username={option.username}
                  id={option.id}
                  status={option.status}
                  description={option.description}
                  setOpen={setOpen}
                  friend={option}
                />
              </Styled.ListItem>
            );
          }}
          renderInput={(params) => (
            <Styled.Input
              {...params}
              fullWidth
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    style={{
                      paddingLeft: isOptions ? '11px' : '0'
                    }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              sx={{
                '& fieldset': { border: 'none' }
              }}
              onBlur={() => {
                handleClearSearch();
              }}
            />
          )}
        />
      </Styled.InputContainer>
      <RecordVoiceModal tipType="”Hey {name}”" user={user} isHeyFriendRecording />
    </>
  );
};
