import styled from 'styled-components';
import { COLORS } from '../theme';

export const DefaultAvatarsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 343px;
  column-gap: 2px;
  row-gap: 2px;
  padding: 2px;
  background-color: ${COLORS.offline};
`;
