import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';

export const CustomLink = styled(Link)<{ theme?: Theme }>`
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  letter-spacing: 0.02em;
`;

export const LinkTemplate = styled(Link)`
  text-decoration: none;
`;

export const ForgotLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
