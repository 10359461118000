/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useState } from 'react';
import { ModalContext } from '../../../context/modal.context';
import { Modal } from '../modal';
import {
  CloseButton,
  MirophoneIcon,
  ModalHeader,
  RecordVoiceModalContainer,
  Tip,
  TipType,
  Title
} from './record-voice-modal.styled';
import crossIcon from '../../../../assets/icons/light-azure-cross.svg';
import voiceRecorderIcon from '../../../../assets/icons/voice-recorder.svg';
import { CustomButton } from '../button';
import { PopOver } from '../popover';
import { useHover, useRecorderPermission } from '../../hooks';
import { IUser } from '../../services/types';
import { useFriendShipRequest } from '../../../home/hooks';
import { useSetupFriendVoiceCommand } from '../../../home/hooks/use-setup-friend-voice-command';
import { uploadAudioService } from '../../services/upload-audio.service';
import { RecordSuccessfull } from '../record-successfull';

interface IRecordVoiceModalProps {
  tipType: string;
  isHeyFriendRecording?: boolean;
  user?: IUser | null;
}

export const RecordVoiceModal = ({
  tipType,
  isHeyFriendRecording = false,
  user
}: IRecordVoiceModalProps) => {
  const [audioLink, setAudioLink] = React.useState<string>('');
  const [isSuccessSetup, setIsSuccess] = useState(false);

  const [isShowSaveBtn, seIsShowSaveBtn] = useState(false);

  const { isModalOpen, setIsModalOpen, friendId } = useContext(ModalContext);

  const recorder = useRecorderPermission('audio');

  const { isHover, seIsHover, onMouseOver, onMouseOut } = useHover();

  const { mutateAsync: createFriend } = useFriendShipRequest();

  const { mutateAsync: setupFriendVoiceCommand } = useSetupFriendVoiceCommand();

  const onCloseModal = () => {
    setIsModalOpen(false);
    seIsHover(false);
    setIsSuccess(false);
    seIsShowSaveBtn(false);
    setAudioLink('');
  };

  const startRecording = async () => {
    recorder.startRecording();
  };

  const onStart = () => {
    startRecording();
    seIsShowSaveBtn(true);
  };

  const onSave = async () => {
    try {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();

      await setupFriendVoiceCommand({
        friendId: user?.id ?? friendId,
        audioUploaded: blob
      });

      if (user) {
        await createFriend(user?.id);
      }

      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
  };

  const getAudioLink = async () => {
    const audioData = await uploadAudioService.getFriendVoiceCommand({ friendId });
    setAudioLink(
      `${process.env.REACT_APP_AWS_S3_DEFAULT_FIEND_VOICE_COMMAND_BASE_URL}/${audioData?.friendVoiceCommand}`
    );
  };

  return isModalOpen ? (
    <Modal onCloseModal={onCloseModal}>
      <RecordVoiceModalContainer>
        {isSuccessSetup ? (
          <RecordSuccessfull onClick={onCloseModal} />
        ) : (
          <>
            <ModalHeader>
              <Title>Record voice command</Title>

              {isHover && isHeyFriendRecording && (
                <PopOver positionBottom="40px" positionRight="-132px" />
              )}

              <CloseButton onClick={onCloseModal} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
                <img src={crossIcon} alt="modal_close_icon" width="14" height="14" />
              </CloseButton>
            </ModalHeader>

            <MirophoneIcon
              src={voiceRecorderIcon}
              alt="voice_recorder_icon"
              width="100"
              height="100"
            />

            <Tip>
              Click &#39;Start &#39; and say <TipType>{tipType}</TipType> to begin recording
            </Tip>

            {isShowSaveBtn ? (
              <CustomButton variant="contained" width="182px" type="submit" onClick={onSave}>
                Save
              </CustomButton>
            ) : (
              <CustomButton variant="contained" width="182px" type="submit" onClick={onStart}>
                Start
              </CustomButton>
            )}

            <br />

            <CustomButton variant="contained" width="182px" type="submit" onClick={getAudioLink}>
              Get audio
            </CustomButton>

            <br />

            {audioLink ? (
              <div>
                <audio controls>
                  <source src={audioLink} />
                </audio>
              </div>
            ) : null}
          </>
        )}
      </RecordVoiceModalContainer>
    </Modal>
  ) : null;
};
