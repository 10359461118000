import { FC } from 'react';
import { SettingsPage } from './settings.component';
import { userService } from '../common/services/user.service';
import { localStorageService } from '../common/services/local-storage.service';

interface IProps {}

export const SettingsContainer: FC<IProps> = () => {
  const token = localStorageService.getTokenFromStorage();
  const { data, isLoading, isFetching, refetch } = userService.useAllQuery<any>(token as string);

  if (isLoading || isFetching) return null;

  const { username, email, phone, description, isNewAccount, password, isWithGoogle } = data || {};
  const initialValues = {
    email: email || '',
    phone: phone || '',
    description: description || '',
    username: username || ''
  };

  return (
    <SettingsPage
      isShowPasswordSection={!isWithGoogle}
      initialValues={initialValues}
      isNewAccount={isNewAccount}
      password={password}
      refetch={refetch}
    />
  );
};
