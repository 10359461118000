import { Field, getIn, useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { IconContainer, Styled } from './form-textarea.styled';
import * as GlobalTypography from '../global-typography';
import { UrgentIcon } from '../icons';

interface IFormikValue {
  description: string;
}

export interface IProps {
  name: string;
  type: string;
  placeholder?: string;
  disabled?: boolean | undefined;
  passedValue: string;
  maxLength?: number;
  label: string;
  subText?: string;
}

const ALLOW_ROWS_COUNT = 3;

export const FormTextarea: React.FC<IProps> = ({
  name,
  type,
  placeholder,
  disabled,
  passedValue,
  maxLength = 50,
  label,
  subText
}) => {
  const { values, handleChange, errors, touched } = useFormikContext<IFormikValue>();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);
  const { description } = values;

  const [height, setHeight] = useState(56);
  const ref = useRef(null);

  const getRowsCount = (text: string) => text.split('\n').length;

  const rowCounts = getRowsCount(description);
  const length = rowCounts > ALLOW_ROWS_COUNT ? description.length : maxLength;

  if (ref.current) {
    const element = ref.current as HTMLElement;
    const { scrollHeight } = element;
    if (scrollHeight !== height) {
      setHeight(scrollHeight);
    }
  }

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        {label && (
          <GlobalTypography.Text
            variant="body1"
            colorVariant="common.black"
            fontWeight="fontWeightMedium"
            pb="0.125rem"
          >
            {label}
            {subText && <Styled.SubText>{subText}</Styled.SubText>}
          </GlobalTypography.Text>
        )}
      </Grid>

      <Styled.TextareaContainer disabled={disabled} isErrorExists={isErrorExists} height={height}>
        <Field
          // @ts-ignore
          render={({ field }) => (
            <Styled.Textarea {...field} placeholder={placeholder} ref={ref} maxLength={length} />
          )}
          id={name}
          name={name}
          type={type}
          onChange={handleChange(name)}
          value={getIn(values, name) || passedValue}
          maxLength={maxLength}
          disabled={disabled}
        />
        {height > 100 && (
          <IconContainer>
            <UrgentIcon />
          </IconContainer>
        )}
      </Styled.TextareaContainer>
    </Grid>
  );
};
