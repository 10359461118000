import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { useHistory } from 'react-router-dom';
import { AuthWrapper, Text, CustomButton } from '../common/components';

export const PermissionNotificationContainer: FC = () => {
  const { push } = useHistory();
  const handleReload = () => {
    push('/');
    window.location.reload();
  };

  return (
    <AuthWrapper withLogo={false} variant="h5">
      <Grid item container justifyContent="center" mt="1rem">
        <Text colorVariant="common.black" letterSpacing="0.02em">
          To apply your updated settings, reload this page
        </Text>
      </Grid>
      <Grid item container justifyContent="center" mt="1rem">
        <CustomButton variant="contained" onClick={handleReload}>
          Reload page
        </CustomButton>
      </Grid>
    </AuthWrapper>
  );
};
