import * as Yup from 'yup';
import {
  getLoginPasswordValidator,
  getPasswordConfirmValidator,
  getPasswordValidator,
  getResetPasswordConfirmValidator
} from './password-validator.util';
import { ESignTypes } from '../types';
import { getloginValidator } from './login-validator.util';
import { getPhoneValidator } from './phone-validator.util';
import { getEmailValidator } from './email-validator.util';
import { getUsernameValidator } from './username-validator.util';

export const signValidationSchema = (type: ESignTypes) =>
  Yup.object().shape({
    login: getloginValidator(),
    password: type === ESignTypes.SIGN_UP ? getPasswordValidator() : getLoginPasswordValidator()
  });

export const resetValidationSchema = () =>
  Yup.object().shape({
    login: getloginValidator()
  });

export const newPasswordValidationSchema = () =>
  Yup.object().shape({
    newPassword1: getPasswordValidator(),
    newPassword2: getResetPasswordConfirmValidator()
  });

export const changePasswordValidationSchema = () =>
  Yup.object().shape({
    oldPassword: getPasswordValidator(),
    newPassword: getPasswordValidator(),
    confirmNewPassword: getPasswordConfirmValidator()
  });

export const setPasswordValidationSchema = () =>
  Yup.object().shape({
    newPassword: getPasswordValidator(),
    confirmNewPassword: getPasswordValidator()
  });

export const settingsValidationSchema = () =>
  Yup.object().shape({
    username: getUsernameValidator(),
    email: getEmailValidator(),
    phone: getPhoneValidator()
  });

export const checkEmail = (email: string) => /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
