import avatar0 from '../../../assets/icons/default-avatar-0.svg';
import avatar1 from '../../../assets/icons/default-avatar-1.svg';
import avatar2 from '../../../assets/icons/default-avatar-2.svg';
import avatar3 from '../../../assets/icons/default-avatar-3.svg';
import avatar4 from '../../../assets/icons/default-avatar-4.svg';
import avatar5 from '../../../assets/icons/default-avatar-5.svg';
import avatar6 from '../../../assets/icons/default-avatar-6.svg';
import avatar7 from '../../../assets/icons/default-avatar-7.svg';
import avatar8 from '../../../assets/icons/default-avatar-8.svg';
import avatar9 from '../../../assets/icons/default-avatar-9.svg';
import avatar10 from '../../../assets/icons/default-avatar-10.svg';
import avatar11 from '../../../assets/icons/default-avatar-11.svg';
import avatar12 from '../../../assets/icons/default-avatar-12.svg';
import avatar13 from '../../../assets/icons/default-avatar-13.svg';
import avatar14 from '../../../assets/icons/default-avatar-14.svg';
import avatar15 from '../../../assets/icons/default-avatar-15.svg';
import avatar16 from '../../../assets/icons/default-avatar-16.svg';
import avatar17 from '../../../assets/icons/default-avatar-17.svg';
import avatar18 from '../../../assets/icons/default-avatar-18.svg';
import avatar19 from '../../../assets/icons/default-avatar-19.svg';
import avatar20 from '../../../assets/icons/default-avatar-20.svg';
import avatar21 from '../../../assets/icons/default-avatar-21.svg';
import { AvatarMapType } from '../types.ts';

export const VISIBLE_DEFAULT_AVATARS_MAP: AvatarMapType[] = [
  {
    id: 'default-avatar-0.svg',
    src: avatar0
  },
  {
    id: 'default-avatar-1.svg',
    src: avatar1
  },
  {
    id: 'default-avatar-2.svg',
    src: avatar2
  },
  {
    id: 'default-avatar-3.svg',
    src: avatar3
  },
  {
    id: 'default-avatar-4.svg',
    src: avatar4
  },
  {
    id: 'default-avatar-5.svg',
    src: avatar5
  },
  {
    id: 'default-avatar-6.svg',
    src: avatar6
  },
  {
    id: 'default-avatar-7.svg',
    src: avatar7
  },
  {
    id: 'default-avatar-8.svg',
    src: avatar8
  },
  {
    id: 'default-avatar-9.svg',
    src: avatar9
  },
  {
    id: 'default-avatar-10.svg',
    src: avatar10
  },
  {
    id: 'default-avatar-11.svg',
    src: avatar11
  },
  {
    id: 'default-avatar-12.svg',
    src: avatar12
  },
  {
    id: 'default-avatar-13.svg',
    src: avatar13
  },
  {
    id: 'default-avatar-14.svg',
    src: avatar14
  },
  {
    id: 'default-avatar-15.svg',
    src: avatar15
  },
  {
    id: 'default-avatar-16.svg',
    src: avatar16
  },
  {
    id: 'default-avatar-17.svg',
    src: avatar17
  },
  {
    id: 'default-avatar-18.svg',
    src: avatar18
  },
  {
    id: 'default-avatar-19.svg',
    src: avatar19
  },
  {
    id: 'default-avatar-20.svg',
    src: avatar20
  },
  {
    id: 'default-avatar-21.svg',
    src: avatar21
  }
];
