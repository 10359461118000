import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Container } from '@mui/material';
import { privateRoutes, publicRoutes } from './navigation.util';
import { usePublicRoutes } from './usePublicRoutes.util';
import { usePrivateRoutes } from './usePrivateRoutes.util';

export const MainRouter = () => (
  <Container disableGutters maxWidth={false}>
    <Router>
      <Switch>
        {publicRoutes.map(({ component, path }) => (
          <Route component={usePublicRoutes(component)} key={path} path={path} />
        ))}
        {privateRoutes.map(({ component, path }) => (
          <Route component={usePrivateRoutes(component)} key={path} path={path} />
        ))}
      </Switch>
    </Router>
  </Container>
);
