import React, {
  createContext,
  ReactChild,
  ReactChildren,
  useContext,
  useMemo,
  useState
} from 'react';
import { ESignMethods } from '../auth/types';

interface IContextValue {
  phone: string;
  email: string;
  username: string;
  signMethod: ESignMethods | null;
  onSetPhone: React.Dispatch<React.SetStateAction<string>>;
  onSetEmail: React.Dispatch<React.SetStateAction<string>>;
  onSetUsername: React.Dispatch<React.SetStateAction<string>>;
  onSetSignMethod: React.Dispatch<React.SetStateAction<ESignMethods | null>>;
}

const AuthContext = createContext<IContextValue>({} as IContextValue);

interface IThemeContext {
  children: ReactChild | ReactChildren;
}

export const AuthContextProvider: React.FC<IThemeContext> = ({ children }) => {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [signMethod, setSignMethod] = useState<ESignMethods | null>(null);

  const value = useMemo(
    () => ({
      phone,
      email,
      signMethod,
      onSetPhone: setPhone,
      onSetEmail: setEmail,
      onSetSignMethod: setSignMethod,
      onSetUsername: setUsername,
      username
    }),
    [phone, username, email, signMethod, setPhone, setEmail, setSignMethod]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthData = () => useContext(AuthContext);
