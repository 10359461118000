import React, { SyntheticEvent } from 'react';
import { createPortal } from 'react-dom';
import { ModalOverlay, StyledModal } from './modal.styled';

const modalRoot = document.querySelector('#modal-root')!;

export interface IModalProps {
  children: JSX.Element | JSX.Element[];
  onCloseModal: () => void;
}

export const Modal = ({ children, onCloseModal }: IModalProps) => {
  const handleOnBackdropClick = (e: SyntheticEvent<EventTarget>) => {
    if (e.currentTarget === e.target) {
      onCloseModal();
    }
  };

  return createPortal(
    <ModalOverlay onClick={handleOnBackdropClick}>
      <StyledModal>{children}</StyledModal>
    </ModalOverlay>,
    modalRoot
  );
};
