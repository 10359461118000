import { useState, useEffect } from 'react';
import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc';

const mimeType = 'audio/webm';

export const useRecorderPermission = (recordingType: RecordRTC.Options['type']) => {
  const [recorder, setRecorder] = useState<any>();

  const getPermissionInitializeRecorder = async () => {
    const stream = await (navigator as any).mediaDevices.getUserMedia({
      video: false,
      audio: true
    });

    const recorderInstance = new RecordRTCPromisesHandler(stream, {
      type: recordingType,
      mimeType
    });

    setRecorder(recorderInstance);
  };

  useEffect(() => {
    getPermissionInitializeRecorder();
  }, []);

  return recorder;
};
