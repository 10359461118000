import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { userService } from '../../common/services/user.service';
import { IErrorResponse } from '../../common/types';
import { IUpdateAvatarResponse, IUser } from '../../common/services/types';
import { QUERY_KEYS } from '../../common/consts/app-keys.const';
import { toastError, toastSuccess } from '../../common/components/toast/toast.component';

export const useUploadAvatar = () => {
  const queryClient = useQueryClient();

  const uploadAvatar = (body: FormData) => userService.uploadAvatar(body);

  const onUpdateAvatarSuccess = (response: IUpdateAvatarResponse) => {
    toastSuccess(response?.message || 'Success!');

    queryClient.setQueryData<IUser[] | undefined>([QUERY_KEYS.USER], (prevUserState) => {
      if (prevUserState) {
        return {
          ...prevUserState,
          avatarUploaded: response?.avatarUploaded,
          avatarDefaultUploaded: null
        };
      }
    });
  };

  const onUpdateAvatarError = (error: AxiosError<IErrorResponse>) => {
    toastError(error?.response?.data?.message ?? error?.message);
  };

  return useMutation<IUpdateAvatarResponse, AxiosError<IErrorResponse>, FormData>({
    // @ts-ignore
    mutationFn: uploadAvatar,
    onSuccess: onUpdateAvatarSuccess,
    onError: onUpdateAvatarError
  });
};
