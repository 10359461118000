import styled from 'styled-components';
import { COLORS } from '../../../theme';
import { SIZES } from '../../../theme/fonts.const';

export const PopOverContainer = styled.div`
  position: relative;
`;

export const PopOverTextWrapper = styled.div<{ positionBottom: string; positionRight: string }>`
  position: absolute;

  bottom: ${({ positionBottom }) => positionBottom};
  right: ${({ positionRight }) => positionRight};

  width: 190px;

  padding: 14px 6px;

  border-radius: 12px;
  background-color: ${COLORS.azure};
`;

export const Pointer = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${COLORS.azure};
  bottom: -5px;
  left: 95px;
  transform: rotate(45deg);
`;

export const PopoverText = styled.p`
  font-size: ${SIZES.m};
  line-height: 18px;
  text-align: center;
  color: ${COLORS.white};
`;
