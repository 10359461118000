import styled from '@emotion/styled';
import { TextField, Theme } from '@mui/material';
import { MergedTheme } from '../../../theme';

export const InputContainer = styled.div<{
  disabled: boolean | undefined;
  theme?: MergedTheme;
  isErrorExists?: boolean;
  isOptions?: boolean;
}>`
  display: flex;
  position: relative;
  background-color: ${({ disabled, theme }) =>
    (disabled ? theme.palette.secondary.main : theme.colors.semiTransparentGrey)};
  border: 1px solid
    ${({ theme, isErrorExists }) =>
      (isErrorExists ? theme.palette.error.dark : theme.colors.semiTransparentGrey)};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: ${({ isOptions }) => (isOptions ? '0px' : '10px')};
  border-bottom-left-radius: ${({ isOptions }) => (isOptions ? '0px' : '10px')};
  min-width: 100%;
  box-sizing: border-box;

  &:focus-within {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    border: 1px solid
      ${({ theme, isErrorExists }) =>
        (isErrorExists ? theme.palette.error.dark : theme.palette.primary.dark)};
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const Input = styled(TextField)<{ theme?: Theme }>`
  & .MuiInputBase-root {
    background-color: transparent;
    border: none;
    height: 56px;
    outline: none;
    padding: 0px ${({ theme }) => theme.spacing(2.5)} 0px ${({ theme }) => theme.spacing(2.5)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  &:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
  &:-webkit-autofill:hover {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
  &:-webkit-autofill:focus {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
`;

export const ListItem = styled.li<{ theme?: MergedTheme }>`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightTransparentBlue};
  }
`;
