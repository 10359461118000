import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import {
  AuthWrapper,
  CustomLink,
  PageWrapper,
  Text,
  TextButtonTemplate
} from '../common/components';
import { APP_KEYS } from '../common/consts';
import { useAuthData } from '../context/auth.context';
import { authService } from '../common/services/auth.service';
import { toastSuccess } from '../common/components/toast/toast.component';

interface IProps {}

export const EmailVerificationPage: FC<IProps> = () => {
  const { email: resetEmail } = useAuthData();
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');

  const { mutate: resetMutation } = useMutation<any, AxiosError, { email: string }>(
    ({ email }) => authService.resetPassword({ email }),
    {
      onSuccess: (res) => {
        toastSuccess(res.message);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <AuthWrapper title="Check Your Email" align="center" variant="h5" errorMsg={serverErrorMsg}>
        <Text
          colorVariant="common.black"
          variant="body1"
          align="center"
          fontWeight="fontWeightMedium"
          mt="1.25rem"
        >
          We sent a password reset link to
        </Text>
        <Text
          colorVariant="common.black"
          variant="body1"
          align="center"
          fontWeight="fontWeightMedium"
        >
          {`${resetEmail}`}
        </Text>
        <Text colorVariant="common.black" variant="body1" align="center" mt="1.25rem">
          If you didn`t receive the email?
        </Text>
        <TextButtonTemplate>
          <Text
            colorVariant="success.main"
            variant="body1"
            align="center"
            fontWeight="fontWeightBold"
            mt="0.25rem"
            onClick={() => resetMutation({ email: resetEmail })}
          >
            Click here to resend
          </Text>
        </TextButtonTemplate>
        <Text
          colorVariant="common.black"
          variant="subtitle2"
          align="center"
          letterSpacing="0.02em"
          mt="2rem"
        >
          Back to&nbsp;
          <CustomLink to={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}>Reset Password</CustomLink>
        </Text>
      </AuthWrapper>
    </PageWrapper>
  );
};
