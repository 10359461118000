import React, { FC, useRef, useState } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import * as Styled from './status-badge.styled';
import { Text } from '../global-typography';
import { EStatusTypes } from '../../types/components.types';
import { getStatusText } from '../../utils/components.util';
import { useOutsideClick } from '../../hooks';
import { EditFriendRemovePopover } from '../../../home/components/edit-friend-popover';
import phone from '../../../../assets/icons/phone-blue.svg';
import { useCreateAudioRoom } from '../../hooks/use-create-audio-room';
import { CustomButton } from '../button';
import { toastSuccess } from '../toast/toast.component';
import { customErrorHandler } from '../../utils/error.util';
import { friendService } from '../../services/friend.service';
import threeDots from '../../../../assets/icons/three-dots.svg';

interface IProps {
  id: string;
  username?: string;
  status?: EStatusTypes;
}

export const StatusBadge: FC<IProps> = ({ status, id, username }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const { mutate: removeFriendMutation } = useMutation<any, AxiosError, string>(
    (userId) => friendService.removeFriend({ id: userId }),
    {
      onSuccess: () => {
        toastSuccess('Friend successfully removed!');
      },
      onError: customErrorHandler
    }
  );

  const menuRef = useRef<HTMLDivElement>(null);

  const { mutate: createRoomAsync } = useCreateAudioRoom();

  const onCall = async () => {
    if (username) {
      createRoomAsync(username);
    }
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const onDeleteFriendBtn = () => {
    removeFriendMutation(id);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useOutsideClick(menuRef, closeMenu);

  return (
    <Grid container alignItems="center">
      <Styled.StatusBadgeContainer>
        <Styled.StatusIndicator status={status} />
        <Text colorVariant="common.black" variant="body1" ml="0.5rem" sx={{ cursor: 'auto' }}>
          {getStatusText(status)}
        </Text>
      </Styled.StatusBadgeContainer>

      {status !== EStatusTypes.OFFLINE && (
        <Styled.CallButton onClick={onCall}>
          <img src={phone} alt="call" width="18" height="18" />
        </Styled.CallButton>
      )}

      <Styled.DotsContainer ref={menuRef} isActive={menuOpen} onClick={handleOpenMenu}>
        <img src={threeDots} alt="three_dots" width="23" height="5" />
        {menuOpen && (
          <EditFriendRemovePopover
            closeMenu={closeMenu}
            handleOpenConfirmModal={handleOpenConfirmModal}
          />
        )}
      </Styled.DotsContainer>
      <Modal
        style={{ zIndex: 10000 }}
        open={isConfirmationModalOpen}
        onClose={handleCloseConfirmModal}
      >
        <Box sx={Styled.modalStyle}>
          <Typography variant="h6" component="h2">
            Are you sure you want to delete {username}?
          </Typography>
          <Styled.ButtonContainer>
            <CustomButton variant="outlined" color="error" onClick={onDeleteFriendBtn}>
              Delete friend
            </CustomButton>
            <CustomButton variant="outlined" width="182px" onClick={handleCloseConfirmModal}>
              Go back
            </CustomButton>
          </Styled.ButtonContainer>
        </Box>
      </Modal>
    </Grid>
  );
};
