import { useEffect, useRef, useCallback } from 'react';
// import freeice from 'freeice';
import useStateWithCallback from './useStateWithCallback';

import { ACTIONS } from '../../common/consts/actions';
import { localStorageService } from '../../common/services/local-storage.service';
import { userService } from '../../common/services/user.service';
import { useSocket } from '../../common/utils/socket.util';

export const LOCAL_VIDEO = 'LOCAL_VIDEO';

const freeice = () => [
  {
    urls: ['turns:turnserver.example.org', 'turn:turnserver.example.org'],
    username: 'webrtc',
    credential: 'turnpassword'
  },
  {
    urls: 'turn:openrelay.metered.ca:80',
    username: 'openrelayproject',
    credential: 'openrelayproject'
  },
  {
    urls: 'turn:openrelay.metered.ca:443',
    username: 'openrelayproject',
    credential: 'openrelayproject'
  },
  {
    urls: 'turn:openrelay.metered.ca:443?transport=tcp',
    username: 'openrelayproject',
    credential: 'openrelayproject'
  },
  {
    urls: 'turn:numb.viagenie.ca',
    credential: 'muazkh',
    username: 'webrtc@live.com'
  },
  {
    urls: 'turn:relay.metered.ca:80',
    username: '55dfc06f92e2c17c749ef7ec',
    credential: 'FhsmRr2ND8UeKV6x'
  },
  {
    urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302']
  },
  {
    url: 'stun:stun2.l.google.com:19302',
    urls: ['stun:stun2.l.google.com:19302']
  },
  {
    url: 'stun:stun.schlund.de',
    urls: ['stun:stun.schlund.de']
  },
  {
    url: 'stun:stun.ekiga.net',
    urls: ['stun:stun.ekiga.net']
  },
  {
    url: 'stun:stun3.l.google.com:19302',
    urls: ['stun:stun3.l.google.com:19302']
  },

  {
    url: 'stun:stun.ideasip.com',
    urls: ['stun:stun.ideasip.com']
  },
  {
    url: 'stun:stun.voipstunt.com',
    urls: ['stun:stun.voipstunt.com']
  }
];

export const useWebRTC = (roomID: string) => {
  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const user = data || {};
  const userId = user.id;
  const { socket } = useSocket(userId);
  const [clients, updateClients] = useStateWithCallback([]);

  const addNewClient = useCallback(
    (newClient, cb) => {
      updateClients((list: any) => {
        if (!list.includes(newClient)) {
          return [...list, newClient];
        }

        return list;
      }, cb);
    },
    [clients, updateClients]
  );

  const peerConnections = useRef<any>({});
  const localMediaStream = useRef<any>(null);
  const peerMediaElements = useRef<any>({
    [LOCAL_VIDEO]: null
  });

  useEffect(() => {
    async function handleNewPeer({ peerID, createOffer }: { peerID: string; createOffer: any }) {
      if (peerID in peerConnections.current) {
        return console.warn(`Already connected to peer ${peerID}`);
      }

      peerConnections.current[peerID] = new RTCPeerConnection({
        iceServers: freeice()
      });

      peerConnections.current[peerID].onicecandidate = (event: any) => {
        if (event.candidate) {
          socket?.emit(ACTIONS.RELAY_ICE, {
            peerID,
            iceCandidate: event.candidate
          });
        }
      };

      let tracksNumber = 0;
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }: any) => {
        tracksNumber += 1;

        // for video and audio tracksNumber must === 2
        if (tracksNumber === 1) {
          // audio tracks received
          tracksNumber = 0;
          addNewClient(peerID, () => {
            if (peerMediaElements.current[peerID]) {
              peerMediaElements.current[peerID].srcObject = remoteStream;
            } else {
              // FIX LONG RENDER IN CASE OF MANY CLIENTS
              let settled = false;
              const interval = setInterval(() => {
                if (peerMediaElements.current[peerID]) {
                  peerMediaElements.current[peerID].srcObject = remoteStream;
                  settled = true;
                }

                if (settled) {
                  clearInterval(interval);
                }
              }, 1000);
            }
          });
        }
      };

      localMediaStream.current.getTracks().forEach((track: any) => {
        peerConnections.current[peerID].addTrack(track, localMediaStream.current);
      });

      if (createOffer) {
        const offer = await peerConnections.current[peerID].createOffer();

        await peerConnections.current[peerID].setLocalDescription(offer);

        socket?.emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: offer
        });
      }
    }

    socket?.on(ACTIONS.ADD_PEER, handleNewPeer);

    return () => {
      socket?.off(ACTIONS.ADD_PEER);
    };
  }, [socket]);

  useEffect(() => {
    async function setRemoteMedia({
      peerID,
      sessionDescription: remoteDescription
    }: {
      peerID: string;
      sessionDescription: any;
    }) {
      await peerConnections.current[peerID]?.setRemoteDescription(
        new RTCSessionDescription(remoteDescription)
      );

      if (remoteDescription.type === 'offer') {
        const answer = await peerConnections.current[peerID].createAnswer();

        await peerConnections.current[peerID].setLocalDescription(answer);

        socket?.emit(ACTIONS.RELAY_SDP, {
          peerID,
          sessionDescription: answer
        });
      }
    }

    socket?.on(ACTIONS.SESSION_DESCRIPTION, setRemoteMedia);

    return () => {
      socket?.off(ACTIONS.SESSION_DESCRIPTION);
    };
  }, [socket]);

  useEffect(() => {
    socket?.on(
      ACTIONS.ICE_CANDIDATE,
      ({ peerID, iceCandidate }: { peerID: string; iceCandidate: any }) => {
        peerConnections.current[peerID]?.addIceCandidate(new RTCIceCandidate(iceCandidate));
      }
    );

    return () => {
      socket?.off(ACTIONS.ICE_CANDIDATE);
    };
  }, [socket]);

  useEffect(() => {
    const handleRemovePeer = ({ peerID }: { peerID: string }) => {
      if (peerConnections.current[peerID]) {
        peerConnections.current[peerID].close();
      }

      delete peerConnections.current[peerID];
      delete peerMediaElements.current[peerID];

      updateClients((list: any) => list.filter((c: any) => c !== peerID));
    };

    socket?.on(ACTIONS.REMOVE_PEER, handleRemovePeer);

    return () => {
      socket?.off(ACTIONS.REMOVE_PEER);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    async function startCapture() {
      localMediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });

      addNewClient(LOCAL_VIDEO, () => {
        const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

        if (localVideoElement) {
          localVideoElement.volume = 0;
          localVideoElement.srcObject = localMediaStream.current;
        }
      });
    }

    startCapture()
      .then(() => {
        socket?.emit(ACTIONS.JOIN, { room: roomID });
      })
      .catch((e) => console.error('Error getting userMedia:', e));

    return () => {
      localMediaStream.current.getTracks().forEach((track: any) => track.stop());

      socket?.emit(ACTIONS.LEAVE, { room: roomID });
    };
  }, [socket]);

  const provideMediaRef = useCallback(
    (id, node) => {
      peerMediaElements.current[id] = node;
    },
    [socket]
  );

  return {
    clients,
    provideMediaRef
  };
};
export default useWebRTC;
