import styled from 'styled-components';

const ResponseWrapper = styled('div')`
  width: 660px;
  height: 652px;
  display: flex;
  font-size: 20px;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  background-color: white;
`;

export const Styled = {
  ResponseWrapper
};
