import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const RemovePopoverContainer = styled.div<{ theme?: Theme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 226px;
  box-shadow: 1px 8px 18px #d0e7f1;
  background-color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: -82px;
  right: -214px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  &::after {
    content: '';
    display: block;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.common.white} transparent transparent;
    position: absolute;
    border-width: 8px;
    margin: 0;
    border-left-color: ${({ theme }) => theme.palette.common.white};
    left: 0;
    top: 100%;
    right: auto;
    border-bottom-left-radius: 0;
  }
`;
