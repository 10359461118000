export const EmptyRequestsIconComponent = () => {
  return (
    <svg
      width="423"
      height="428"
      viewBox="0 0 423 428"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.582 416.971C166.296 434.88 187.493 427.445 195.608 413.083C211.302 385.31 263.483 328.516 346.175 309.32C455.695 283.896 432.823 216.392 365.496 180.968C298.17 145.545 247.829 96.5302 255.448 43.2353C263.067 -10.0597 187.576 -28.4321 154.004 74.0893C132.309 140.343 93.43 158.092 68.6377 162.359C45.2714 166.38 19.7116 171.425 7.24742 191.594C-7.87706 216.066 -2.59934 240.821 60.7235 257.8C150.458 281.86 69.982 376.825 136.582 416.971Z"
        fill="#EAF1F6"
      />
      <path
        d="M59.3811 300.848C60.6385 300.128 74.8312 294.119 145.437 327.035C145.231 327.315 145.144 327.448 145.144 327.448C145.144 327.448 71.7422 352.167 57.3606 321.715C53.0659 312.649 54.2965 306.406 59.2584 300.918L59.3811 300.848Z"
        fill="#0583F2"
      />
      <path
        d="M296.693 313.304C281.353 326.2 257.297 335.56 234.085 323.113C237.753 320.769 241.542 318.355 245.406 315.858C268.716 300.88 289.249 310.902 296.693 313.304Z"
        fill="#0583F2"
      />
      <path
        d="M324.17 154.697C339.842 285.201 302.597 308.886 298.451 312.484C297.25 313.525 296.764 313.449 294.949 312.982L294.918 313C287.236 310.963 268.717 300.879 245.405 315.856C241.541 318.353 237.751 320.767 234.084 323.111C210.304 338.193 191.1 348.821 177.265 342.653C165.53 336.666 154.949 331.485 145.437 327.035C74.831 294.119 60.6383 300.127 59.3809 300.847C60.8399 300.053 74.2248 292.633 92.2473 279.586C92.3086 279.551 92.3699 279.516 92.3831 279.468C116.473 262.01 148.733 234.498 171.922 199.108C187.708 175.042 203.152 114.034 232.949 102.063C294.188 79.2178 321.627 128.152 324.17 154.697Z"
        fill="#6BB3F2"
      />
      <path
        d="M256.456 152.408C258.357 147.388 258.172 142.666 256.042 141.859C253.913 141.053 250.646 144.468 248.745 149.488C246.844 154.507 247.029 159.23 249.159 160.036C251.288 160.843 254.555 157.427 256.456 152.408Z"
        fill="white"
      />
      <path
        d="M295.449 158.812C296.796 153.616 296.101 148.941 293.897 148.369C291.693 147.798 288.814 151.546 287.467 156.742C286.119 161.938 286.814 166.613 289.018 167.184C291.223 167.756 294.102 164.007 295.449 158.812Z"
        fill="white"
      />
      <path
        d="M271.714 177.889C271.394 177.889 271.085 177.779 270.836 177.579C270.588 177.378 270.415 177.098 270.347 176.786C270.098 175.656 268.987 172.312 267.227 171.815C265.466 171.318 262.012 173.248 260.615 174.26C260.314 174.478 259.939 174.567 259.573 174.509C259.206 174.45 258.878 174.248 258.66 173.947C258.442 173.647 258.352 173.272 258.411 172.905C258.47 172.538 258.672 172.21 258.972 171.992C259.523 171.593 264.455 168.124 267.987 169.12C271.67 170.16 272.95 175.576 273.083 176.191C273.127 176.395 273.126 176.607 273.077 176.811C273.029 177.015 272.936 177.205 272.805 177.368C272.673 177.531 272.507 177.662 272.318 177.752C272.129 177.842 271.923 177.889 271.713 177.889H271.714Z"
        fill="white"
      />
    </svg>
  );
};
