import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Field } from 'formik';

export const InputContainer = styled.div<{
  disabled: boolean | undefined;
  theme?: Theme;
  isErrorExists?: boolean;
}>`
  display: flex;
  position: relative;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.secondary.main : theme.palette.common.white};
  border: 1px solid
    ${({ theme, isErrorExists }) =>
      isErrorExists ? theme.palette.error.dark : theme.palette.secondary.main};
  border-radius: 10px;
  &:focus-within {
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
    border: 1px solid
      ${({ theme, isErrorExists }) =>
        isErrorExists ? theme.palette.error.dark : theme.palette.primary.dark};
  }
`;

export const InputIconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 18px;
  cursor: pointer;
`;

export const InputPrefix = styled.div<{ theme?: Theme }>`
  align-items: center;
  background-color: transparent;
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(2)};
`;

export const Input = styled(Field)<{
  theme?: Theme;
  isErrorExists?: boolean;
  errorPlaceholder?: boolean;
  type?: string;
}>`
  background-color: transparent;
  border: none;
  border-radius: 10px;
  height: 56px;
  outline: none;
  padding: 0px ${({ theme }) => theme.spacing(1.25)} 0px 0px;
  width: 100%;
  border-radius: 10px;
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme, initType }) =>
    initType === 'password' ? theme.spacing(5) : theme.spacing(3)};
  overflow: hidden;

  &::placeholder {
    color: ${({ theme, isErrorExists, errorPlaceholder }) =>
      isErrorExists && errorPlaceholder ? theme.palette.error.main : theme.palette.secondary.main};
  }
  &:-webkit-autofill {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
  &:-webkit-autofill:hover {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
  &:-webkit-autofill:focus {
    box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.common.white} inset !important`};
  }
`;

export const SubText = styled.span<{ theme?: Theme }>`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const OrContainer = styled.div<{ theme?: Theme }>`
  position: absolute;
  top: 16px;
  left: -28px;
`;

export const ErrorInfoContainer = styled.div<{ theme?: Theme }>`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.error.main};
`;

export const ErrorInfoText = styled.div<{ theme?: Theme }>`
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  font-family: 'DM Sans', sans-serif;
`;

export const AutocompleteInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  cursor: text;
`;

export const Styled = {
  InputContainer,
  InputPrefix,
  Input,
  ErrorInfoContainer,
  ErrorInfoText,
  AutocompleteInput,
  InputIconContainer,
  SubText,
  OrContainer
};
