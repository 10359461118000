import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AVATAR_HUGE_SIZE_ERROR } from '../settings/consts';
import { toastError } from '../common/components/toast/toast.component';

export interface AvatarContextProps {
  children: JSX.Element | JSX.Element[];
}

export interface IAvatarContext {
  avatarUrl: Array<string>;
  setAvatarUrl: Dispatch<SetStateAction<Array<string>>>;
  sizeError: string;
  setSizeError: Dispatch<SetStateAction<string>>;
}

export const AvatarContext = createContext<IAvatarContext>({
  avatarUrl: [''],
  setAvatarUrl: () => {},
  sizeError: '',
  setSizeError: () => {}
});

export const AvatarState = ({ children }: AvatarContextProps) => {
  const [avatarUrl, setAvatarUrl] = useState(['']);
  const [sizeError, setSizeError] = useState('');

  useEffect(() => {
    if (sizeError === '') {
      return;
    }

    toastError(AVATAR_HUGE_SIZE_ERROR);
  }, [sizeError]);

  const value = useMemo(
    () => ({
      avatarUrl,
      setAvatarUrl,
      sizeError,
      setSizeError
    }),
    [avatarUrl, setAvatarUrl, sizeError, setSizeError]
  );

  return <AvatarContext.Provider value={value}>{children}</AvatarContext.Provider>;
};
