import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toastSuccess } from '../../common/components/toast/toast.component';
import { friendService } from '../../common/services/friend.service';
import { sentRequestService } from '../../common/services/sent-request.service';
import { customErrorHandler } from '../../common/utils/error.util';

export const useFriendShipRequest = () => {
  const { refetch } = sentRequestService.useAllQuery<any>();

  const createFriend = async (userId: string) => friendService.createFriend({ id: userId });

  const onSuccess = () => {
    refetch();
    toastSuccess('Friend successfully added!');
  };

  return useMutation<any, AxiosError, string>({
    mutationFn: createFriend,
    onSuccess,
    onError: customErrorHandler
  });
};
