import styled from '@emotion/styled';
import { Button, Theme } from '@mui/material';

export const CustomButton = styled(Button)<{
  theme?: Theme;
  variant?: string;
  width?: string;
  size?: string;
}>`
  min-height: ${({ size }) => (size === 'small' ? '43px' : '58px')};
  letter-spacing: 0.02em;
  text-transform: none;
  border-radius: 10px;
  font-size: ${({ theme, size }) =>
    size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.h6.fontSize};
  box-shadow: none;
  box-sizing: border-box;
  min-width: 92px;
  width: ${({ width }) => width};

  &:disabled {
    background-color: ${({ theme, variant }) =>
      variant === 'contained' ? theme.palette.primary.main : 'inherit'};
    color: ${({ theme, variant }) =>
      variant === 'contained'
        ? theme.palette.common.white
        : variant === 'outlined'
        ? theme.palette.primary.main
        : 'inherit'};
    opacity: 0.5;
    border: 1px solid
      ${({ theme, variant }) => (variant === 'outlined' ? theme.palette.primary.light : 'inherit')};
  }

  &:hover {
    background-color: ${({ theme, variant }) =>
      variant === 'contained' ? theme.palette.primary.main : 'inherit'};
    box-shadow: 1px 3px 10px
      ${({ theme, variant }) =>
        variant === 'contained'
          ? theme.palette.primary.dark
          : variant === 'outlined'
          ? theme.palette.secondary.light
          : 'inherit'};
  }
`;

export const CustomIconButton = styled(CustomButton)<{ theme?: Theme }>`
  text-transform: none;
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
`;

export const TextButtonTemplate = styled.div<{ theme?: Theme }>`
  cursor: pointer;
`;
