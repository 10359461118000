import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { AuthWrapper, Text, CustomButton } from '../common/components';
import { useDecoratedMutations } from '../common/hooks';
import { userService } from '../common/services/user.service';
import { localStorageService } from '../common/services/local-storage.service';
import { useSocket } from '../common/utils/socket.util';
import { QUERY_KEYS } from '../common/consts/app-keys.const';

type DeleteAccountPopoverContainerProps = {
  handleClose: () => void;
};

export const DeleteAccountPopoverContainer: FC<DeleteAccountPopoverContainerProps> = ({
  handleClose
}) => {
  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const user = data || {};
  const userId = user.id;
  const { socket, handleClearSocket } = useSocket(userId);

  const { push } = useHistory();

  const queryClient = useQueryClient();

  const { mutateAsync: deleteAccountAsync } = useDecoratedMutations<any, AxiosError, void>(
    () => userService.deleteAccount(),
    {
      onSuccess: () => {
        queryClient.removeQueries([QUERY_KEYS.USER]);
        localStorageService.removeTokenFromStorage();
        socket?.disconnect();
        handleClearSocket();
        push('/');
      }
    }
  );

  const handleConfirm = async () => {
    await deleteAccountAsync();
    handleClose();
  };

  const handleDismiss = () => {
    handleClose();
  };

  return (
    <AuthWrapper withLogo={false} variant="h5">
      <Grid item container justifyContent="center" mt="1rem">
        <Text colorVariant="common.black" letterSpacing="0.02em">
          Are you sure?
        </Text>
      </Grid>
      <Grid item container columnSpacing="1rem" mt="1rem">
        <Grid item xs={6}>
          <CustomButton width="100%" variant="contained" onClick={handleConfirm}>
            Yes
          </CustomButton>
        </Grid>
        <Grid item xs={6}>
          <CustomButton width="100%" variant="outlined" onClick={handleDismiss}>
            No
          </CustomButton>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};
