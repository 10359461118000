import React, { FC, useEffect, useRef, useState } from 'react';
import * as Styled from './header.styled';
import { Text } from '../global-typography';
import { UserIcon } from '../user-item/user-icon.component';
import { SettingsPopover } from './settings-popover';
import { useUserData } from '../../../context/user.context';
import { useSocketData } from '../../../context/socket.context';
import { useOutsideClick } from '../../hooks';

interface IProps {
  id: string;
  username?: string | null;
}

export const UserInfo: FC<IProps> = ({ id, username }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { statusData } = useSocketData();
  const { status, onSetStatus } = useUserData();

  const handleMenu = () => {
    setOpenMenu((prev) => !prev);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    if (statusData) {
      onSetStatus(statusData.status);
    }
  }, [statusData]);

  const menuRef = useRef<HTMLDivElement>(null);
  useOutsideClick(menuRef, closeMenu);

  return (
    <Styled.UserInfoContainer
      ref={menuRef}
      isActive={openMenu}
      onClick={handleMenu}
      isUsername={!!username}
    >
      <UserIcon id={id} status={status} isAccountOwner />
      <Text
        colorVariant="common.black"
        variant="body1"
        fontWeight="fontWeightBold"
        ml={username ? '1rem' : '0rem'}
      >
        {username && `@${username}`}
      </Text>
      {openMenu && <SettingsPopover isUsername={!!username} />}
    </Styled.UserInfoContainer>
  );
};
