import { HttpFactoryService } from './http-factory.service';
import { HttpService } from './http.service';
import { IUser, IRegisterRequest, IResetRequest, ISetPasswordRequest } from './types';

export class AuthService {
  constructor(private httpService: HttpService) {}

  async googleAuth(token: string): Promise<IUser | void> {
    return this.httpService.post('auth/social', { access_token: token });
  }

  async register({ email, phone, password }: IRegisterRequest): Promise<IUser | void> {
    return this.httpService.post('auth/register', { email, phone, password });
  }

  async login({ email, phone, password }: IRegisterRequest): Promise<IUser | void> {
    return this.httpService.post('auth/login', { email, phone, password });
  }

  async resetPassword({ email, phone }: IResetRequest): Promise<any | void> {
    return this.httpService.post('auth/reset-password', { email, phone });
  }

  async verificatePhoneEmail({ email, phone }: IResetRequest): Promise<any | void> {
    return this.httpService.post('auth/verificate-phone-email', { email, phone });
  }

  async setPassword({ password, code }: ISetPasswordRequest): Promise<any | void> {
    return this.httpService.post('user/set-password', { password, code });
  }
}

const factory = new HttpFactoryService();
export const authService = new AuthService(factory.createHttpService());
