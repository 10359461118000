import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import {
  AuthWrapper,
  CustomLink,
  MultiInput,
  PageWrapper,
  Text,
  TextButtonTemplate
} from '../../common/components';
import { APP_KEYS } from '../../common/consts';
import { useAuthData } from '../../context/auth.context';
import { toastSuccess } from '../../common/components/toast/toast.component';
import { userService } from '../../common/services/user.service';
import { authService } from '../../common/services/auth.service';

interface IProps {}

export const PhoneVerificationPage: FC<IProps> = () => {
  const { push } = useHistory();
  const { phone } = useAuthData();
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');

  const { mutate: checkCodeMutation } = useMutation<any, AxiosError, { code: number }>(
    async ({ code }) => userService.checkPhoneCode({ code }),
    {
      onSuccess: (res) => {
        if (res.code) {
          toastSuccess(res.message);
          push(`${APP_KEYS.ROUTER_KEYS.RESET_PASSWORD}?code=${res.code}`);
        }
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const { mutate: resetMutation } = useMutation<any, AxiosError, string>(
    (phoneNumber) => authService.resetPassword({ phone: phoneNumber }),
    {
      onSuccess: () => {
        push(APP_KEYS.ROUTER_KEYS.PHONE_VERIFICATION);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const handleCheckCode = (code: number) => {
    checkCodeMutation({ code });
  };

  const handleSentCodeAgain = () => {
    if (phone) {
      resetMutation(phone);
    }
  };

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <AuthWrapper
        title="Enter Verification Code"
        align="center"
        variant="h5"
        errorMsg={serverErrorMsg}
      >
        <Text
          colorVariant="common.black"
          variant="body1"
          align="center"
          fontWeight="fontWeightMedium"
          mt="1.25rem"
        >
          We sent the verification code to
        </Text>
        <Text
          colorVariant="common.black"
          variant="body1"
          align="center"
          fontWeight="fontWeightMedium"
          mb="1.25rem"
        >
          {`${phone}`}
        </Text>
        <MultiInput codeHandler={handleCheckCode} />
        <Text colorVariant="common.black" variant="body1" align="center" mt="1.25rem">
          If you didn`t receive the code?
        </Text>
        <TextButtonTemplate onClick={handleSentCodeAgain}>
          <Text
            colorVariant="success.main"
            variant="body1"
            align="center"
            fontWeight="fontWeightBold"
            mt="0.25rem"
          >
            Click here to resend
          </Text>
        </TextButtonTemplate>
        <Text
          colorVariant="common.black"
          variant="subtitle2"
          align="center"
          letterSpacing="0.02em"
          mt="2rem"
        >
          Back to&nbsp;
          <CustomLink to={APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}>Reset Password</CustomLink>
        </Text>
      </AuthWrapper>
    </PageWrapper>
  );
};
