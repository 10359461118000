import { EStatusTypes } from '../types/components.types';
import { COLORS } from '../../theme';

export const getStatusColor = (status?: EStatusTypes) => {
  switch (status) {
    case EStatusTypes.ONLINE:
      return COLORS.online;
    case EStatusTypes.BUSY:
      return COLORS.busy;
    default:
      return COLORS.offline;
  }
};

export const getStatusBorderColor = (status?: EStatusTypes) => {
  switch (status) {
    case EStatusTypes.ONLINE:
      return COLORS.onlineBorder;
    case EStatusTypes.BUSY:
      return COLORS.busyBorder;
    default:
      return COLORS.offlineBorder;
  }
};

export const getStatusText = (status?: EStatusTypes) => {
  switch (status) {
    case EStatusTypes.ONLINE:
      return 'Online';
    case EStatusTypes.BUSY:
      return 'Busy';
    default:
      return 'Offline';
  }
};
