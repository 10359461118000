import { IUser } from '../../common/services/types';
import { AvatarMapType } from '../types.ts';

export const setChoosenAvatar = (
  userData: void | IUser | null | undefined,
  defaultAvatarMap: AvatarMapType,
  itemId: string | null
) => {
  if (itemId === defaultAvatarMap.id) {
    return true;
  }
  if (userData?.avatarDefaultUploaded === defaultAvatarMap.id) {
    return true;
  }
  return false;
};
