export const UrgentIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6402 13.1149L11.334 2.19217C10.304 0.387766 7.69926 0.387766 6.67294 2.19217L0.363077 13.1149C-0.666953 14.9193 0.615027 17.161 2.69361 17.161H15.2874C17.366 17.161 18.6702 14.8971 17.6402 13.1149ZM8.99977 14.7378C8.45141 14.7378 7.99568 14.2821 7.99568 13.7337C7.99568 13.1853 8.45141 12.7296 8.99977 12.7296C9.54813 12.7296 10.0039 13.1853 9.98163 13.7596C10.0076 14.2821 9.5259 14.7378 8.99977 14.7378ZM9.91494 8.24638C9.87048 9.02446 9.82231 9.79883 9.77785 10.5769C9.75562 10.8289 9.75562 11.0586 9.75562 11.3068C9.73339 11.7181 9.41104 12.0367 8.99977 12.0367C8.5885 12.0367 8.26986 11.7403 8.24392 11.3291C8.17723 10.1175 8.10683 8.92812 8.04014 7.71654C8.01791 7.3979 7.99568 7.07555 7.96974 6.75691C7.96974 6.23078 8.26615 5.79728 8.74782 5.66019C9.22949 5.54533 9.70745 5.77505 9.91494 6.23078C9.98534 6.3901 10.0076 6.54942 10.0076 6.73468C9.98534 7.24228 9.93717 7.74618 9.91494 8.24638Z"
        fill="#0583F2"
      />
    </svg>
  );
};
