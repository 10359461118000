import { Icon } from '@mui/material';
import React from 'react';
import toast from 'react-hot-toast';
import { Text } from '../global-typography';

export const toastSuccess = (msg: string) =>
  toast.success(
    (t) => (
      <Text
        style={{ cursor: 'pointer' }}
        colorVariant="common.black"
        variant="body1"
        onClick={() => toast.dismiss(t.id)}
      >
        {msg}
      </Text>
    ),
    {
      position: 'top-right',
      duration: 3000
    }
  );

export const toastError = (msg: string) => {
  toast.error(
    (t) => (
      <Text
        style={{ cursor: 'pointer' }}
        colorVariant="common.black"
        variant="body1"
        onClick={() => toast.dismiss(t.id)}
      >
        {msg}
      </Text>
    ),
    {
      position: 'top-right',
      duration: 3000
    }
  );
};
