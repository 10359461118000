import React, { FC } from 'react';
import { Grid } from '@mui/material';
import * as Styled from './user-item.styled';
import { UserIcon } from './user-icon.component';
import { Text } from '../global-typography';
import { EHomeTabs, EStatusTypes } from '../../types/components.types';
import { IUser } from '../../services/types';
// import { EditFriendHintInput } from '../../../home/components/edit-friend-hint-input';
// import { FriendItemContext } from '../../../context/friend-item.context';

interface IProps {
  id: string;
  friend?: IUser;
  username?: string;
  status?: EStatusTypes;
  description?: string;
  withborder?: string;
  type?: EHomeTabs;
  children?: React.ReactNode;
}

export const UserItem: FC<IProps> = ({
  id,
  friend,
  username,
  status,
  description,
  withborder = 'true',
  type,
  children
}) => {
  // const { isEditInput } = useContext(FriendItemContext);

  // eslint-disable-next-line no-confusing-arrow
  const renderUserHint = () => (
    // isEditInput ? (
    //   <EditFriendHintInput friend={friend} />
    // ) : (
    //   <Styled.HeyName>{`- ”hey ${friend?.friendHint ?? username}”`}</Styled.HeyName>
    // );

    // <Styled.HeyName>{`- ”hey ${friend?.friendHint ?? username}”`}</Styled.HeyName>
    <Styled.HeyName>{`- ”hey ${username}”`}</Styled.HeyName>
  );
  return (
    <Styled.UserItemContainer
      container
      alignItems="center"
      justifyContent="space-between"
      withborder={withborder}
    >
      <Grid alignItems="center">
        <Grid container alignItems="center">
          <UserIcon id={id} status={status} friend={friend} />
          {/* <Grid height={25} marginBottom="50px"> */}
          <Grid
            height={25}
            marginBottom={description && type === EHomeTabs.REQUESTS ? '50px' : '0'}
          >
            <Styled.UsernameWrapper>
              <Text
                colorVariant="common.black"
                variant="body1"
                fontWeight="fontWeightBold"
                ml="1rem"
                pt={description && type === EHomeTabs.REQUESTS ? '1.25rem' : '0rem'}
                sx={{ cursor: 'auto' }}
              >
                {username && `@${username}`}
              </Text>
              {type === EHomeTabs.FRIENDS && renderUserHint()}
            </Styled.UsernameWrapper>
            {description && type === EHomeTabs.REQUESTS && (
              <Styled.DescriptionText
                colorVariant="secondary.dark"
                variant="body2"
                ml="1rem"
                pt="0.25rem"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
              >
                {description}
              </Styled.DescriptionText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid alignItems="center">
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {});
          }
          return null;
        })}
      </Grid>
    </Styled.UserItemContainer>
  );
};
