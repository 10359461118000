import React, { FC } from 'react';
import * as Styled from './edit-friend-popover.styled';
import { EditFriendPopoverAction } from '../edit-friend-action';
// import microAzure from '../../../../assets/icons/micro-azure.svg';
// import microGrey from '../../../../assets/icons/micro-grey.svg';
// import editAzure from '../../../../assets/icons/edit-azure.svg';
// import editGrey from '../../../../assets/icons/edit-grey.svg';
import trashGrey from '../../../../assets/icons/trash-grey.svg';
import trashRed from '../../../../assets/icons/trash-red.svg';
import { COLORS } from '../../../theme';
// import { ModalContext } from '../../../context/modal.context';
// import { FriendItemContext } from '../../../context/friend-item.context';

interface IProps {
  closeMenu: () => void;
  handleOpenConfirmModal: () => void;
}

export const EditFriendRemovePopover: FC<IProps> = ({ closeMenu, handleOpenConfirmModal }) => {
  // const { setIsModalOpen, setFriendId } = useContext(ModalContext);
  // const { setIsEditInput } = useContext(FriendItemContext);

  // onEditVoiceCommand open modal and put friendId in order to edit friend voice command

  // const onEditVoiceCommand = () => {
  //   setIsModalOpen(true);
  //   setFriendId(id);
  // };

  // const onEditTextHint = () => {
  //   setIsEditInput(true);
  // };

  const onDeleteFriend = () => {
    closeMenu();
    handleOpenConfirmModal();
  };

  return (
    <Styled.RemovePopoverContainer>
      {/* <EditFriendPopoverAction
        text="Edit voice command"
        activeIcon={microAzure}
        inactiveIcon={microGrey}
        activeColor={COLORS.azure}
        inactiveColor={COLORS.offline}
        action={onEditVoiceCommand}
      /> */}
      {/* <EditFriendPopoverAction
        text="Edit text hint"
        activeIcon={editAzure}
        inactiveIcon={editGrey}
        activeColor={COLORS.azure}
        inactiveColor={COLORS.offline}
        action={onEditTextHint}
      /> */}
      <EditFriendPopoverAction
        text="Delete friend"
        activeIcon={trashRed}
        inactiveIcon={trashGrey}
        activeColor={COLORS.lust}
        inactiveColor={COLORS.offline}
        action={onDeleteFriend}
        isLast
      />
    </Styled.RemovePopoverContainer>
  );
};
