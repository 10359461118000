import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { HomePageContainer } from './home-page.container';
import { EHomeTabs } from '../common/types/components.types';
import { FriendsTab } from './friends.component';
import { RequestsTab } from './requests.component';
import { userService } from '../common/services/user.service';
import { localStorageService } from '../common/services/local-storage.service';
import { APP_KEYS } from '../common/consts';
import { ConversationNotificationContainer } from './conversation-notification-container.component';
import { OverlayCover } from '../common/components/overlayCover';
import { PermissionNotificationContainer } from './permission-notification-container';

interface IProps {}

type Keys = '0' | '1';

const ERROR = {
  0: { status: true, title: 'Name is currently busy', description: null },
  2: { status: true, title: 'Name is offline right now', description: null },
  1: {
    status: true,
    title: 'Oops...',
    description: 'We are not able to find a friend with these keywords. Please try again!'
  }
};

export const HomePage: FC<IProps> = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  const token = localStorageService.getTokenFromStorage();
  const { data, isLoading } = userService.useAllQuery<any>(token as string);
  const [activeTab, setActiveTab] = useState(EHomeTabs.FRIENDS);
  const handleActiveTab = (tab: EHomeTabs) => (e: any) => {
    e.stopPropagation();
    setActiveTab(tab);
  };

  if (isLoading) return null;

  const { id, isNewAccount } = data || {};

  if (isNewAccount) {
    push(APP_KEYS.ROUTER_KEYS.SETTINGS);
  }

  // if token time is expired or bad token
  if (!id) {
    push(APP_KEYS.ROUTER_KEYS.HOME);
  }

  const showNotification = search.includes('notification');
  const searchParams = new URLSearchParams(search);
  const errorKey = searchParams.get('error') as Keys;

  const showPermissionNotification = search.includes('permission');

  return (
    <>
      {showNotification && ERROR[errorKey].status && (
        <OverlayCover>
          <ConversationNotificationContainer
            title={ERROR[errorKey].title}
            description={ERROR[errorKey].description}
          />
        </OverlayCover>
      )}
      {showPermissionNotification && (
        <OverlayCover>
          <PermissionNotificationContainer />
        </OverlayCover>
      )}
      <HomePageContainer activeTab={activeTab} handleActiveTab={handleActiveTab}>
        {activeTab === EHomeTabs.FRIENDS && <FriendsTab userId={id} />}
        {activeTab === EHomeTabs.REQUESTS && <RequestsTab />}
      </HomePageContainer>
    </>
  );
};
