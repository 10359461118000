import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { MergedTheme } from '../../../theme';

export const OverlayContainer = styled(Grid)<{ theme?: MergedTheme }>`
  background-color: ${({ theme }) => theme.colors.semiTransparentBlue};
  position: fixed;
  top: 0px;
  left: 0;
  min-height: 100vh;
  z-index: 10000000;
`;
