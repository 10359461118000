/* eslint-disable jsx-a11y/media-has-caption */
import React, { createContext, ReactChild, ReactChildren, useContext, useMemo } from 'react';

import startCall from '../../assets/sound/start_call.mp3';
import endCall from '../../assets/sound/end_call.mp3';
import userBusy from '../../assets/sound/user_busy.mp3';
import commandUnknown from '../../assets/sound/command_unknown.mp3';

export const ESoundTypes = {
  START_CALL: startCall,
  END_CALL: endCall,
  USER_BUSY: userBusy,
  COMMAND_UNKNOWN: commandUnknown
} as const;

interface IContextValue {
  play: (path: string) => void;
}

const SoundContext = createContext<IContextValue>({} as IContextValue);

interface ISoundContext {
  children: ReactChild | ReactChildren;
}

export const SoundContextProvider: React.FC<ISoundContext> = ({ children }) => {
  const play = (path: string) => {
    const audio = new Audio(path);
    audio.play().catch((e: any) => {
      console.warn(e);
    });
  };

  const value = useMemo(
    () => ({
      play
    }),
    [play]
  );

  return <SoundContext.Provider value={value}>{children}</SoundContext.Provider>;
};

export const useSound = () => useContext(SoundContext);
