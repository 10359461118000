import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { conversationService } from '../services';
import { IErrorResponse } from '../types';
import { customErrorHandler } from '../utils/error.util';

export const useEndAudioRoom = () => {
  const createAudioRoom = async () => conversationService.deleteRoom();

  return useMutation<any, AxiosError<IErrorResponse>, {}>({
    mutationFn: createAudioRoom,
    onError: customErrorHandler
  });
};
