import * as React from 'react';
import { Pointer, PopOverContainer, PopOverTextWrapper, PopoverText } from './popover.styled';

interface IPopOverProps {
  positionBottom?: string;
  positionRight?: string;
}

export const PopOver = ({ positionBottom = '0', positionRight = '0' }: IPopOverProps) => (
  <PopOverContainer>
    <PopOverTextWrapper positionBottom={positionBottom} positionRight={positionRight}>
      <PopoverText>If you skip this step, we won’t send the friend request!</PopoverText>
      <Pointer />
    </PopOverTextWrapper>
  </PopOverContainer>
);
