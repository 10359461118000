import React from 'react';
import {
  BackBtn,
  BackText,
  BackWrapper,
  HeaderText,
  RecordSuccessfullContainer,
  RecordSuccessfullHeader
} from './record-successfull.styled';
import tick from '../../../../assets/icons/tick-blue-bg.svg';

interface IRecordSuccessfull {
  onClick: () => void;
}

export const RecordSuccessfull = ({ onClick }: IRecordSuccessfull) => (
  <RecordSuccessfullContainer>
    <RecordSuccessfullHeader>
      <img src={tick} alt="record_successfull" width="52" height="52" />
      <HeaderText>Recording saved</HeaderText>
    </RecordSuccessfullHeader>
    <BackWrapper>
      <BackText>Back to</BackText>
      <BackBtn onClick={onClick}>Homepage</BackBtn>
    </BackWrapper>
  </RecordSuccessfullContainer>
);
