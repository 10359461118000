import React, { FC, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useGoogleLogin } from '@react-oauth/google';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AuthWrapper,
  CustomButton,
  CustomIconButton,
  CustomLink,
  ForgotLink,
  FormInput,
  GoogleIcon,
  PageWrapper,
  Text,
  TextDivider
} from '../../common/components';
import { APP_KEYS } from '../../common/consts';
import { ESignTypes } from '../types';
import { authService } from '../../common/services/auth.service';
import { localStorageService } from '../../common/services/local-storage.service';
import { checkEmail, signValidationSchema } from '../utils/auth.util';

interface IProps {
  type: ESignTypes;
}

interface IFormikData {
  login: string;
  password: string;
}

const initialValues = {
  login: '',
  password: ''
};

export const SignPage: FC<IProps> = ({ type }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const signMethod = pathname.includes(APP_KEYS.ROUTER_KEYS.SIGN_UP)
    ? APP_KEYS.ROUTER_KEYS.SIGN_UP
    : APP_KEYS.ROUTER_KEYS.LOGIN;
  const nextRoute =
    signMethod === APP_KEYS.ROUTER_KEYS.SIGN_UP
      ? APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION
      : APP_KEYS.ROUTER_KEYS.HOME;

  const [errorLoginMsg, setErrorLoginMsg] = useState<string>('');
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');
  const [submitValues, setSubmitValues] = useState({ login: '', password: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleForgotLink = (login: string) => {
    push(`${APP_KEYS.ROUTER_KEYS.FORGOT_PASSWORD}?${login}`);
  };

  const { mutate: googleAuthMutation } = useMutation<any, AxiosError, string>(
    (token) => authService.googleAuth(token),
    {
      onSuccess: (res) => {
        localStorageService.setTokenToStorage(res.token);
        push(nextRoute);
      },
      onError: (err: any) => {
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const { mutate: resetMutationVerificatePhoneEmail } = useMutation<
    any,
    AxiosError,
    { login: string }
  >(
    ({ login }) => {
      const isEmail = checkEmail(login);
      const args = isEmail ? { email: login } : { phone: login };
      return authService.verificatePhoneEmail(args);
    },
    {
      onSuccess: (res) => {
        const login = res.email === undefined ? res.phone : res.email;
        const isEmail = checkEmail(login);
        const nextRouteRedirect = isEmail
          ? `${APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION}?email=${login}&'`
          : `${APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION}?phone=${login}&'`;

        push(nextRouteRedirect);
        setIsLoading(false);
      },
      onError: (err: any) => {
        setIsLoading(false);
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const redirectToPhoneEmailVerification = ({ login }: { login: string }) => {
    resetMutationVerificatePhoneEmail({ login });
  };

  const { mutate: registerMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ login, password }) => {
      const isEmail = checkEmail(login);
      const args = isEmail ? { email: login } : { phone: login };
      return authService.register({ ...args, password });
    },
    {
      onSuccess: (res) => {
        const login = res.email === null ? res.phone : res.email;
        redirectToPhoneEmailVerification({ login });
      },
      onError: (err: any) => {
        setIsLoading(false);
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        setServerErrorMsg(error);
      }
    }
  );

  const { mutate: loginMutation } = useMutation<any, AxiosError, IFormikData>(
    ({ login, password }) => {
      const isEmail = checkEmail(login);
      const args = isEmail ? { email: login } : { phone: login };
      return authService.login({ ...args, password });
    },
    {
      onSuccess: (res) => {
        setIsLoading(false);
        localStorageService.setTokenToStorage(res.token);
        push(nextRoute);
      },
      onError: (err: any) => {
        setIsLoading(false);
        const error = err.response?.data?.message || 'Something went wrong. Try again.';
        if (err.response?.data?.text === 'Your account has not been verified') {
          const login = err.response?.data?.login;
          const isEmail = checkEmail(login);
          const nextRouteRedirect = isEmail
            ? `${APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION}?email=${login}&'`
            : `${APP_KEYS.ROUTER_KEYS.PHONE_EMAIL_VERIFICATION}?phone=${login}&'`;
          push(nextRouteRedirect);
        } else setServerErrorMsg(error);
      }
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ login, password }) => {
      setIsLoading(true);
      setSubmitValues({ login, password });

      (type === ESignTypes.SIGN_UP ? registerMutation : loginMutation)({
        login,
        password
      });
    },
    validationSchema: signValidationSchema(type)
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse: any) => {
      googleAuthMutation(tokenResponse.access_token);
    }
  });

  const { login, password } = formik.values;

  if (
    serverErrorMsg &&
    ((submitValues.login && login !== submitValues.login) ||
      (submitValues.password && password !== submitValues.password))
  ) {
    setServerErrorMsg('');
  }
  const errorMsg = errorLoginMsg || serverErrorMsg;
  const isValidButton =
    !Object.keys(formik.errors).length && !!login && !!password && !errorMsg && !serverErrorMsg;

  const title = type === ESignTypes.SIGN_UP ? 'SIGN UP' : 'LOGIN';
  const submitButtonText = type === ESignTypes.SIGN_UP ? 'SIGN UP' : 'LOGIN';
  const bottomText = type === ESignTypes.SIGN_UP ? 'Already a user?' : 'Need an account?';
  const bottomLinkText = type === ESignTypes.SIGN_UP ? 'LOGIN' : 'SIGN UP';
  const redirectLink =
    type === ESignTypes.SIGN_UP ? APP_KEYS.ROUTER_KEYS.LOGIN : APP_KEYS.ROUTER_KEYS.SIGN_UP;

  return (
    <PageWrapper justifyContent="center" alignItems="center">
      <AuthWrapper title={title} errorMsg={serverErrorMsg || errorMsg}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column" mt="0.5rem">
              <Grid item mt="1.5rem">
                <FormInput
                  name="login"
                  type="text"
                  passedValue=""
                  placeholder="Enter your Email or Phone Number"
                  label="Email / Phone Number"
                  setErrorMsg={setErrorLoginMsg}
                />
              </Grid>
              <Grid item mt="1.5rem">
                <FormInput
                  name="password"
                  type="password"
                  passedValue=""
                  placeholder="Enter your Password"
                  label="Password"
                  setErrorMsg={setErrorLoginMsg}
                />
              </Grid>
              {type === ESignTypes.LOGIN && (
                <ForgotLink>
                  <Text
                    colorVariant="secondary.main"
                    variant="body1"
                    align="right"
                    letterSpacing="0.02em"
                    pt="0.6125rem"
                    onClick={() => handleForgotLink(login)}
                    sx={{ cursor: 'pointer' }}
                  >
                    Forgot Password?
                  </Text>
                </ForgotLink>
              )}
              <Grid item mt={type === ESignTypes.SIGN_UP ? '1.75rem' : '0.75rem'}>
                <CustomButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!isValidButton || isLoading}
                >
                  {!isLoading && submitButtonText}
                </CustomButton>
                {isLoading && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '-50px',
                      marginBottom: '10px'
                    }}
                  >
                    <CircularProgress style={{ color: 'white' }} />
                  </Box>
                )}
              </Grid>
              <TextDivider />
              <Grid item mt="1.5rem">
                <CustomIconButton
                  onClick={() => googleLogin()}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<GoogleIcon />}
                >
                  Continue with Google
                </CustomIconButton>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
        <Grid item container justifyContent="center" mt="2.5rem">
          <Text colorVariant="common.black" variant="subtitle2" letterSpacing="0.02em">
            {bottomText}&nbsp;
            <CustomLink to={redirectLink}>{bottomLinkText}</CustomLink>
          </Text>
        </Grid>
      </AuthWrapper>
    </PageWrapper>
  );
};
