import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Styled } from './form-input.styled';
import * as GlobalTypography from '../global-typography';
import { OpenEyeIcon, CloseEyeIcon } from '../icons';
import { getInputType } from './form-input.util';
import { Text } from '../global-typography';

export interface IFormInputProps {
  text?: string;
  name: string;
  type: string;
  placeholder?: string;
  step?: number;
  min?: number;
  width?: string;
  isEdit?: boolean;
  maxLength?: number;
  disabled?: boolean | undefined;
  passedValue: string;
  label: string;
  subText?: string;
  setErrorMsg?: React.Dispatch<React.SetStateAction<string>>;
  customError?: boolean;
  errorPlaceholder?: boolean;
  notTouchableError?: boolean;
  orError?: boolean;
}

export const FormInput: React.FC<IFormInputProps> = ({
  name,
  type,
  placeholder,
  step,
  width,
  min,
  maxLength,
  disabled,
  passedValue,
  label,
  subText,
  setErrorMsg,
  customError,
  errorPlaceholder,
  notTouchableError,
  orError
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const errMsg = getIn(errors, name);
  const isErrorExists = ((errMsg || customError) && getIn(touched, name)) || notTouchableError;

  const [openEye, setOpenEye] = useState(false);
  const handleEye = () => {
    setOpenEye((prev) => !prev);
  };

  useEffect(() => {
    if (setErrorMsg) {
      if (isErrorExists) {
        setErrorMsg(getIn(errors, name));
      } else {
        setErrorMsg('');
      }
    }
  }, [errMsg, isErrorExists]);

  const renderInputType = getInputType(type, openEye);

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        {label && (
          <GlobalTypography.Text
            variant="body1"
            colorVariant="common.black"
            fontWeight="fontWeightMedium"
            pb="0.125rem"
          >
            {label}
            {subText && <Styled.SubText>{subText}</Styled.SubText>}
          </GlobalTypography.Text>
        )}
      </Grid>

      <Styled.InputContainer disabled={disabled} isErrorExists={isErrorExists}>
        <Styled.InputPrefix />
        <Styled.Input
          id={name}
          name={name}
          type={renderInputType}
          initType={type}
          min={min || 0.01}
          step={step}
          onChange={handleChange(name)}
          value={getIn(values, name) || passedValue}
          placeholder={placeholder}
          width={width}
          maxLength={maxLength}
          disabled={disabled}
          isErrorExists={isErrorExists}
          errorPlaceholder={errorPlaceholder}
        />
        {type === 'password' && (
          <Styled.InputIconContainer onClick={handleEye}>
            {openEye ? <OpenEyeIcon /> : <CloseEyeIcon />}
          </Styled.InputIconContainer>
        )}
        {orError && (
          <Styled.OrContainer>
            <Text colorVariant="error.dark" variant="body1">
              or
            </Text>
          </Styled.OrContainer>
        )}
      </Styled.InputContainer>
    </Grid>
  );
};

export interface IAutocompleteFormInputProps {
  text?: string;
  name: string;
  type: string;
  placeholder?: string;
  step?: number;
  min?: number;
  width?: string;
  isEdit?: boolean;
  maxLength?: number;
  toggleAutocomplete: React.FocusEventHandler<HTMLInputElement>;
  handleAutocomplete: () => void;
}

export const AutocompleteFormInput: React.FC<IAutocompleteFormInputProps> = ({
  name,
  type,
  placeholder,
  step,
  width,
  min,
  maxLength,
  toggleAutocomplete,
  handleAutocomplete
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);

  return (
    <>
      <Styled.AutocompleteInput
        id={name}
        name={name}
        type={type}
        min={min || 0.01}
        step={step}
        onChange={() => {
          handleChange(name);
          handleAutocomplete();
        }}
        value={getIn(values, name)}
        placeholder={placeholder}
        width={width}
        maxLength={maxLength}
        onFocus={toggleAutocomplete}
      />
      {isErrorExists && (
        <Styled.ErrorInfoContainer>
          <Styled.ErrorInfoText>{getIn(errors, name)}</Styled.ErrorInfoText>
        </Styled.ErrorInfoContainer>
      )}
    </>
  );
};
