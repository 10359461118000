export const OpenEyeIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 18C3.80308 18 0.234953 11.6118 0.086625 11.34C-0.028875 11.1284 -0.028875 10.8714 0.086625 10.6598C0.234953 10.3882 3.80308 4 11 4C18.1969 4 21.765 10.3882 21.9134 10.66C22.0289 10.8715 22.0289 11.1286 21.9134 11.3402C21.765 11.6118 18.1969 18 11 18ZM1.49652 10.999C2.3143 12.2609 5.52509 16.6 11 16.6C16.4923 16.6 19.6888 12.2644 20.5035 11.001C19.6857 9.73912 16.4749 5.4 11 5.4C5.50773 5.4 2.3112 9.73562 1.49652 10.999ZM11 15.2C8.72541 15.2 6.875 13.3159 6.875 11C6.875 8.68405 8.72541 6.8 11 6.8C13.2746 6.8 15.125 8.68405 15.125 11C15.125 13.3159 13.2746 15.2 11 15.2ZM11 8.2C9.48372 8.2 8.25 9.45615 8.25 11C8.25 12.5438 9.48372 13.8 11 13.8C12.5163 13.8 13.75 12.5438 13.75 11C13.75 9.45615 12.5163 8.2 11 8.2Z"
        fill="#B0B0B0"
      />
    </svg>
  );
};
