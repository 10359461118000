import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GridProps } from '@mui/material/Grid/Grid';
import { AxiosError } from 'axios';
import * as Styled from './page-wrapper.styled';
import { Header } from '../header';
import { useSocket } from '../../utils/socket.util';
import { userService } from '../../services/user.service';
import { useSocketData } from '../../../context/socket.context';
import { localStorageService } from '../../services/local-storage.service';
import {
  conversationService,
  nativeSpeechService,
  speechService as azureSpeechService
} from '../../services';
import { matchSpeech } from '../../utils';
import { useDecoratedMutations } from '../../hooks';
import { customErrorHandler } from '../../utils/error.util';
import { toastSuccess } from '../toast/toast.component';
import { EStatusTypes } from '../../types/components.types';
import { getAllowNotification } from '../../../auth/utils/notificationMessage.util';

interface IProps extends GridProps {
  withHeader?: boolean;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
}

export const PageWrapper: FC<IProps> = ({
  withHeader = false,
  headerContent,
  children,
  ...props
}) => {
  const token = localStorageService.getTokenFromStorage();
  const { data } = userService.useAllQuery<any>(token as string);
  const user = data || {};
  const userId = user.id;

  const speechModule = localStorage.getItem('speechRecognitionModule');
  const speechService = speechModule === 'native' ? nativeSpeechService : azureSpeechService;

  const { push } = useHistory();
  const { socket, accepted, requestCount, statusData } = useSocket(userId);
  const { setStatusData, setAccepted, setRequestedCount, setRecognizedText } = useSocketData();

  const { mutateAsync: createRoomAsync } = useDecoratedMutations<any, AxiosError, string>(
    (usernamePrompt: string) => conversationService.createRoom({ usernamePrompt }),
    {
      onError: (err: any, usernamePrompt) => {
        const error = err!.response!.data!.message;
        const username = usernamePrompt.toLowerCase();

        if (error === 'User busy') push(`/?notification&&error=0&&name=${username}`);
        if (error === 'User offline') push(`/?notification&&error=2&&name=${username}`);
        if (error === 'We are not able to find a user via these keywords. Please try again.') {
          push('/?notification&&error=1');
        }
      }
    }
  );

  const { mutateAsync: deleteRoomAsync } = useDecoratedMutations<any, AxiosError, void>(
    () => conversationService.deleteRoom(),
    {
      onError: customErrorHandler
    }
  );

  const { mutateAsync: declineCallAsync } = useDecoratedMutations<any, AxiosError, void>(
    () => conversationService.declineRoom(),
    {
      onSuccess: () => {
        toastSuccess('Call declined!');
      },
      onError: customErrorHandler
    }
  );

  const recognizeCallback = async (text: string, status: EStatusTypes) => {
    if (!text?.length) {
      return;
    }
    const commandMatch = matchSpeech(text);
    if (commandMatch[0] && status === EStatusTypes.ONLINE) {
      setRecognizedText(`User start call with ${commandMatch[0]}`);
      if (typeof commandMatch[0] === 'string') {
        await createRoomAsync(commandMatch[0]);
      }
    }
    if (commandMatch[1] && status === EStatusTypes.BUSY) {
      await deleteRoomAsync();
      setRecognizedText('User ended call');
    }
    if (commandMatch[2] && status === EStatusTypes.BUSY) {
      await declineCallAsync();
      setRecognizedText('User is busy now');
    }
  };

  const checkPermissionCallback = () => {
    push('/?permission');
  };

  useEffect(() => {
    // if logged show toast to allow micro and notification
    if (userId) {
      getAllowNotification();
      speechService.showErrorToast();
    }

    speechService.checkPermission(checkPermissionCallback);
    speechService.init(recognizeCallback);
    return () => {
      speechService.stop();
      socket?.off();
    };
  }, [speechService]);

  useEffect(() => {
    setStatusData(statusData);
    setAccepted(accepted);
    setRequestedCount(requestCount);
  }, [accepted, requestCount, statusData]);

  return (
    <Styled.MainContainer container direction="column" {...props}>
      {withHeader && <Header />}
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Styled.MainContainer>
  );
};
