import {
  AudioConfig,
  SpeechConfig,
  SpeechRecognizer,
  PhraseListGrammar
} from 'microsoft-cognitiveservices-speech-sdk';
import { HttpService } from './http.service';
import { IssueTokenResponse } from './types';
import { HttpFactoryService } from './http-factory.service';
import { toastError } from '../components/toast/toast.component';
import { userService } from './user.service';
import { EStatusTypes } from '../types/components.types';
import { localStorageService } from './local-storage.service';

class SpeechService {
  private recognizer: SpeechRecognizer | null = null;

  private phraseList: PhraseListGrammar | null = null;

  constructor(private httpService: HttpService) {}

  async stop() {
    if (this.recognizer) {
      this.recognizer.stopContinuousRecognitionAsync();
    }
  }

  private async initGrammar(recognizer: SpeechRecognizer) {
    this.phraseList = PhraseListGrammar.fromRecognizer(recognizer);
    this.phraseList.addPhrase("I'm busy");
    this.phraseList.addPhrase('Bye bye');
    this.phraseList.addPhrase('Hey');
  }

  private async issueToken() {
    const cachedCreds = localStorageService.getSpeechTokenFromStorage();
    if (cachedCreds) {
      return JSON.parse(cachedCreds) as IssueTokenResponse;
    }
    const res = await this.httpService.get<IssueTokenResponse>('speech/issueToken');
    if (res) {
      localStorageService.setSpeechTokenToStorage(JSON.stringify(res));
    }
    return res;
  }

  public async checkPermission(cb: () => void) {
    try {
      // @ts-ignore
      const permission = await navigator.permissions.query({ name: 'microphone' });

      // listen for changes in permission status
      permission.onchange = () => {
        cb();
      };

      const permissionNotification = await navigator.permissions.query({ name: 'notifications' });

      permissionNotification.onchange = () => {
        cb();
      };
    } catch {
      // in firefox or webkit case
    }
  }

  public async showErrorToast() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (e) {
      console.log(
        'if you want to have full access to app features give permission to use your microphone'
      );

      toastError('Allow access to use your microphone');
    }
  }

  async init(cb: (text: string, status: EStatusTypes) => void, tries = 1) {
    if (tries > 2) {
      return;
    }
    const res = await this.issueToken();
    console.log({ res });
    if (!res) {
      return;
    }

    const speechConfig = SpeechConfig.fromAuthorizationToken(res.token, res.region);
    speechConfig.speechRecognitionLanguage = 'en-US';

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioConfig = AudioConfig.fromStreamInput(stream);

      this.recognizer = new SpeechRecognizer(speechConfig, audioConfig);
      this.initGrammar(this.recognizer);
      this.recognizer.sessionStarted = () => {
        console.log('\n    Session started event.');
      };
      this.recognizer.recognized = async (s, e) => {
        const userData = await userService.getMe();
        if (e.result.text) {
          cb(e.result.text, userData.status);
        }
        console.log(`RECOGNIZED: Text=${e.result.text}`);
      };
      this.recognizer.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`);
        console.log(e);
        console.log(`Revoking... Attempt ${tries}`);
        this.recognizer?.stopContinuousRecognitionAsync();
        localStorageService.removeSpeechTokenFromStorage();
        this.init(cb, tries + 1);
      };
      this.recognizer.sessionStopped = () => {
        console.log('\n    Session stopped event.');
      };

      this.recognizer.startContinuousRecognitionAsync();
    } catch (e) {
      console.log(
        'if you want to have full access to app features give permission to use your microphone'
      );
    }
  }
}

const factory = new HttpFactoryService();
export const speechService = new SpeechService(factory.createHttpService());
