import React, { FC, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import * as Styled from './requests-content.styled';
import { CustomButton } from '../button';
import { BlueTrashIcon } from '../icons';
import { friendService } from '../../services/friend.service';
import { toastSuccess } from '../toast/toast.component';
import { customErrorHandler } from '../../utils/error.util';
import { friendRequestService } from '../../services/friend-request.service';

interface IProps {
  id: string;
}

export const RequestsItemContent: FC<IProps> = ({ id }) => {
  const isAccept = useRef(false);
  const { refetch: refetchFriends } = friendService.useAllQuery<any>();
  const { refetch: refetchRequests } = friendRequestService.useAllQuery<any>();
  const [isHover, setHover] = useState(false);

  const { mutate: acceptFriendMutation } = useMutation<any, AxiosError, string>(
    (userId) => friendService.acceptFriend({ id: userId }),
    {
      onSuccess: () => {
        refetchRequests();
        refetchFriends();
        toastSuccess('Friend request successfully accepted!');
      },
      onError: customErrorHandler
    }
  );

  const { mutate: removeSentRequestMutation } = useMutation<any, AxiosError, string>(
    (userId) => friendRequestService.removeFriendRequest({ id: userId }),
    {
      onSuccess: () => {
        refetchRequests();
        toastSuccess('Friend request successfully removed!');
      },
      onError: customErrorHandler
    }
  );

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  const handleAcceptFriend = () => {
    if (!isAccept.current) acceptFriendMutation(id);
    isAccept.current = true;
  };

  const handleRemoveFriendRequest = () => {
    removeSentRequestMutation(id);
  };

  return (
    <Grid container alignItems="center">
      <CustomButton variant="contained" size="small" width="112px" onClick={handleAcceptFriend}>
        {isAccept.current ? 'Accepted' : 'Accept'}
      </CustomButton>
      <Styled.IconContainer
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleRemoveFriendRequest}
      >
        <BlueTrashIcon isHover={isHover} />
      </Styled.IconContainer>
    </Grid>
  );
};
