import { useState } from 'react';

export const useHover = () => {
  const [isHover, seIsHover] = useState(false);

  const onMouseOver = () => {
    seIsHover(true);
  };

  const onMouseOut = () => {
    seIsHover(false);
  };

  return {
    isHover,
    seIsHover,
    onMouseOver,
    onMouseOut
  };
};
