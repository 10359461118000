import React, {
  createContext,
  ReactChild,
  ReactChildren,
  useContext,
  useMemo,
  useState
} from 'react';

interface IContextValue {
  accepted: any;
  setAccepted: any;
  requestCount: any;
  setRequestedCount: any;
  sentRequestChanged: boolean;
  setSentRequestsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  recognizedText: string;
  setRecognizedText: React.Dispatch<React.SetStateAction<string>>;
  statusData: any;
  setStatusData: any;
}

const SocketContext = createContext<IContextValue>({} as IContextValue);

interface IUserContext {
  children: ReactChild | ReactChildren;
}

export const SocketContextProvider: React.FC<IUserContext> = ({ children }) => {
  const [accepted, setAccepted] = useState();
  const [requestCount, setRequestedCount] = useState();
  const [sentRequestChanged, setSentRequestsChanged] = useState(false);
  const [statusData, setStatusData] = useState();
  const [recognizedText, setRecognizedText] = useState<string>('');

  const value = useMemo(
    () => ({
      accepted,
      setAccepted,
      requestCount,
      setRequestedCount,
      statusData,
      setStatusData,
      setSentRequestsChanged,
      sentRequestChanged,
      setRecognizedText,
      recognizedText
    }),
    [
      accepted,
      setAccepted,
      requestCount,
      sentRequestChanged,
      setRequestedCount,
      statusData,
      setStatusData,
      recognizedText
    ]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const useSocketData = () => useContext(SocketContext);
