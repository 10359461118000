import React, { FC } from 'react';
import { Grid, TypographyProps } from '@mui/material';
import * as Styled from './auth-wrapper.styled';
import { Text } from '../global-typography';
import { NearLogo } from '../near-logo';

interface IProps extends TypographyProps {
  title?: string;
  errorMsg?: JSX.Element | undefined | string;
  withLogo?: boolean;
  children?: React.ReactNode;
}

export const AuthWrapper: FC<IProps> = ({
  title,
  errorMsg,
  withLogo = true,
  variant = 'h5',
  align = 'left',
  children
}) => (
  <Styled.AuthContainer
    container
    direction="column"
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    {errorMsg && (
      <Styled.ErrorToast container alignItems="center" justifyContent="center">
        <Text colorVariant="error.main" variant="body1" align="center">
          {errorMsg}
        </Text>
      </Styled.ErrorToast>
    )}
    <Styled.FormContainer container direction="column">
      {!errorMsg && withLogo ? (
        <Grid item container alignItems="center" justifyContent="center">
          <NearLogo isInAuth />
        </Grid>
      ) : null}
      <Text
        colorVariant="common.black"
        variant={variant}
        fontWeight="fontWeightBold"
        align={align}
        mt={errorMsg ? '0.75rem' : '1.75rem'}
      >
        {title}
      </Text>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return null;
      })}
    </Styled.FormContainer>
  </Styled.AuthContainer>
);
