import { FC } from 'react';
import CombineComponents from '../common/components/combine-components';
import { AuthContextProvider } from './auth.context';
import { UserContextProvider } from './user.context';
import { SocketContextProvider } from './socket.context';
import { SoundContextProvider } from './sound.context';

const providers = [
  AuthContextProvider,
  UserContextProvider,
  SocketContextProvider,
  SoundContextProvider
];
const ContextProvider = CombineComponents(...(providers as FC[]));

export default ContextProvider;
