export const getInputType = (inputType: string, openEye: boolean) => {
  switch (true) {
    case inputType === 'password' && openEye:
      return 'text';
    case inputType === 'password' && !openEye:
      return 'password';
    default:
      return inputType;
  }
};
