import { FC } from 'react';
import * as Styled from './divider.styled';

interface IProps {
  label?: string;
}

export const TextDivider: FC<IProps> = ({ label = 'OR' }) => (
  <Styled.DividerLine>
    <Styled.DividerLabelContainer>
      <Styled.DividerLabel>{label}</Styled.DividerLabel>
    </Styled.DividerLabelContainer>
  </Styled.DividerLine>
);
