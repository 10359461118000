import React, { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

export interface FriendItemContextProps {
  children: JSX.Element | JSX.Element[];
}

export interface IFriendItemContext {
  isEditInput: boolean;
  setIsEditInput: Dispatch<SetStateAction<boolean>>;
  friendHintValue: string;
  setFriendHintValue: Dispatch<SetStateAction<string>>;
}

export const FriendItemContext = createContext<IFriendItemContext>({
  isEditInput: false,
  setIsEditInput: () => {},
  friendHintValue: '',
  setFriendHintValue: () => {}
});

export const FriendItemState = ({ children }: FriendItemContextProps) => {
  const [isEditInput, setIsEditInput] = useState(false);
  const [friendHintValue, setFriendHintValue] = useState('');

  const value = useMemo(
    () => ({
      isEditInput,
      setIsEditInput,
      friendHintValue,
      setFriendHintValue
    }),
    [isEditInput, setIsEditInput, friendHintValue, setFriendHintValue]
  );

  return <FriendItemContext.Provider value={value}>{children}</FriendItemContext.Provider>;
};
