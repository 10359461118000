export const getAvatarUrl = (member: {
  user: { avatarUploaded: string; avatarDefaultUploaded: string };
}) => {
  const avatarBaseURL = member.user.avatarUploaded
    ? process.env.REACT_APP_AWS_S3_AVATAR_BASE_URL
    : process.env.REACT_APP_AWS_S3_DEFAULT_AVATAR_BASE_URL;

  const avatarPath = member.user.avatarUploaded || member.user.avatarDefaultUploaded;

  return `${avatarBaseURL}${avatarPath}`;
};
