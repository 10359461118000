import logo from '../../../assets/image/near-logo.svg';
import { toastError } from '../../common/components/toast/toast.component';

export const notifyMessage = async (message: string) => {
  const options = {
    icon: logo,
    body: message
  };

  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification('Near team', options);
    // …
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    await Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        const notification = new Notification('Near team', options);
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
};

export async function getAllowNotification() {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    await Notification.requestPermission();
  }

  if (Notification.permission === 'denied') {
    toastError('Allow access to use your notification');
  }
}
