import React, { FC } from 'react';
import { UserItem } from './user-item.component';
import { StatusBadge } from '../status-badge';
import { EHomeTabs, EStatusTypes } from '../../types/components.types';
import { IUser } from '../../services/types';
import { FriendItemState } from '../../../context/friend-item.context';

interface IProps {
  friend: IUser;
  status?: EStatusTypes;
  username?: string;
  id: string;
}

export const FriendsItem: FC<IProps> = ({ username, status, id, friend }) => (
  <FriendItemState>
    <UserItem id={id} username={username} status={status} type={EHomeTabs.FRIENDS} friend={friend}>
      <StatusBadge status={status} id={id} username={username} />
    </UserItem>
  </FriendItemState>
);
