import styled from '@emotion/styled';
import { MergedTheme } from '../../../theme';

export const HeaderContainer = styled.div<{ theme?: MergedTheme }>`
  min-height: 100px;
  min-width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: ${({ theme }) => theme.spacing(6.25)};
  padding-right: ${({ theme }) => theme.spacing(6.25)};
  box-shadow: 1px 5px 10px ${({ theme }) => theme.colors.mostLightBlue};
  z-index: 9999;
`;

export const UserInfoContainer = styled.div<{
  theme?: MergedTheme;
  isActive?: boolean;
  isUsername?: boolean;
}>`
  height: 52px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-right: ${({ theme, isUsername }) => (isUsername ? theme.spacing(2.5) : 0)};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.semiTransparentGrey : theme.colors.transparent};

  &:hover {
    background-color: ${({ theme }) => theme.colors.semiTransparentGrey};
  }
`;
