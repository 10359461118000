import React, { FC } from 'react';
import * as Styled from './home-wrapper.styled';
import { Text } from '../../common/components/global-typography';

interface IProps {
  title?: string;
}

export const RequestsHeader: FC<IProps> = ({ title }) => (
  <Styled.RequestHeaderContainer container alignItems="center">
    <Text
      sx={{ cursor: 'auto' }}
      colorVariant="secondary.main"
      variant="body1"
      fontWeight="fontWeightMedium"
      pt="1rem"
    >
      {title}
    </Text>
  </Styled.RequestHeaderContainer>
);
