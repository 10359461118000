import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import * as Styled from './requests-content.styled';
import { CustomButton } from '../button';
import { BlueTrashIcon } from '../icons';
import { toastSuccess } from '../toast/toast.component';
import { customErrorHandler } from '../../utils/error.util';
import { sentRequestService } from '../../services/sent-request.service';

interface IProps {
  id: string;
}

export const SentRequestsItemContent: FC<IProps> = ({ id }) => {
  const { refetch } = sentRequestService.useAllQuery<any>();
  const [isHover, setHover] = useState(false);
  const handleMouseOver = () => {
    setHover(true);
  };
  const handleMouseOut = () => {
    setHover(false);
  };

  const { mutate: removeSentRequestMutation } = useMutation<any, AxiosError, string>(
    (userId) => sentRequestService.removeSentRequest({ id: userId }),
    {
      onSuccess: () => {
        refetch();
        toastSuccess('Request successfully removed!');
      },
      onError: customErrorHandler
    }
  );

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    removeSentRequestMutation(id);
  };

  return (
    <Grid container alignItems="center">
      <CustomButton
        disableRipple
        variant="text"
        size="small"
        width="112px"
        sx={{
          cursor: 'auto',
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            borderColor: 'rgba(5, 131, 242, 0.5)',
            boxShadow: 'none'
          }
        }}
      >
        Added
      </CustomButton>
      <Styled.IconContainer
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        <BlueTrashIcon isHover={isHover} />
      </Styled.IconContainer>
    </Grid>
  );
};
