import { HttpFactoryService } from './http-factory.service';
import {
  IAcceptFriendRequest,
  ICreateFriendRequest,
  IRemoveFriendRequest,
  IUpdateFriendHintPayload
} from './types';
import { EnhancedWithAuthHttpService } from './http-auth.service';
import { ReactQueryFactory } from '../utils';
import { IHttpConfig } from '../types';

export class FriendService extends ReactQueryFactory<{}> {
  constructor(private httpService: EnhancedWithAuthHttpService) {
    super('friend');
  }

  async onDetailedRequest(): Promise<any | void | undefined> {
    throw new Error('onDetailedRequest not implemented!');
  }

  async onAllRequest() {
    const data = await this.httpService.get<any>('user-friend/friends');
    return data;
  }

  async onListRequest({ match }: { match?: string }) {
    if (!match) return Promise.resolve([]);
    const queryString = match ? `?match=${match}` : '';
    const data = await this.httpService.get<any[]>(`user-friend${queryString}`);
    return data;
  }

  async createFriend({ id }: ICreateFriendRequest): Promise<any | void> {
    return this.httpService.post('user-friend/create-friend', { id });
  }

  async acceptFriend({ id }: IAcceptFriendRequest): Promise<any | void> {
    return this.httpService.post('user-friend/accept-friend', { id });
  }

  async removeFriend({ id }: IRemoveFriendRequest): Promise<any | void> {
    return this.httpService.delete('user-friend/friend', { data: { id } } as IHttpConfig);
  }

  async updateFriendHint({ friendId, hint }: IUpdateFriendHintPayload) {
    return this.httpService.put(`user-friend/friend-hint/${friendId}`, { hint });
  }
}

const factory = new HttpFactoryService();
export const friendService = new FriendService(factory.createAuthHttpService());
