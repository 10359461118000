export const GoogleIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_171_3780)">
      <path
        d="M28.2273 14.3225C28.2273 13.3709 28.1501 12.414 27.9855 11.4778H14.7803V16.8689H22.3423C22.0285 18.6077 21.0202 20.1458 19.5438 21.1232V24.6213H24.0553C26.7046 22.1829 28.2273 18.582 28.2273 14.3225Z"
        fill="#4285F4"
      />
      <path
        d="M14.7803 28.0009C18.5561 28.0009 21.7404 26.7611 24.0605 24.6211L19.549 21.1231C18.2938 21.977 16.6734 22.4606 14.7854 22.4606C11.133 22.4606 8.0362 19.9965 6.92505 16.6836H2.26953V20.2897C4.64616 25.0172 9.48688 28.0009 14.7803 28.0009Z"
        fill="#34A853"
      />
      <path
        d="M6.9199 16.6837C6.33346 14.9449 6.33346 13.0621 6.9199 11.3234V7.71729H2.26953C0.283856 11.6732 0.283856 16.3339 2.26953 20.2898L6.9199 16.6837Z"
        fill="#FBBC04"
      />
      <path
        d="M14.7803 5.54127C16.7762 5.5104 18.7053 6.26146 20.1508 7.64011L24.1479 3.64305C21.6169 1.26642 18.2578 -0.0402103 14.7803 0.000943444C9.48687 0.000943444 4.64616 2.98459 2.26953 7.71728L6.91991 11.3234C8.02592 8.00535 11.1279 5.54127 14.7803 5.54127Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_171_3780">
        <rect width="28" height="28" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
