import styled from '@emotion/styled';
import { Grid, Theme } from '@mui/material';
import { MergedTheme } from '../../../theme';

export const AuthContainer = styled(Grid)<{ theme?: MergedTheme }>`
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 1px 5px 10px ${({ theme }) => theme.colors.mostLightBlue};
  border-radius: 20px;
  width: 506px;
  height: auto;
  position: relative;
  z-index: 1000;
`;
export const FormContainer = styled(Grid)<{ theme?: Theme }>`
  padding: 36px 58px 58px 58px;
`;

export const ErrorToast = styled(Grid)<{ theme?: Theme; withoutBorderRadius?: boolean }>`
  min-height: 80px;
  min-width: 100%;
  box-sizing: border-box;
  border: 5px solid ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.error.light};
  border-radius: ${({ withoutBorderRadius }) => (withoutBorderRadius ? 0 : '20px 20px 0 0')};
`;
